@import "src/breakpoints";

.reports-list {
    width: 100%;
    border-collapse: collapse;

    @media (min-width: $reports-table-s) {
        background-color: var(--white);
    }

    &__table-wrap {

        &.scrollbar-container {
            height: auto;

            > .ps__rail-y {
                width: 8px;
                background-color: var(--grey-2);
                opacity: 0.6;

                &:hover, &:focus, &.ps--clicking {
                    background-color: var(--grey-2);
                    opacity: 1;
                }

                .ps__thumb-y {
                    width: 3px;
                    right: 2px;
                    background-color: var(--grey-5);
                }
            }

            > .ps__rail-x {
                height: 8px;
                background-color: var(--grey-2);
                opacity: 0.6;

                &:hover, &:focus, &.ps--clicking {
                    background-color: var(--grey-2);
                    opacity: 1;
                }

                .ps__thumb-x {
                    height: 3px;
                    bottom: 2px;
                    background-color: var(--grey-5);
                }
            }
        }
    }

}