@import '../../../../variables.scss';

section.feedback-form {
  width: 100%;
  min-height: calc(
    100vh - var(--auth-footer-min-height) - var(--auth-header-min-height)
  );
  padding: 0 16px;
  background: radial-gradient(
    229.59% 96.04% at 50% 3.96%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.224) 100%
  );
  backdrop-filter: blur(32px);
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    position: absolute;
    z-index: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../../../assets/vector/gradients/gradient.svg);
  }

  .feedback__container {
    position: relative;
    padding: 20px;
    max-width: 1200px;
    min-height: 500px;
    margin: 40px auto;
    background: #ffffff;
    box-shadow: 0px 6px 12px rgba(210, 217, 225, 0.4);
    border-radius: 24px;
    z-index: 1000;

    @media screen and (min-width: 1280px) {
      padding: 60px 72px;
    }

    @media screen and (min-width: 1920px) {
      margin: 100px auto 40px;
      max-width: 1400px;
      min-height: 655px;
    }

    .coach__section {
      width: 100%;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }

      .coach__info {
        display: flex;
        background: #ffffff;
        border-radius: 12px;
        width: 100%;
        padding: 16px 24px;

        @media screen and (min-width: 768px) {
          max-width: 253px;
          justify-content: space-between;
        }

        .coach__image {
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 12px;
        }

        .info__content {
          margin-left: 16px;

          .coach__name {
            font-weight: var(--font-weight-medium);
            font-size: 17px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            color: var(--black);
          }
          p {
            font-size: 15px;
            line-height: 150%;
            color: var(--grey-3);
          }
        }
      }

      .section__text {
        margin-top: 12px;

        @media screen and (min-width: 768px) {
          margin-top: unset;
          text-align: right;
        }

        .text__title {
          font-weight: var(--font-weight-medium);
          font-size: 17px;
          line-height: 140%;
          letter-spacing: 0.0015em;
          color: var(--black);
          span {
            color: var(--orange-2);
          }
        }
        p {
          font-size: 15px;
          line-height: 150%;
          color: var(--grey-3);
        }
      }
    }

    .form-question {
      font-weight: var(--font-weight-medium);
      font-size: 24px;
      line-height: 140%;
      letter-spacing: 0.015em;
      color: var(--black1);

      &.date-step,
      &.gender {
        @media screen and (max-width: 1279px) {
          text-align: center;
        }
      }

      span {
        color: #e75b32;
      }
    }

    .question_tooltip {
      font-weight: normal;
      font-size: 18px;
      line-height: 125%;
      letter-spacing: 0.015em;
      color: #101821;
    }

    .not-answer__wrapper {
      display: flex;
      justify-content: start;

      @media screen and (max-width: 1279px) {
        justify-content: center;
      }

      .not-answer {
        margin-top: 20px;

        @media screen and (max-width: 1279px) {
          margin-bottom: 0;
        }

        span {
          font-weight: var(--font-weight-medium);
          font-size: 17px;
          line-height: 140%;
          letter-spacing: 0.0015em;
          color: #333333;
          font-family: 'Poppins', sans-serif !important;
        }
      }
    }

    .legend-page {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: auto;

      .form-question {
        font-size: 24px;
        line-height: 140%;
      }
    }

    form {
      margin-top: 40px;
      width: 100%;

      p.error-message {
        margin-top: 10px;
        font-size: 18px;
        line-height: 25px;
        color: #e75b32;
      }

      .radio__buttons {
        width: 100%;
        margin-top: 55px;

        .criteria.mobile {
          font-weight: var(--font-weight-medium);
          font-size: 17px;
          line-height: 140%;
          letter-spacing: 0.0015em;
          color: #333333;
          display: block;
          text-align: center;

          &.min {
            margin-bottom: 24px;
          }

          &.max {
            margin-top: -32px;
          }

          @media screen and (min-width: 1280px) {
            display: none;
          }
        }

        &.__buttons {
          .MuiFormGroup-root {
            flex-direction: column !important;
            align-items: flex-start;

            .MuiFormControlLabel-root {
              justify-content: flex-start !important;
              margin: 0px 0px 24px 0px !important;

              .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
                font-weight: var(--font-weight-regular);
                font-size: 15px;
                line-height: 150%;
                color: var(--black1);
                font-family: 'Poppins', sans-serif;
                margin-left: 24px !important;
              }
            }
          }

          .MuiRadio-colorSecondary.Mui-checked {
            color: var(--blue-3);
            background-color: var(--blue-3);
          }

          .MuiSvgIcon-root {
            width: 1.2em !important;
            height: 1.72m !important;
          }
        }

        &.five__buttons {
          position: relative;

          .MuiFormControlLabel-root {
            margin-left: 0 !important;
            margin-right: 0 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.first {
            color: #3d8b82 !important;
            background-color: #3d8b82 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.second {
            color: #4296e5 !important;
            background-color: #4296e5 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.third {
            color: #ecaf2b !important;
            background-color: #ecaf2b !important;
          }
          .MuiRadio-colorSecondary.Mui-checked.fourth {
            color: #e75b32 !important;
            background-color: #e75b32 !important;
          }
          .MuiRadio-colorSecondary.Mui-checked.fifth {
            color: #9f3c47 !important;
            background-color: #9f3c47 !important;
          }

          .vector {
            position: absolute;
            display: none;

            @media screen and (min-width: 1280px) {
              display: block;
              z-index: -1;
            }

            &.vector_1 {
              left: 50px;
            }
            &.vector_2 {
              left: 310px;
              top: 40px;
            }
            &.vector_3 {
              left: 580px;
            }
            &.vector_4 {
              left: 828px;
              top: 40px;
            }
          }
        }

        &.ten__buttons {
          position: relative;

          .MuiFormControlLabel-root {
            margin-left: 0 !important;
            margin-right: 0 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.first {
            color: #366b65 !important;
            background-color: #366b65 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.second {
            color: #3d8b82 !important;
            background-color: #3d8b82 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.third {
            color: #35a684 !important;
            background-color: #35a684 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.fourth {
            color: #93cbff !important;
            background-color: #93cbff !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.fifth {
            color: #4296e5 !important;
            background-color: #4296e5 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.sixth {
            color: #fdc94c !important;
            background-color: #fdc94c !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.seventh {
            color: #ecaf2b !important;
            background-color: #ecaf2b !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.eighth {
            color: #e75b32 !important;
            background-color: #e75b32 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.ninth {
            color: #e07b93 !important;
            background-color: #e07b93 !important;
          }

          .MuiRadio-colorSecondary.Mui-checked.tenth {
            color: #9f3c47 !important;
            background-color: #9f3c47 !important;
          }

          .vector {
            position: absolute;
            display: none;

            @media screen and (min-width: 1280px) {
              display: block;
            }

            &.vector_1 {
              left: 64px;
              top: 20px;
            }

            &.vector_2 {
              left: 175px;
              top: 45px;
            }

            &.vector_3 {
              left: 295px;
              top: 20px;
            }

            &.vector_4 {
              left: 405px;
              top: 45px;
            }

            &.vector_5 {
              left: 525px;
              top: 20px;
            }

            &.vector_6 {
              left: 635px;
              top: 45px;
            }

            &.vector_7 {
              left: 755px;
              top: 20px;
            }

            &.vector_8 {
              left: 865px;
              top: 45px;
            }

            &.vector_9 {
              left: 985px;
              top: 20px;
            }
          }
        }
      }

      .form__buttons {
        margin: 55px 0px;
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1280px) {
          justify-content: start;
        }
        .btn {
          width: 100px;
          height: 48px;
          // fot ILTB
          // background: #e75b32;
          // border-radius: 56px;
          background: #2e79da;
          border-radius: 8px;
          font-weight: var(--font-weight-medium);
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          &:disabled {
            background: rgba(0, 0, 0, 0.12);
            cursor: auto;
            box-shadow: none;
          }

          &.button__back {
            margin-right: 20px;
            border: 2px solid var(--blue-3);
            background: #fff;
            color: var(--blue-3);
            // for ILTB
            // color: #e75b32;
            // border: 2px solid #e75b32;
          }
        }
      }

      .radio__criterias {
        width: 100%;
        margin-top: 25px;
        display: flex;
        justify-content: space-between;

        .criteria {
          font-weight: var(--font-weight-regular);
          font-size: 15px;
          line-height: 150%;
          letter-spacing: 0.0015em;
          color: var(--black1);
          display: none;

          @media screen and (min-width: 1280px) {
            display: block;
          }

          &.min {
            width: 150px;
          }

          &.max {
            width: 185px;
            text-align: right;
          }
        }
      }

      .textarea-form {
        textarea {
          margin-top: 56px;
          width: 100%;
          height: 200px;
          background: #f8f9fa;
          border: 1px solid #f1f1f1;
          border-radius: 12px;
          padding: 20px 24px;

          &:focus {
            outline: 1px solid var(--blue-3);
          }

          @media screen and (min-width: 1440px) {
            height: 250px;
          }
        }
      }

      .select__container {
        margin-top: 56px;
        display: flex;
        justify-content: center;

        .survey-select {
          &__control {
            width: 340px;
            background-color: var(--background);
          }
          &__indicators {
            .multi-select-remove-item {
              display: none;
            }
          }
        }

        .row-select {
          &__control {
            width: 340px;
          }
          &__indicators {
            .multi-select-remove-item {
              display: none;
            }
          }
        }

        @media screen and (min-width: 1280px) {
          justify-content: flex-start;
        }

        .react-datepicker__input-container {
          input {
            padding-left: 24px;
            width: 340px;
            height: 50px;
            background: var(--background);
            border: 1px solid #edebf9;
            border-radius: 12px;
            box-sizing: border-box;
            border-radius: 12px;

            &.react-datepicker-ignore-onclickoutside {
              border: 1px solid #2e79da;
            }
          }
        }

        .react-datepicker__navigation-icon {
          // used to fix displaying of the arrows in the calendar for Safari
          width: 0;
        }

        .react-datepicker-popper {
          .react-datepicker {
            background: #ffffff;
            border: 1px solid #cdcbcb;
            border-radius: 16px;

            .react-datepicker__triangle {
              display: none;
            }

            .react-datepicker__navigation.react-datepicker__navigation--next {
              background-image: url(../../../../assets/vector/short_left.svg);
              background-repeat: no-repeat;
              background-position: center;
            }

            .react-datepicker__navigation.react-datepicker__navigation--previous {
              background-image: url(../../../../assets/vector/short_left.svg);
              background-repeat: no-repeat;
              background-position: center;
              transform: rotate(180deg);
            }

            .react-datepicker__navigation-icon::before {
              display: none;
            }

            .react-datepicker__month-container {
              .react-datepicker__header {
                background-color: #ffffff;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
              }

              .react-datepicker__month {
                .react-datepicker__week {
                  .react-datepicker__day {
                    &--selected {
                      border-radius: 50%;
                      background-color: #2e79da;
                    }
                    &--today {
                      border-radius: 50%;
                      background-color: #2e79da;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .checkbox__container {
        margin-top: 64px;
        max-width: 1000px;
        margin-left: 0;

        .questionnaire-checkbox {
          margin: 36px 0;

          .custom-checkbox__item {
            .custom-checkbox__item-box {
              border: 2px solid var(--blue-3);
            }
            .custom-checkbox__item-label {
              margin-left: 20px;
              font-weight: var(--font-weight-regular);
              font-size: 15px;
              line-height: 150%;
              color: var(--black1);
            }
          }
        }

        .MuiFormGroup-root {
          flex-direction: column !important;
          align-items: flex-start;

          @media screen and (min-width: 1280px) {
            margin-left: -16px;
          }
          .MuiFormControlLabel-root {
            justify-content: start !important;
            margin: 8px 0;

            .MuiTypography-body1 {
              margin-left: 8px;
              font-weight: var(--font-weight-medium);
              font-size: 24px;
              line-height: 125%;
              font-family: inherit;
            }
          }
        }
      }

      .text-input-step {
        input {
          margin-top: 40px;
          padding: 0 25px;
          width: 540px;
          height: 64px;
          background: #f8f9fa;
          border: 1px solid #f1f1f1;
          border-radius: 12px;
          font-size: 15px;
          line-height: 150%;
          color: var(--black1);
        }
      }
    }
  }

  // material UI components

  .MuiMobileStepper-root {
    padding: 0;
  }

  .MuiMobileStepper-progress {
    width: 100% !important;
    max-width: 382px !important;
    height: 16px;
    background: #f8f9fa;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
  }

  .MuiLinearProgress-bar {
    border-radius: 8px;
  }
  .MuiLinearProgress-barColorPrimary {
    // for ILTV
    // background-color: var(--rafia) !important;
    background-color: var(--blue-3) !important;
  }

  .MuiFormGroup-root {
    width: 100% !important;
    align-items: center;

    @media screen and (min-width: 1280px) {
      flex-direction: row !important;
      justify-content: space-between !important;
    }
  }

  .MuiSvgIcon-root {
    width: 1.7em !important;
    height: 1.7em !important;
  }

  .MuiFormControlLabel-root {
    justify-content: center !important;

    @media screen and (max-width: 1279px) {
      margin-bottom: 56px;
    }
  }
}
