@import 'src/breakpoints';
@import '~react-perfect-scrollbar/dist/css/styles.min.css';

.modal {
  position: relative;
  width: 675px;
  padding: 16px 14px;

  @media (min-width: $modal) {
    padding: 48px;
  }

  @media (max-width: 675px) {
    width: 100%;
  }

  &__overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    background-color: var(--modal);
    z-index: 500;

    // styles for calendar_event_modal
    &.calendar_event_modal {
      .ScrollbarsCustom {
        background: transparent;
      }

      .modal {
        padding: 0;

        &__close {
          top: 28px;
          right: 24px;

          @media (min-width: $modal) {
            top: 45px;
            right: 45px;
          }
        }
      }
    }

    // styles for EditScheduleModal
    &.edit-schedule-wrapper {
      .modal {
        width: 860px;
        padding: 16px;

        @media (min-width: 860px) {
          padding: 40px;
        }

        @media (max-width: 859px) {
          width: 100%;
        }
      }
    }

    &--enter {
      opacity: 0;
    }

    &--enter-active {
      opacity: 1;
      transition: opacity 0.2s;
    }

    &--exit {
      opacity: 1;
    }

    &--exit-active {
      opacity: 0;
      transition: opacity 0.2s;
    }

    .ScrollbarsCustom {
      border-radius: 12px;
      background-color: var(--white);
      max-height: 100%;
    }

    .ScrollbarsCustom-Track {
      width: 6px !important;
      background-color: var(--grey-2) !important;
    }

    .ScrollbarsCustom-Thumb {
      background-color: var(--grey-4) !important;
      opacity: 0.8;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  &__close {
    position: absolute;
    right: 21px;
    top: 21px;
    flex-shrink: 0;
    cursor: pointer;
    transition: transform 0.15s;
    z-index: 1;

    @media (hover: hover) {
      &:hover {
        transform: scale(0.95);
      }
    }

    @media (min-width: $modal) {
      right: 48px;
      top: 53px;

      &.edit-schedule-close {
        top: 45px;
        right: 45px;
      }
    }
  }
}
