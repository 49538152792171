@import 'src/breakpoints';

.settings-page {
  .settings-wrap {
    margin: 0 10%;

    .page-title {
      font-weight: var(--font-weight-medium);
      font-size: 32px;
      line-height: 125%;
      letter-spacing: 0.015em;
      color: #101821;
      margin: 0 0 24px;
    }

    .section-info-block {
      margin: 0 0 24px;

      .block-title {
        font-weight: var(--font-weight-regular);
        font-size: 22px;
        line-height: 125%;
        color: var(--gen_font_color);
        margin: 0 0 24px;
      }

      .settings-row {
        margin: 0 0 23px;

        &.timezone {
          max-width: 560px;
        }

        &.no-select {
          .row-select__control,
          .css-2b097c-container {
            cursor: default;
          }
          .row-select__indicators {
            display: none;
          }
        }

        &__label {
          font-weight: var(--font-weight-medium);
          font-size: 16px;
          line-height: 140%;
          letter-spacing: 0.0015em;
          color: var(--gen_font_color);
          margin: 0 0 8px 0;
          display: flex;
        }

        &__input {
          display: flex;
          align-items: center;
          width: 560px;
          height: 42px;
          border-radius: 8px;
          padding: 0 16px;
          background-color: var(--white);
          color: var(--grey-3);
          font-weight: var(--font-weight-regular);
          font-size: 14px;
          line-height: 125%;
          border: 1px solid var(--grey-5);
          transition: border-color 0.25s;

          &::placeholder {
            font-size: 14px;
            line-height: 125%;
            color: var(--grey-3);
          }
        }

        &.input-error {
          .settings-row__input {
            border: 2px solid var(--stiletto);
          }
        }

        &__error {
          color: var(--stiletto);
          margin: 0 0 0 40px;
          font-weight: var(--font-weight-regular);
          font-size: 15px;
          line-height: 150%;
        }
      }

      .change-pass-btn {
        margin: 17px 0 0 0;
        min-width: 160px;
        height: 44px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 125%;

        cursor: pointer;
        color: var(--white);
        // for ILTB
        // border: 2px solid var(--orange-2);
        // background-color: var(--orange-2);
        border: 2px solid var(--gen_button_color);
        background-color: var(--gen_button_color);
        transition: all 0.2s linear;

        &:hover {
          // for ILTB
          // color: var(--orange-2);
          color: var(--gen_button_color);
          background-color: var(--white);
        }

        &:disabled {
          opacity: 0.5;
          cursor: auto;

          &:hover {
            color: var(--gen_button_color);
            background-color: var(--white);
          }
        }
      }
      #g-anchor {
        margin-top: 24px;
      }
      .send-me-email {
        margin: 0 0 32px;

        .text-send-me {
          font-weight: normal;
          font-size: 18px;
          line-height: 130%;
          margin: 0 0 7px;
          display: flex;
          align-items: center;
          color: #101821;
        }

        .mail-to {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          text-decoration: none;
          color: #101821;
        }
      }

      .send-me-notification {
        .set-status-send-me-notification {
          display: flex;
          align-items: center;
          margin: 0 0 7px;

          .text {
            font-size: 18px;
            line-height: 130%;
            color: #101821;
            margin: 0 250px 0 0;
          }

          .toggle-button {
            display: flex;
          }
        }

        .info-text {
          .info-title {
            font-weight: var(--font-weight-regular);
            font-size: 15px;
            line-height: 150%;
            color: #101821;
          }

          .list {
            margin: 7px 0 0 0;

            .item {
              position: relative;
              padding: 0 0 0 20px;
              font-weight: var(--font-weight-regular);
              font-size: 16px;
              line-height: 180%;
              letter-spacing: 0.01em;
              color: #101821;
              //margin: 0 0 17px;

              &:before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #101821;
                position: absolute;
                top: calc(50% - 2px);
                left: 0;
              }
            }
          }
        }
      }
    }

    .result-save-settings-form {
      display: flex;
      margin: 25px 0 0 0;
      min-width: 150px;

      .message-text {
        font-size: 15px;
        padding: 10px 20px;
        line-height: 150%;
        border-radius: 12px;
        font-weight: var(--font-weight-medium);
        letter-spacing: 0.015em;
      }

      .error {
        border: 2px solid var(--stiletto);
        color: var(--stiletto);
        background: rgba(159, 60, 71, 0.2);
      }

      .success {
        border: 2px solid var(--william);
        color: var(--white);
        background: var(--mint);
      }
    }
  }
  .timezone_option {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 140%;

    input {
      -webkit-appearance: checkbox !important;
      -moz-appearance: auto;
      appearance: auto;
    }
  }

  .select_checkboxes {
    max-width: 560px;
  }

  @media (max-width: $tablet-4) {
    .settings-wrap {
      .section-info-block {
        .settings-row {
          &__input {
            width: 100%;
          }

          &.timezone {
            max-width: initial;

            .row-select__control {
              width: 100%;
            }
          }
        }
      }
    }
    .select_checkboxes {
      max-width: 100%;
    }
  }

  @media (max-width: $tablet) {
    .settings-wrap {
      margin: 0;

      .section-info-block {
        .send-me-notification {
          .set-status-send-me-notification {
            .text {
              margin: 0 30px 0 0;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: $full) {
    .settings-wrap {
      .section-info-block {
        .block-title {
          font-size: 24px;
        }
      }

      .settings-row {
        &__input {
          height: 44px;
          padding: 0 24px;
        }
      }
    }
  }
}
