.total-participants__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 13px;
  flex: 1 1 auto;

  .icon {
    margin-right: 12px;
  }

  .total-values {
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 160%;
    color: #929292;

    span {
      font-size: 16px;
      line-height: 125%;
      color: #212121;
      font-weight: var(--font-weight-medium);
    }
  }
}
