@import 'src/variables';
@import 'src/breakpoints';

.ratings {
  padding: 16px 4px 16px 25px;
  border: 1px solid var(--grey-girl);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  background: white;

  @media (min-width: $tablet-3) {
    padding: 14px 8px 20px 20px;
    border-radius: 12px;
    box-shadow: none;
  }

  &__head {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--grey-girl);

    @media (min-width: $tablet-1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 14px;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 140%;
    font-weight: var(--font-weight-medium);

    @media (min-width: $tablet-1) {
      margin: 0 12px 0 0;
    }

    @media (min-width: $tablet-3) {
      font-size: 14px;
      line-height: 125%;
    }

    @media (min-width: $desktop) {
      font-size: 16px;
    }

    @media (min-width: $desktop-2) {
      font-size: 18px;
    }
  }

  &__tabs {
    display: flex;
  }

  &__tab-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 6px;
    font-size: 12px;
    line-height: 125%;
    background: var(--bird-grey);
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: color 0.25s, background-color 0.25s;

    @media (min-width: $tablet-1) {
      padding: 5px 8px;
    }

    @media (min-width: $desktop) {
      padding: 8px 14px;
    }

    &.active {
      background: rgba(46, 121, 218, 0.1);
      color: var(--blue-3);
      font-weight: var(--font-weight-medium);
    }

    &:first-child {
      margin-right: 4px;

      @media (min-width: $desktop) {
        margin-right: 8px;
      }
    }
  }
}
