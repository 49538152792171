@import 'src/breakpoints';

.empty-page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  // for Switch
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    left: 0px;
    bottom: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/vector/gradients/gradient-1.svg);
    opacity: 0.2;
  }

  .empty-page-wrap {
    max-width: 675px;
    margin: 0 auto;
    z-index: 1;

    .page-empty-image {
      width: 100%;
      margin: 0 0 50px;
      max-width: 445px;
    }

    .page-title {
      padding: 0 16px;
      font-weight: 700;
      font-size: 50px;
      line-height: 110%;
      letter-spacing: 0.01em;
      color: #101821;
      margin: 0 0 32px;
      white-space: pre-line;
    }

    .page-subtitle {
      padding: 0 16px;
      font-weight: var(--font-weight-medium);
      font-size: 17px;
      line-height: 140%;
      letter-spacing: 0.0015em;
      color: #101821;
    }

    .page-short-description {
      font-size: 15px;
      line-height: 150%;
      margin: 24px 0 0;
      color: #929292;
    }
  }

  // for ILTB
  // &:before {
  //   content: '';
  //   width: 118px;
  //   height: 145px;
  //   position: absolute;
  //   right: 90px;
  //   top: 60px;
  //   background-image: url("../../../assets/vector/empty-pages/empty-page-abstract-2.svg");
  // }

  // for ILTB
  // &:after {
  //   content: '';
  //   width: 238px;
  //   height: 244px;
  //   position: absolute;
  //   z-index: 0;
  //   left: -100px;
  //   bottom: 50px;
  //   background-image: url("../../../assets/vector/empty-pages/empty-page-abstract-1.svg");
  // }
}

@media (max-width: $tablet) {
  .empty-page {
    .empty-page-wrap {
      .page-title {
        font-size: 30px;
      }
    }
  }
}
