@import 'src/breakpoints';

.create-event {
  color: var(--black);

  .default-auth__error {
    @media (max-width: 619px) {
      margin: -19px 0 6px 16px;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0 24px;

    @media (min-width: $modal) {
      flex-direction: row;
      margin: 12px 0 40px;
    }
  }

  &__icon {
    margin: 0 0 16px;

    @media (min-width: $modal) {
      margin: 0 24px 0 0;
    }
  }

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.015em;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__save {
    margin-right: 16px;
    height: 56px;
  }

  &__cancel {
    height: 56px;
  }

  &__date-picker {
    .DayPicker-NavButton--prev {
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 5L3.83 5L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7L16 7V5Z' fill='%23101821'/%3E%3C/svg%3E%0A");
    }

    .DayPicker-NavButton--next {
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.37114e-07 7L12.17 7L8.59 10.59L10 12L16 6L10 -5.24537e-07L8.59 1.41L12.17 5L6.1196e-07 5L4.37114e-07 7Z' fill='%23101821'/%3E%3C/svg%3E%0A");
    }

    .DayPicker-Caption > div {
      font-weight: var(--font-weight-medium);
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }

    .DayPicker-Weekdays {
      margin-top: 13px;
    }

    .DayPicker-WeekdaysRow {
      border-bottom: 1px solid var(--grey-5);
    }

    .DayPicker-Weekday {
      padding: 2px 2px 5px;
      font-weight: var(--font-weight-medium);
      font-size: 15px;
      line-height: 150%;
      color: var(--black);
    }

    .DayPicker-Day {
      font-size: 14px;
      padding: 10px 13px;

      &--today {
        color: inherit;
        font-weight: inherit;
      }

      &--highlighted:not(.DayPicker-Day--outside) {
        color: var(--pink);
        font-weight: var(--font-weight-medium);
        background-color: rgba(236, 78, 124, 0.15);
      }
    }
  }
}
