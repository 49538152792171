@import 'src/breakpoints';

.spinner__wrapper {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: var(--font-weight-regular);
    font-size: 32px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: var(--loader-color);
    @media (max-width: $tablet) {
      font-size: 20px;
    }
  }
}
