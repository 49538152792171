.create-session {
  height: 44px;
  padding: 5px 24px;
  font-size: 14px;
  line-height: 21px;
  background-color: var(--gen_button_color);
  &:hover {
    background-color: var(--gen_button_color);
    opacity: 0.7;
  }
}
