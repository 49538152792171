.participants {
  &__title {
    margin-bottom: 10px;
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 125%;
  }

  &__add-btn {
    margin-bottom: 10px;
    padding: 8px 24px;
    font-size: 15px;
    line-height: 125%;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 500px;
    overflow: auto;
  }

  &__user {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: var(--bird-grey);

    &--invited {
      background-color: var(--white);

      .participants__invite-btn {
        color: var(--pink);
      }
    }
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-btn {
    color: var(--grey-3);
    font-weight: var(--font-weight-medium);
    background-color: transparent;
    border: none;
    padding: 0;
  }

  &__user-img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 40px;
  }

  &__user-info {
    margin: 0 16px 0 12px;
    color: var(--black);
  }

  &__user-name {
    margin-bottom: 4px;
    font-weight: var(--font-weight-regular);
    font-size: 18px;
    line-height: 130%;
  }

  &__user-zone {
    font-weight: var(--font-weight-medium);
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.015em;

    .hours {
      color: var(--grey-3);
    }
  }

  &__invite-btn {
    border: 0;
    margin-left: auto;
    background-color: transparent;
    font-weight: var(--font-weight-medium);
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: var(--blue-3);
    cursor: pointer;

    &:disabled {
      color: var(--grey-4);
    }
  }
}
