.invoices-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-x: scroll;

  &__wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 0;
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    line-height: 150%;
    color: var(--black1);
    margin-bottom: 12px;

    &.head {
      padding: 11px 0;
      background-color: var(--reports-sorting_bg);
      color: var(--brown-3);
    }
  }

  &__col {
    display: flex;
    padding: 0 4px;
    width: calc((100% - 80px) / 6);

    @media screen and (min-width: 1280px) {
      width: calc((100% - 120px) / 6);
    }

    .sorting-buttons__wrapper {
      svg path {
        fill: #6a541f;
      }
    }

    &.logo {
      width: 80px;
      justify-content: center;

      @media screen and (min-width: 1280px) {
        width: 120px;
      }
    }

    &.download {
      color: var(--blue-3);

      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin-right: 4px;
        }
      }
    }
  }

  .empty-data {
    display: flex;
    justify-content: center;
    padding: 16px;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 150%;
    color: var(--black1);
  }

  &.scrollbar-container {
    height: auto;
    min-width: 750px;

    > .ps__rail-y {
      opacity: 1;
      width: 6px;
      border-radius: 8px;
      background-color: var(--grey-gun);

      &:hover,
      &:focus,
      &.ps--clicking {
        background-color: var(--grey-gun);
      }

      .ps__thumb-y {
        width: 100%;
        right: 0;
        border-radius: 8px;
        background-color: var(--grey-5);
      }
    }

    > .ps__rail-x {
      opacity: 1;
      height: 6px;
      border-radius: 8px;
      background-color: var(--grey-gun);

      &:hover,
      &:focus,
      &.ps--clicking {
        background-color: var(--grey-gun);
      }

      .ps__thumb-x {
        height: 100%;
        bottom: 0;
        border-radius: 8px;
        background-color: var(--grey-5);
      }
    }
  }
}
