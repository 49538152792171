@import 'src/breakpoints';

.tabs {
  position: relative;
  margin-top: 24px;

  @media (min-width: $program-l) {
    margin-top: 36px;
  }

  @media (min-width: $full) {
    margin-top: 63px;
  }

  .fill-form {
    display: flex;
    margin: 16px 0;
    text-decoration: none;
    padding: 8px 24px;
    font-size: 16px;
    line-height: 21px;

    &:disabled {
      opacity: 0;
      cursor: auto;
      border-color: var(--grey-4);
      color: var(--grey-4);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 48px;
    font-size: 16px;
    line-height: 29px;
    color: var(--black);
    white-space: pre-line;
    // overflow: hidden;

    @media (max-width: $tablet-4) {
      padding-top: 10px;
    }

    &.decision {
      padding-top: 0;
    }

    //h1, h2, h3, h4, h5, h6 {
    //    margin-bottom: 16px;
    //    font-size: 17px;
    //    line-height: 24px;
    //}
    //
    //p {
    //    margin-bottom: 24px;
    //}
    //
    //ul, ol {
    //    margin-bottom: 24px;
    //    padding: 0;
    //    list-style: none;
    //
    //    li {
    //        display: flex;
    //        align-items: flex-start;
    //
    //        &::before {
    //            content: "\00B7";
    //            margin-right: 16px;
    //            font-size: 32px;
    //        }
    //    }
    //}
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    line-height: 22px;

    @media screen and (min-width: $full) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__details {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 180%;
    color: var(--black);
    // word-break: break-all;

    a {
      color: var(--blue-3);
    }

    @media screen and (min-width: $full) {
      font-size: 16px;
    }
  }

  &__description-edit {
    margin-left: 8px;
    cursor: pointer;
  }

  &__category-edit {
    margin-top: 20px;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    text-decoration: underline;
    background-color: transparent;
    border: 0;
    color: var(--blue-3);
    cursor: pointer;
    transition: color 0.25s;

    &:focus {
      color: var(--blue-1);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--blue-1);
      }
    }
  }

  &__list {
    display: none;
    position: absolute;
    flex-direction: column;
    z-index: 50;

    @media (min-width: $program-l) {
      position: relative;
      display: flex;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--grey-5);
      border: none;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
      border-radius: 25px;
    }

    @media (min-width: $program-l) {
      flex-direction: row;
      overflow: auto;

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        bottom: 2px;
        background: var(--grey-5);
      }
    }

    &--open {
      @media (max-width: calc(#{$program-l} - 1px)) {
        display: flex;
        position: absolute;
        width: 100%;
        top: 56px;
        background: var(--white);
        border: 1px solid var(--grey-5);
        box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
        border-radius: 12px;
      }
    }
  }

  &__mob-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    border: 1px solid var(--grey-5);
    border-radius: 8px;
    margin: 0 0 8px;
    padding: 11px 19px 11px 24px;
    background: var(--white);
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 24px;
    cursor: pointer;
    transition: border-color 0.15s linear;

    @media (min-width: $program-l) {
      display: none;
    }

    &--active {
      border: 1px solid var(--blue-3);

      .arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__btn {
    display: flex;
    position: relative;
    width: calc(100% - 32px);
    margin: 0 auto;
    padding: 4px 6px;
    border: 0;
    background-color: transparent;
    border-radius: 12px;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
    z-index: 5;
    cursor: pointer;
    transition: color 0.15s linear, border-color 0.15s linear,
      background-color 0.15s linear;

    @media (hover: hover) and (max-width: calc(#{$program-l} - 1px)) {
      &:hover {
        background: var(--light-gray);
      }
    }

    @media (min-width: $program-l) {
      width: auto;
      padding-bottom: 10px;
      margin: 0 24px 0 0;
      border: 0;
      border-radius: 0;
      border-bottom: 5px solid transparent;
      font-weight: var(--font-weight-medium);
    }

    @media (min-width: $full) {
      margin-right: 48px;
      font-size: 24px;
      line-height: 30px;
    }

    &--active {
      @media (min-width: $program-l) {
        align-items: stretch;
        border: 0;
        // for ILTB
        // border-bottom: 5px solid var(--william);
        border-bottom: 5px solid var(--blue-3);
        border-radius: 0;
        order: 0;
        margin-bottom: 0;
      }
    }

    &:first-child {
      margin-top: 27px;

      @media (min-width: $program-l) {
        margin: 0 24px 0 0;
      }
    }

    &:last-child {
      margin-bottom: 27px;

      @media (min-width: $program-l) {
        margin: 0;
      }
    }
  }

  .date-picker-kit--selected {
    text-decoration: none;
    color: var(--black);

    &:focus {
      color: var(--black);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--black);
      }
    }
  }
}
