.calendar-sync {
  padding: 26px 24px 24px;
  background: var(--white);
  border-radius: 8px;

  @media (min-width: 1195px) {
    max-width: 560px;
  }

  &__title {
    font-weight: var(--font-weight-regular);
    font-size: 22px;
    line-height: 125%;
    display: flex;
    align-items: center;
    color: var(--gen_font_color);

    @media screen and (min-width: 1920px) {
      font-weight: var(--font-weight-medium);
      font-size: 24px;
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 20px;
  }

  &__email {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-content: space-between;

    @media (min-width: 1195px) {
      max-width: 560px;
    }
  }

  &__remove {
    flex-shrink: 0;
    cursor: pointer;
  }

  &__subtext {
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 150%;
    justify-content: space-between;
  }

  &__list {
    font-size: 15px;
    line-height: 150%;
    margin: 18px 0 16px;
    padding: 0;
    list-style-position: inside;
  }
  &__list-item {
    color: var(--gen_font_color);
  }

  &__g-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    border-radius: 4px;
    padding: 0 11px 0 0;
    border: 1px solid white;
    background-color: #4285f4;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: white;

    @media (hover: hover) {
      &:hover,
      &:focus {
        border-color: #b2c6e7;
      }
    }

    .active {
      background-color: #3469d6;
    }

    .icon {
      flex-shrink: 0;
      margin-right: 8px;
      border: 1px solid #4285f4;
      border-radius: 2px;
    }
  }
}
