.session-invitation {
  width: 100%;
  margin: 24px 0;
  padding: 32px 24px 24px;
  border: 1px solid var(--grey-girl);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: white;
  color: var(--black);

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .button {
      width: 100%;
      min-width: 94px;
      padding: 10px 16px;
      margin-bottom: 12px;
      font-size: 17px;
      line-height: 24px;

      @media (min-width: 480px) {
        width: auto;
        margin-right: 12px;
      }
    }

    .button:last-child {
      margin-right: 0;
    }
  }

  &--confirmed {
    .session-invitation__edit {
      color: var(--white);
    }

    .session-invitation__link,
    .session-invitation__status,
    .session-invitation__details {
      color: inherit;
    }

    .session-invitation__actions {
      button {
        &:last-child {
          border-color: #fff;

          span {
            color: var(--mint);
          }

          &:hover {
            background-color: var(--mint);

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  &--sent {
    .session-invitation__edit {
      color: white;
    }

    .session-invitation__link,
    .session-invitation__status,
    .session-invitation__details {
      color: inherit;
    }

    .button--white {
      color: var(--blue);
    }

    .session-invitation__actions {
      button {
        &:nth-child(n + 2) {
          border-color: #fff;

          span {
            color: var(--blue);
          }

          &:hover {
            background-color: var(--blue);

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  &--canceled {
    .session-invitation__status {
      color: var(--pink);
    }

    .session-invitation__date {
      line-height: 140%;
      font-weight: var(--font-weight-medium);
    }
  }

  &--inactive {
    .session-invitation__status {
      color: inherit;
    }

    .session-invitation__edit {
      color: var(--grey-3);
    }

    .session-invitation__actions {
      button {
        &:not(.session-invitation__edit):last-child {
          span {
            color: var(--white);
          }

          &:not(.session-invitation__edit):hover {
            background-color: var(--blue);
            color: #fff;

            span {
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  &__wrap {
    position: relative;
    width: 100%;

    &--active {
      .session-invitation__overlay {
      }
    }

    .session-card {
      margin: 24px 0;
    }

    .button--orange-invert {
      border-color: var(--orange);
      color: var(--orange);
    }
  }

  &__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__complete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 18px;
    margin: 0 0 24px auto;
    background: transparent;
    border: 2px solid #5bcbc9;
    color: #5bcbc9;
    border-radius: 56px;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.01em;
    cursor: pointer;
    transition: color 0.25s linear, border-color 0.25s linear;

    &:focus {
      border-color: var(--william);
      color: var(--william);
    }

    @media (hover: hover) {
      &:hover {
        border-color: var(--william);
        color: var(--william);
      }
    }

    &--white {
      border: 2px solid var(--white);
      color: var(--white);

      &:focus {
        border-color: var(--light-gray);
        color: var(--light-gray);
      }

      @media (hover: hover) {
        &:hover {
          border-color: var(--light-gray);
          color: var(--light-gray);
        }
      }
    }
  }

  &__complete {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    color: var(--grey-5);
  }

  &__complete-icon {
    width: 22px;
    margin-left: 8px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0 16px 12px 0;
    font-size: 24px;
    line-height: 30px;

    @media screen and (min-width: 1920px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__date {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__duration {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 11px;
    flex-shrink: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    margin: 0 16px 12px 0;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 24px;
    word-break: break-word;
    text-decoration-line: underline;
    color: var(--grey-5);
  }

  &__details {
    width: 100%;
    margin: 0 16px 12px 0;
    font-size: 17px;
    line-height: 24px;
    word-wrap: break-word;
  }

  &__status {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 21px;
    text-transform: uppercase;
    color: var(--orange-2);
  }

  &__status-icon {
    margin-left: 16px;
    flex-shrink: 0;
  }

  &__edit {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 10px 24px 10px 0;
    margin: 0 12px 12px 0;
    background: none;
    border: none;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    cursor: pointer;

    .icon {
      position: relative;
      bottom: 1px;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
}
