@import 'src/breakpoints';

.participant-dashboard {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__main-section {
    width: 100%;
    max-width: 68%;
    display: flex;
    flex-direction: column;
  }

  &__side-section {
    width: 100%;
    max-width: 29%;
  }

  @media screen and (max-width: ($desktop - 1px)) {
    flex-direction: column;

    &__main-section {
      max-width: 100%;
      order: 2;
    }

    &__side-section {
      order: 1;
      max-width: 100%;
    }
  }
}
