@import 'src/breakpoints';

.recent-chats {
  margin-top: 20px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  &__top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-section {
      &__title {
        font-style: normal;
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: var(--grey-3);
      }

      &__link {
        display: flex;
        align-items: center;
        font-weight: var(--font-weight-medium);
        font-size: 12px;
        line-height: 17px;
        color: var(--blue-3);
        cursor: pointer;
        @media screen and (min-width: $full) {
          font-size: 14px;
        }

        img {
          margin-right: 8px;
        }
      }
    }
  }

  &__list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .spinner__wrapper {
      span {
        display: none;
      }
    }
  }

  @media screen and (max-width: ($desktop - 1px)) {
    display: none;
  }
}
