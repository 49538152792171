.tooltip {
  position: fixed;
  width: 150px;
  //   height: 38px;
  min-height: 30px;
  padding: 6px 6px;
  background: white;
  border-radius: 8px;
  filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.11));
  z-index: 21;

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 5;

    .item__title {
      display: flex;
      align-items: center;
      z-index: 5;
      font-size: 11px;
      font-weight: var(--font-weight-medium);

      .type {
        margin-right: 4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;

        &.planned {
          background-color: var(--db_confirmed_session);
        }
        &.not_attended {
          background-color: var(--db_not_attended_session);
        }
        &.completed {
          background-color: var(--db_completed_session);
        }
      }
    }
  }

  &__title {
    position: relative;
    font-size: 11px;
    font-weight: var(--font-weight-medium);
    z-index: 5;
  }

  &__text {
    position: relative;
    font-weight: var(--font-weight-medium);
    font-size: 10px;
    line-height: 130%;
    z-index: 5;

    span {
      color: var(--grey-3);
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 9px);
    bottom: -9px;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    background-color: white;
    z-index: 2;
  }
}
