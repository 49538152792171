@import 'src/breakpoints';

.container-site-notification {
  &.site-notification-open {
    .site-notification-overlay {
      position: absolute;
      width: 100%;
      height: calc(100vh - 65px);

      .site-notification-wrapper {
        opacity: 1;
        visibility: visible;
        overflow: initial;
      }
    }
  }

  .site-notification-overlay {
    background: linear-gradient(
      270.35deg,
      rgba(40, 34, 31, 0.3) 0.36%,
      rgba(40, 34, 31, 0) 94.18%
    );
    top: 65px;
    right: 0;
    width: 0;
    height: 0;
    transition: all 0.35s ease-out;
    position: relative;
    border-top: 1px solid #ebcdb9;

    .site-notification-wrapper {
      opacity: 0;
      visibility: hidden;
      transition: all 0.35s ease-out;
      background: var(--white);
      position: fixed;
      right: 0;
      top: 65px;
      width: 100%;
      max-width: 480px;
      z-index: 1;
      height: calc(100vh - 65px);
      overflow: hidden;
      padding: 40px 24px 0;

      .header-notification {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 24px;

        .header-notification-title {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          color: var(--gen_font_color);
        }

        .clear {
          display: flex;
          align-items: center;
          cursor: pointer;

          .clear-all {
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            margin: 0 4px 0 0;

            color: #1a1f36;
          }

          .trash-bin-icon {
            display: flex;
          }
        }
      }

      .empty-notification {
        .empty-notification-image {
          height: 360px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &:after {
            content: '';
            width: calc(100% + 48px);
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0;
            left: -24px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../../assets/vector/gradients/gradient-9.png);
          }
        }

        .empty-notification-title {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          text-align: center;
          color: var(--gen_font_color);
          margin: 0 auto;
          max-width: 360px;
        }
      }

      .notification-items {
        height: calc(100% - 70px);

        .ps__rail-y {
          z-index: 1;
        }

        .item {
          display: flex;
          position: relative;
          background: linear-gradient(
              0deg,
              rgba(56, 29, 219, 0.02),
              rgba(56, 29, 219, 0.02)
            ),
            #ffffff;
          border-radius: 8px;
          margin: 0 0 6px;
          padding: 14px 50px 14px 14px;

          .item-icon {
            margin: 0 12px 0 0;
            position: relative;

            .not-readed-icon {
              position: absolute;
              top: -4px;
              left: -4px;
            }
          }

          .item-info {
            .item-notification-title {
              font-weight: var(--font-weight-medium);
              font-size: 17px;
              line-height: 140%;
              margin: 0 0 6px;
              letter-spacing: 0.0015em;
              color: #212121;
            }

            .item-notification-content {
              font-weight: var(--font-weight-regular);
              font-size: 15px;
              line-height: 150%;
              color: #212121;
              margin: 0 0 6px 0;
            }

            .item-notification-date {
              font-weight: var(--font-weight-regular);
              font-size: 13px;
              line-height: 160%;
              color: #929292;
              margin: 0 0 12px;
            }

            .item-actions {
              display: flex;

              .cancel-btn {
                font-weight: var(--font-weight-medium);
                font-size: 15px;
                line-height: 150%;
                color: #ec4e7c;
                width: 156px;
                height: 36px;
                cursor: pointer;
                margin: 0 10px 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border: 2px solid #ec4e7c;
                border-radius: 8px;
                transition: all ease-in 0.25s;

                &:hover {
                  background: #ec4e7c;
                  color: #fff;
                }
              }

              .accept-btn {
                font-weight: var(--font-weight-medium);
                font-size: 15px;
                line-height: 150%;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 156px;
                height: 36px;
                cursor: pointer;
                background: #2e79da;
                border: 2px solid #2e79da;
                border-radius: 8px;
                transition: all ease-in 0.25s;

                &:hover {
                  background: #fff;
                  color: #2e79da;
                }
              }
            }
          }

          .remove-item {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
          }
        }
      }

      .popup-clear-all-notification {
        background: rgba(40, 34, 31, 0.4);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        .clear-all-notification {
          background: #fff;
          border-radius: 12px;
          width: 675px;
          height: 70%;
          overflow: hidden;
          flex-direction: column;

          display: flex;
          align-items: center;
          justify-content: center;

          position: relative;

          .image {
            position: relative;
            z-index: 2;
          }

          .text-alarm {
            font-weight: var(--font-weight-medium);
            font-size: 32px;
            line-height: 125%;
            letter-spacing: 0.015em;
            color: #212121;
            margin: 24px 0 40px;
            justify-content: center;
            text-align: center;
            position: relative;
            z-index: 2;
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;

            .confirm {
              cursor: pointer;
              font-weight: var(--font-weight-medium);
              font-size: 16px;
              line-height: 24px;
              background: #2e79da;
              padding: 14px 32px;
              border: 2px solid #2e79da;

              border-radius: 8px;
              margin: 0 16px 0 0;
              color: #ffffff;
            }

            .cancel {
              font-weight: var(--font-weight-medium);
              font-size: 16px;
              line-height: 24px;
              color: #2e79da;
              cursor: pointer;
              background: #fff;
              padding: 14px 32px;
              border: 2px solid #2e79da;
              border-radius: 8px;
            }
          }

          .close-popup {
            position: absolute;
            top: 60px;
            right: 45px;
            cursor: pointer;

            z-index: 2;
          }

          &:after {
            content: '';
            width: calc(100%);
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../../assets/vector/gradients/gradient-9.png);
          }
        }
      }
    }
  }
}

@media (min-width: $desktop-1) and (max-width: $desktop-2) {
  .container-site-notification {
    .site-notification-overlay {
      .site-notification-wrapper {
        .popup-clear-all-notification {
          .clear-all-notification {
            height: 90%;
          }
        }
      }
    }
  }
}

@media (min-width: $tablet-2) and (max-width: $desktop-1 - 1px) {
  .container-site-notification {
    .site-notification-overlay {
      top: 84px;

      .site-notification-wrapper {
        top: 85px;
        height: calc(100vh - 84px);

        .popup-clear-all-notification {
          .clear-all-notification {
            background: #fff;
            border-radius: 12px;
            width: 675px;
            height: 90%;
          }
        }
      }
    }

    &.site-notification-open {
      .site-notification-overlay {
        height: calc(100vh - 84px);
      }
    }
  }
}

@media (min-width: $tablet-1) and (max-width: $tablet-2 - 1px) {
  .container-site-notification {
    .site-notification-overlay {
      top: 47px;

      .site-notification-wrapper {
        top: 48px;
        height: calc(100vh - 47px);
      }
    }

    &.site-notification-open {
      .site-notification-overlay {
        height: calc(100vh - 47px);
      }
    }
  }
}

@media (max-width: $tablet) {
  .container-site-notification {
    .site-notification-overlay {
      top: 47px;

      .site-notification-wrapper {
        top: 48px;
        width: calc(100% - 48px);
        height: calc(100vh - 48px);

        .notification-items {
          .item {
            .item-info {
              .item-actions {
                display: flex;
                flex-direction: column;

                .cancel-btn {
                  margin: 0 0 15px;
                }
              }
            }
          }
        }

        .popup-clear-all-notification {
          .clear-all-notification {
            width: 100%;
            height: 75%;
            margin: 0 10px;

            .text-alarm {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
