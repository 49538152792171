.questionnaire-stepper {
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__graph {
    display: flex;
    align-items: center;
    width: 100%;
    height: 16px;
    background: #f8f9fa;
    border: 2px solid #f1f1f1;
    border-radius: 12px;
    overflow: hidden;

    .line {
      height: 100%;
      background-color: var(--blue-3);
      border-radius: 12px;
      transition: all 0.2s linear;
    }
  }

  &__count {
    margin-left: 10px;
    color: var(--black1);
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.0015em;

    @media screen and (min-width: 1280) {
      margin-left: 24px;
    }
  }
}
