.category-description {
  width: 100%;

  &__topic {
    margin: 0 0 4px;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
    color: var(--grey-3);
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 12px;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: var(--black);
  }

  &__actions {
    position: relative;
    color: var(--grey-3);
    font-size: 26px;
    cursor: pointer;
  }

  &__text {
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 180%;
    color: var(--black);

    a {
      color: var(--blue-3);
    }
  }

  &__remove {
    width: 14px;
    height: auto;
    cursor: pointer;
  }

  &__edit {
    width: 15px;
    height: auto;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }
}
