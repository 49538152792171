.request-session {
  padding: 8px 32px;
  font-weight: var(--font-weight-medium);
  font-size: 16px;
  line-height: 24px;

  &:disabled {
    cursor: auto;
    opacity: 0.4;

    &:hover,
    &:focus {
      opacity: 0.4;
      color: var(--blue-3);
      border-color: var(--blue-3);
    }
  }
}
