@import 'src/breakpoints';

.routes-wrapper {
  width: 100%;

  @media (min-width: $tablet-2) {
    padding: 0 0 0 245px;
  }

  @media (min-width: $full) {
    padding: 0 0 0 365px;
  }

  .section-wrap {
    height: calc(100% - var(--header-height));
    padding: 16px 16px 24px;
    background: var(--background);

    &.fullscreen {
      padding: 16px 0 24px;
    }

    @media (min-width: 980px) {
      padding: 24px 32px;
    }

    @media (min-width: $tablet-2) {
      height: calc(100% - var(--header-height));
    }

    @media (max-width: $tablet-2) {
      height: calc(100% - 48px);
    }

    &.open-chat {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          270.35deg,
          #28221f 0.36%,
          rgba(40, 34, 31, 0) 94.18%
        );
        opacity: 0.3;
      }
    }
  }
}
