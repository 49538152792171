.modern-radio-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-left: 20px;
}

.radio-outer-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;
  background-color: #fff;
}

.radio-inner-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transition: all 0.1s linear;
}

.helper-text {
  font-size: 14px;
  line-height: 150%;
  color: var(--black1);
  margin-left: 4px;
}
