@import 'src/breakpoints';

.header {
  height: var(--header-height);
  background: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edebf9;
  z-index: 4;
  position: relative;

  .header-left {
    height: 100%;

    .header-title-page {
      display: none;
      height: 100%;
      align-items: center;

      @media (min-width: $tablet-2) {
        display: flex;
      }

      .title {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: var(--gen_font_color);
        margin: 0 0 0 32px;

        @media (min-width: $full) {
          font-size: 32px;
        }
      }
    }
  }

  .header-right {
    .header-user-information {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;

      .notification-block {
        position: relative;
        display: flex;
        align-items: center;
        pointer-events: none;
        position: relative;

        .circle-notification {
          position: absolute;
          top: -4px;
          left: 12px;
        }

        &::before {
          content: '';
          position: absolute;
          top: -10px;
          left: -10px;
          width: 44px;
          height: 44px;
          background: #eff3ff;
          border-radius: 100px;
          z-index: -1;
          opacity: 0;
          -webkit-transition: opacity 200ms linear;
          -ms-transition: opacity 200ms linear;
          transition: opacity 200ms linear;
        }
        &:hover {
          &::before {
            opacity: 1;
          }
        }
        &.opened {
          &::before {
            opacity: 1;
          }
        }
      }

      .bell-img {
        width: auto;
        margin: 0 21px 0 0;
        cursor: pointer;
        flex-shrink: 0;
        pointer-events: auto;
        &.notification-open {
          path {
            fill: var(--blue-3);
            stroke: var(--blue-3);
          }
        }

        @media (min-width: $tablet-2) {
          margin: 0 24px 0 0;
        }

        &:hover {
          path {
            stroke: var(--blue-3);
          }
        }
      }

      .icon-chat {
        margin: 0 20px 0 0;
        cursor: pointer;
        flex-shrink: 0;
        display: flex;
        position: relative;
        position: relative;

        svg {
          path {
            transition: all 0.2s ease-out;
          }
        }

        .circle-notification {
          position: absolute;
          top: -4px;
          left: 12px;
        }

        &::before {
          content: '';
          position: absolute;
          top: -10px;
          left: -10px;
          width: 44px;
          height: 44px;
          background: #eff3ff;
          border-radius: 100px;
          z-index: -1;
          opacity: 0;
          -webkit-transition: opacity 200ms linear;
          -ms-transition: opacity 200ms linear;
          transition: opacity 200ms linear;
        }
        &:hover {
          &::before {
            opacity: 1;
          }
          svg {
            path {
              stroke: var(--blue-3);
            }
            ellipse {
              fill: var(--blue-3);
            }
          }
        }

        &.open-chat {
          &::before {
            opacity: 1;
          }
          svg {
            fill: var(--blue-3);
            path {
              stroke: var(--blue-3);
            }
            ellipse {
              fill: #fff;
            }
          }
        }

        @media (min-width: $tablet-2) {
          width: auto;
          margin: 0 24px 0 0;
        }
      }

      .user-avatar {
        display: flex;
        align-items: center;
        margin: 0 16px 0 0;
        height: 100%;

        @media (min-width: $tablet-2) {
          margin: 0 40px 0 0;
        }

        .user-avatar-image {
          width: 32px;
          height: 32px;
          border: 4px solid var(--white);
          border-radius: 50%;
          cursor: pointer;
          object-fit: cover;

          @media (min-width: $tablet-2) {
            width: 44px;
            height: 44px;
          }
        }

        .profile-menu-list {
          position: absolute;
          margin: 0;
          top: 80px;
          right: 40px;
          background-color: #fff;
          border-radius: 12px;
          box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
          padding: 24px 0 0 0;
          width: 176px;
          transition: all 0.2s ease-out;
          opacity: 0;
          transform: translateY(-15px) scale(0.9);
          z-index: -200;
          transform-origin: top right;
          list-style: none;

          .profile-menu-item {
            padding: 0 0 12px 24px;

            .link-to-page {
              font-weight: var(--font-weight-regular);
              font-size: 14px;
              line-height: 150%;
              color: var(--gen_font_color);
              text-decoration: none;

              @media screen and (min-width: $full) {
                font-size: 18px;
              }
            }
          }

          .log-out {
            font-weight: var(--font-weight-regular);
            font-size: 14px;
            line-height: 150%;
            color: #9f3c47;
            cursor: pointer;
            padding: 16px 0 14px 24px;
            border-top: 1px solid #9f3c47;

            @media screen and (min-width: $full) {
              font-size: 18px;
            }
          }

          &.show {
            z-index: 10;
            transform: none;
            opacity: 1;
            transition: all 0.2s ease-out;
            visibility: visible;
          }

          &.hide {
            visibility: hidden;
          }

          @media (max-width: 767px) {
            top: 50px;
            right: 5px;
            padding: 24px 0 0 0;
            width: 120px;
          }
        }
      }
    }

    &.show-programme {
      width: 50%;
      justify-content: end;
      display: flex;
      height: 100%;
    }
  }

  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 48px;
    height: 100%;
    padding: 14px 12px;
    // for ILTB
    // background-color: var(--william);
    background-color: var(--blue-3);
    cursor: pointer;

    @media (min-width: $tablet-2) {
      display: none;
    }

    &__line {
      width: 100%;
      height: 2px;
      margin-bottom: 6px;
      flex-shrink: 0;
      background-color: var(--white);

      &:last-child {
        margin: 0;
      }
    }
  }
}
.css-jd2i9d-Control,
.row-select__menu,
.css-4hh786-Control,
.css-c5v6uw-Control,
.row-select__control {
  border-radius: 6px !important;
}

@media (max-width: $desktop-1) {
  .header {
    .header-right {
      &.show-programme {
        width: auto;

        .header-user-information {
          width: auto;
        }
      }
    }
  }
}

#jvlabelWrap,
#jcont {
  left: 50px !important;
  right: unset !important;
}
