.reports-tooltip {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 24px 48px;
    background: var(--white);
    box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
    border-radius: 12px;
    font-size: 15px;
    line-height: 150%;
    color: #101821;
    z-index: 50;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 24px;

        &:last-child {
            margin: 0;
        }
    }

    &__label {
        margin-bottom: 4px;
        color: var(--grey-3);
    }
}