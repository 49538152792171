.switch {
  &__wrapper {
    width: 44px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    border: 1px solid #cdcbcb;
    border-radius: 16px;
    padding: 4px;
    cursor: pointer;

    &.active {
      border: 1px solid #ebcdb9;
      justify-content: flex-end;

      .switch__ellipse {
        background-color: #e75b32;
      }
    }
  }

  &__ellipse {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #929292;
  }
}
