@import 'src/breakpoints';

.invoices-filters {
  padding: 0 0 24px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .multi-select {
    width: 100%;

    &:first-child {
      margin: 0 0 10px 0;
    }
  }
  @media screen and (min-width: $tablet-1) {
    flex-direction: row;

    .multi-select {
      max-width: 300px;

      &:first-child {
        margin: 0 14px 0 0;
      }
    }
  }
}
