@import 'src/breakpoints';

.reports-sorting {
  display: none;
  // for ILTB
  // background-color: var(--blue);
  background-color: var(--reports-sorting_bg);

  @media (min-width: $reports-table-s) {
    display: table-row;
  }

  &__cell {
    align-items: center;
    flex-shrink: 0;
    padding: 8px;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    // for ILTB
    // color: var(--white);
    color: var(--brown-3);
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;

    @media (min-width: $full) {
      font-size: 15px;
      padding: 12px 8px;
    }

    svg {
      path {
        fill: var(--brown-3);
      }
    }

    &--checkbox {
      display: flex;
      align-items: center;
    }

    &--num {
      padding: 8px;
      text-align: center;

      @media screen and (min-width: $full) {
        padding: 12px 8px 20px;
      }
    }

    &--hidden {
      display: none;

      @media (min-width: $report-table-m) {
        display: table-cell;
      }
    }

    &--coach-name {
      display: none;
      @media (min-width: 1600px) {
        display: table-cell;
      }
    }

    &--rating {
      padding: 8px;

      @media (min-width: $full) {
        padding: 12px 24px 12px 8px;
      }
    }

    &--tooltip {
      display: table-cell;
      padding: 8px;

      @media (min-width: $report-table-m) {
        display: none;
      }
    }
  }

  &__sort {
    margin-right: 15px;
    flex-shrink: 0;
    vertical-align: middle;
  }

  &.engagement {
    .reports-sorting__cell {
      text-align: right;

      &--user,
      &--program,
      &--institution {
        text-align: left;
      }

      &--user {
        font-weight: normal;
      }

      &--num {
        text-align: center;
      }

      &--survey {
        text-align: center;
        // width: 100px;
      }

      &--coach {
        text-align: center;
        // width: 70px;
      }

      &--details {
        width: 100px;
      }

      &--sessions {
        width: 140px;
      }
    }
  }
}

.reports-checkbox__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0 16px;

  @media (min-width: $reports-table-s) {
    display: none;
  }
}
