@import 'src/breakpoints';

.reports-actions {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  margin-left: auto;
  margin-bottom: 24px;

  @media (min-width: $tablet-4) {
    width: auto;
  }

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin: 0 10px 0 auto;
    border-radius: 24px;
    background-color: transparent;
    // for ILTB
    // border: 2px solid var(--orange-2);
    border: 2px solid var(--gen_button_color);
    cursor: pointer;
    svg {
      path {
        fill: var(--gen_button_color);
      }
    }

    &--active {
      // for ILTB
      // border: 2px solid var(--viridian);
      // background: var(--viridian);
      border: 2px solid var(--gen_button_color);
      background: var(--gen_button_color);
      svg {
        path {
          fill: #fff;
        }
      }
    }

    @media (min-width: $desktop-2) {
      margin-left: 0;
      margin: 0 32px 0 auto;
    }
  }

  .reports-search {
    &__input-group {
      width: 100%;
    }

    &--mobile {
      position: static;

      &.reports-search--is-open {
        .reports-search__search {
          border-color: transparent;
        }
      }

      &.focus {
        border: 1px solid var(--viridian);
      }
    }

    &--is-open {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid var(--grey-5);
      top: 50%;
      transform: translate(0, -50%);
      z-index: 2;
      background-color: var(--background);
      padding-right: 20px;
    }
  }

  &__download {
    flex-shrink: 0;
    // margin-left: auto;
    padding: 9.5px 38px;
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 21px;
    background: var(--gen_button_color);
    &:hover {
      background: var(--gen_button_color) !important;
    }
  }

  .reports-actions__toggle {
    margin-left: auto;
  }
}
