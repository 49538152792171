.page-calendar {
  .rbc-toolbar {
    justify-content: flex-start;
    margin: 0;
    position: absolute;
    top: -145px;
    .rbc-toolbar-label {
      order: 3;
      flex-grow: inherit;
      padding: 0;
      font-weight: var(--font-weight-medium);
      font-size: 16px;
      line-height: 140%;
      margin-right: 10px;
      color: var(--gen_font_color);

      @media screen and (max-width: 1200px) {
        font-size: 20px;
      }
    }
    .rbc-btn-group {
      display: flex;
      &:first-child {
        order: 2;
        button {
          border: none;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            width: 8px;
            height: 9px;
            background-image: url('/assets/vector/arrow_2.svg');
            background-position: center;
            background-repeat: no-repeat;
          }
          &:hover {
            cursor: pointer;
          }
          &:focus,
          &:active,
          &:hover {
            background: transparent;
            box-shadow: none;
          }
          &:first-child {
            display: none;
          }
          &:nth-child(2) {
            &::before {
              right: 10px;
              left: unset;
              transform: rotate(180deg);
            }
          }
        }
      }
      &:last-child {
        order: 1;
        border-radius: 4px;
        border: 2px solid #f3c042;
        overflow: hidden;
        margin-right: 20px;
        @media screen and (max-width: 1200px) {
          margin-right: 0;
        }
        button {
          border: none;
          margin: 0;
          padding: 5px 21px;
          font-size: 16px;
          line-height: 140%;
          background-color: #ffffff;
          border-radius: 0;
          font-weight: var(--font-weight-medium);
          &::-moz-focus-inner {
            border: 0;
            padding: 0;
          }
          &:hover {
            cursor: pointer;
          }
          &.rbc-active {
            background: rgba(249, 191, 5, 0.5) !important;
            box-shadow: none;
          }
        }
      }
    }
  }

  .calendar-event__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-height: 80px;

    .button {
      width: 180px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .create-session {
      margin-bottom: 24px;
    }

    .edit-schedule {
      margin-right: 10px;
    }

    @media screen and (min-width: 769px) and (max-width: 1150px) {
      flex-direction: column;
      align-items: flex-end;
      .button {
        font-size: 14px;
        width: 160px;
        &.edit-schedule {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      .button {
        padding: 0;

        &.edit-schedule {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.globalClass_dadc .wrap_6730 {
  z-index: 3;
}
