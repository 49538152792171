@import 'src/breakpoints';

.locked-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__img {
    max-width: 100%;
    height: auto;
    margin: 50px 0 16px;

    @media (min-width: $tablet-1) {
      margin: 80px 0 16px;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 125%;
  }

  &__text {
    font-size: 15px;
    line-height: 150%;
  }
}
