.action-tooltip {
    position: absolute;
    top: 26px;
    right: 0;
    padding: 24px 16px 16px;
    background: var(--white);
    box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
    border-radius: 12px;
    font-size: 15px;
    line-height: 150%;
    color: #101821;
    z-index: 50;

    &__btn {
        width: 100%;
        margin-bottom: 10px;
        background-color: transparent;
        border: 0;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 18px;
        font-weight: normal;
        text-align: left;
        line-height: 150%;
        color: var(--black);
        white-space: nowrap;
        cursor: pointer;
        transition: background-color .25s;

        &:last-child {
            margin: 0;
        }

        &:hover {
            background-color: #F1F1F1;
        }
    }
}