@import 'src/breakpoints';

.text-editor {
  &__area {
    width: 100%;
    min-height: 100px;
    border: 1px solid #cdcbcb;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 40px;
    outline: none;
    font-family: Poppins, sans-serif;
    color: var(--black);
    font-size: 16px;
    line-height: 180%;
    resize: none;
    transition: border-color 0.25s;

    &:focus {
      border-color: var(--blue-3);
    }

    @media (min-width: $program-m) {
      padding: 32px;
    }
  }
}

.quill {
  margin-bottom: 40px;
  width: 100%;
  height: auto;
  background-color: #fff;

  .ql-toolbar {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    .ql-formats {
      .ql-header {
        .ql-picker-label {
          display: flex;
        }
      }
    }
  }

  .ql-tooltip {
    left: 0 !important;
  }

  .ql-container {
    min-height: 100px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border: 1px solid #cdcbcb;
  }
}
