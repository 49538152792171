@import 'src/breakpoints';

.activities {
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 18px;
    line-height: 130%;
  }

  &__list {
    margin-top: 12px;
    width: 100%;
    min-height: 64px;
    max-height: 204px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .custom-scroll__wrapper {
      width: 100% !important;
      .ScrollbarsCustom-Scroller {
        .ScrollbarsCustom-Content {
          display: block !important;
        }
      }
    }

    .spinner__wrapper {
      span {
        display: none;
      }
    }

    .ScrollbarsCustom-Track {
      width: 6px !important;
      right: -12px !important;
      background-color: var(--grey-2) !important;
    }

    .ScrollbarsCustom-Thumb {
      background-color: var(--grey-4) !important;
      opacity: 0.8;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: $full) {
    padding: 32px;
    margin-top: 32px;

    &__title {
      font-size: 24px;
    }

    &__list {
      max-height: 218px;
    }
  }

  @media screen and (max-width: $tablet) {
    padding: 16px 12px;

    &__list {
      margin-top: 0;
      max-height: 655px;
    }

    .ScrollbarsCustom-Track {
      right: -10px !important;
    }
  }
}
