.auth-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: var(--auth-footer-min-height);
  padding: 20px 4.5%;
  // for ILoveToBe:
  // background-color: var(--black);
  background: var(--background);
  border-top: 1px solid #d8d8d8;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1024px) {
    padding: 40px 6.25%;
  }

  &__logo {
    width: 56px;
    height: auto;
    margin-bottom: 9px;
    flex-shrink: 0;

    @media (min-width: 768px) {
      width: 97px;
      margin-bottom: 0;
      margin-right: 24px;
    }

    @media (min-width: 1024px) {
      width: 110px;
    }
  }

  &__copy {
    font-weight: var(--font-weight-regular);
    font-size: 13px;
    line-height: 160%;
    color: var(--silver);
  }
}
