@import 'src/breakpoints';
@import '~react-perfect-scrollbar/dist/css/styles.min.css';

.topics-content {
  margin: -35px 0 24px -24px;
  background-color: var(--white);
  border-radius: 4px;

  @media (min-width: $tablet-4) {
    position: absolute;
    top: 0;
    left: 360px;
    width: calc(100% - 360px);
    height: 100%;
    margin: 0;
    border-left: 8px solid var(--opened_activity);
    border-radius: 0;
    overflow: hidden !important;
  }

  @media (min-width: $program-l) {
    left: 377px;
    width: calc(100% - 377px);
  }

  @media (min-width: $full) {
    left: 577px;
    width: calc(100% - 577px);
  }

  .ScrollbarsCustom-Wrapper {
    @media (max-width: $tablet-4 - 1px) {
      position: static !important;
    }
  }

  .ScrollbarsCustom-Scroller {
    position: static !important;
    padding: 48px 16px;
    margin-right: unset !important;
    &::-webkit-scrollbar {
      width: auto;
    }
    &::-webkit-scrollbar-track,
    #no-scroll1::-webkit-scrollbar-thumb {
      background: transparent;
    }

    @media (min-width: $tablet-4) {
      position: absolute !important;
      padding: 32px 56px;
    }
  }

  .ScrollbarsCustom-Track {
    width: 6px !important;
    background-color: var(--grey-2) !important;
  }

  .ScrollbarsCustom-Thumb {
    background-color: var(--grey-4) !important;
    opacity: 0.8;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;

    @media (min-width: 1280px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &--blocked {
      color: var(--grey-3);

      .topics-content__title {
        color: var(--grey-3);
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    text-transform: uppercase;
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: var(--grey-5);
  }

  &__status-icon {
    flex-shrink: 0;
    margin-left: 10px;
    @media (max-width: $tablet) {
      width: 22px;
      height: 22px;
    }
  }

  &__num {
    margin: 0 16px 4px 0;
    font-size: 14px;
    line-height: 21px;
    color: var(--grey-3);
  }

  &__block {
    display: flex;
    align-items: center;
    color: var(--grey-5);
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    text-transform: uppercase;
  }

  &__block-icon {
    margin-left: 22px;
    flex-shrink: 0;
  }

  &__action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    order: -1;
    position: relative;
    padding: 12px 24px;
    margin: 10px auto 32px;
    background: transparent;
    // for ILTN
    // border: 2px solid var(--mint);
    // color: var(--mint);
    border: 2px solid #5bcbc9;
    color: #5bcbc9;
    border-radius: 8px;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.01em;
    cursor: pointer;
    transition: color 0.25s linear, border-color 0.25s linear;

    &:focus {
      border-color: var(--william);
      color: var(--william);
    }

    @media (hover: hover) {
      &:hover {
        border-color: var(--william);
        color: var(--william);
      }
    }

    @media (min-width: 1280px) {
      width: auto;
      order: 0;
      margin: 0;
      padding: 8px 24px;
    }
  }

  &__title {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 30px;
    color: var(--black);
    font-weight: var(--font-weight-medium);

    @media screen and (min-width: $full) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__date {
    display: inline-flex;
    align-items: center;
    color: var(--grey-3);
  }

  &__date-icon {
    margin-right: 15px;
  }
}
