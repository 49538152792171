@import 'src/breakpoints';

.notification {
  position: fixed;
  bottom: 20px;
  right: 0;
  background: linear-gradient(
      90deg,
      rgba(183, 162, 240, 0.15) 2.19%,
      rgba(94, 211, 242, 0) 100%
    ),
    #ffffff;
  border-radius: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  padding: 50px;
  width: 100%;
  // max-width: calc(100% - 40px);
  max-width: 100%;
  z-index: 9999;

  p {
    font-size: 20px;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 20px;
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 30px;

    &::before {
      position: absolute;
      content: '';
      top: 13px;
      right: 0;
      width: 14px;
      height: 1px;
      background: #717171;
      transform: rotate(-45deg);
    }

    &::after {
      position: absolute;
      content: '';
      top: 13px;
      right: 0px;
      width: 14px;
      height: 1px;
      background: #717171;
      transform: rotate(45deg);
    }
  }

  @media (min-width: 540px) {
    max-width: 600px;
    right: 20px;
  }

  &--enter {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }

  &--enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s, transform 0.3s;
  }

  &--exit {
    opacity: 1;
  }

  &--exit-active {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
    transition: opacity 0.5s, transform 0.3s;
  }

  &__content {
    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      background: transparent;
      border: none;
      font-size: 20px;
      outline: none;
      cursor: pointer;
      width: 30px;
      height: 30px;

      &::before {
        position: absolute;
        content: '';
        top: 13px;
        right: 0;
        width: 25px;
        height: 2px;
        background: #717171;
        transform: rotate(-45deg);
      }

      &::after {
        position: absolute;
        content: '';
        top: 13px;
        right: 0px;
        width: 25px;
        height: 2px;
        background: #717171;
        transform: rotate(45deg);
      }
    }

    .notification-title {
      margin-bottom: 15px;
    }
  }

  &.temp {
    display: block;
    background: #ffffff;
    border: 1px solid #edebf9;
    border-radius: 12px;
    bottom: unset;
    top: 116px;
    right: 40px;
    padding: 24px 48px 24px 24px;
    max-width: 600px;
    display: flex;
    align-items: center;
    max-width: 442px;
    box-shadow: none;

    h3 {
      font-weight: var(--font-weight-medium);
      font-size: 24px;
      line-height: 125%;
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      background: transparent;
      border: none;
      font-size: 20px;
      outline: none;
      cursor: pointer;
      width: 30px;
      height: 30px;

      &::before {
        position: absolute;
        content: '';
        top: 13px;
        right: 0;
        width: 14px;
        height: 1px;
        background: #717171;
        transform: rotate(-45deg);
      }

      &::after {
        position: absolute;
        content: '';
        top: 13px;
        right: 0px;
        width: 14px;
        height: 1px;
        background: #717171;
        transform: rotate(45deg);
      }
    }

    svg {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin-right: 16px;
    }

    @media (max-width: $tablet) {
      top: 60px;
      right: 16px;
      h3 {
        font-size: 20px;
      }
    }
  }
}
