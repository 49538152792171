.decline-session{
    text-align: center;
    &__title{
        margin-bottom: 20px;
    }   
    &__actions{
        display: flex;
        justify-content: center;
        button{
            &:first-child{
                margin-right: 20px;
            }
        }
    }
}