.registration-checkbox__wrapper {
  margin-bottom: 32px;

  &.error {
    .checkbox__item {
      .checkbox__item-box {
        border: 2px solid var(--stiletto);
      }
    }
  }

  .custom-checkbox__item {
    .custom-checkbox__item-label {
      font-size: 15px;
      line-height: 140%;
      color: var(--black1);

      @media screen and (min-width: 768px) {
        font-size: 17px;
      }

      a {
        text-decoration: none;
        color: var(--blue-3);
        font-weight: var(--font-weight-medium);
      }
    }

    &--checked {
      .custom-checkbox__item-box::before {
        background-color: var(--blue-3);
      }
    }
  }
}
