.EditScheduleTImePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 134px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  line-height: 22.5px;
  font-weight: var(--font-weight-regular);

  &.disabled {
    background-color: #f7f7f7;
    cursor: auto;
  }

  &.react-datepicker-ignore-onclickoutside {
    border: 1px solid #2e79da;
  }

  &.error {
    border: 1px solid var(--pink);
  }

  @media screen and (max-width: 859px) {
    width: 175px;
  }

  @media screen and (max-width: 450px) {
    width: 140px;
  }

  @media screen and (max-width: 350px) {
    width: 120px;
  }
}
