@import "src/breakpoints";

.engagement-reports__download {
  display: flex;
  position: relative;

  .button {
    width: 30px;
    height: 44px;
    padding: 0;
    border-radius: 0px 8px 8px 0px;
    background-color: var(--gen_button_color);
    transition: all 0.2s linear;

    @media (min-width: 400px) {
      width: 48px;
    }

    span {
      display: flex;
      align-items: center;
      svg {
        path {
          fill: #fff;
        }
      }
    }
    &:hover {
      background-color: var(--gen_button_color);
    }

    &__download {
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      border-radius: 8px 0px 0px 8px;
      background-color: var(--gen_button_color);

      @media (min-width: 400px) {
        width: 200px;
        font-size: 14px;
        line-height: 21px;
        font-weight: var(--font-weight-medium);
      }

      @media (min-width: $reports-table-s) {
        width: 228px;
      }
    }

    &__options {
      &.active {
        background-color: var(--gen_button_color);

        svg {
          transform: rotate(180deg);
        }
      }

      &.hide {
        opacity: 0;
        transition: all 0.1s linear;
      }
    }
  }

  .download__options {
    position: absolute;
    width: 100%;
    height: auto;
    display: none;
    flex-direction: column;
    align-items: center;
    top: 52px;
    left: 0;
    background: #ffffff;
    border: 1px solid #eaeefa;
    box-sizing: border-box;
    box-shadow: 0px 12px 24px rgba(209, 207, 205, 0.4);
    border-radius: 8px;
    padding: 7px;
    z-index: 1;

    @media (min-width: 400px) {
      padding: 14px;
    }

    &.active {
      display: flex;
    }

    span {
      font-weight: var(--font-weight-medium);
      font-size: 13px;
      line-height: 130%;
      padding: 14px 5px;
      cursor: pointer;
      transition: all 0.2s linear;
      border-radius: 8px;

      @media (min-width: 400px) {
        font-size: 14px;
      }

      @media (min-width: $reports-table-s) {
        padding: 14px 10px;
      }

      &:hover {
        background: rgba(46, 121, 218, 0.08);
        color: #2e79da;
      }
    }
  }
}
