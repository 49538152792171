.generate-invoice-modal {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 125%;
    color: var(--black1);
  }

  &__invoices-list {
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .invoice-card {
      width: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #edebf9;
      border-radius: 12px;
      padding: 12px;
      margin: 6px 0;

      &__content {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        margin: 0 16px;

        .code {
          font-size: 12px;
          line-height: 140%;
          font-weight: var(--font-weight-medium);
          letter-spacing: 0.0015em;
          color: var(--black1);
        }

        .date,
        .total {
          font-weight: var(--font-weight-regular);
          font-size: 12px;
          line-height: 125%;
          color: var(--grey-3);
        }
      }

      &__btn {
        color: var(--blue-3);
        font-size: 12px;
        line-height: 140%;
        cursor: pointer;

        img {
          margin-right: 8px;
        }
      }

      @media screen and (min-width: 675px) {
        padding: 16px 24px;

        &__content {
          .code {
            font-size: 16px;
          }
        }
      }
    }
  }

  &__btn {
    margin-top: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    border: 2px solid var(--blue-3);
    border-radius: 8px;
    color: var(--blue-3);
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 21px;
    max-width: 200px;
    align-self: center;
    cursor: pointer;
  }
}
