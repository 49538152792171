@import 'src/breakpoints';

.choose-time {
  position: relative;
  width: 100%;

  &__label {
    display: inline-block;
    margin-bottom: 7px;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.0015em;
    color: var(--black);

    @media (min-width: $modal) {
      font-size: 17px;
      line-height: 140%;
    }
  }

  &__placeholder {
    &--active {
      color: var(--black);
    }
  }

  &__open-picker {
    position: relative;
    display: flex;
    width: 100%;
    height: 42px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    border: 1px solid #edebf9;
    border-radius: 8px;
    background: var(--white);
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 140%;
    color: var(--grey-3);
    cursor: pointer;

    &--disabled,
    &[disabled] {
      color: var(--grey-4);
      cursor: auto;
    }
  }

  &__icon {
    &--active {
      transform: rotate(180deg);
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 56px;
    left: 0;
    border: 1px solid var(--grey-5);
    border-radius: 16px;
    background: var(--white);
    box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
    z-index: 20;

    @media (min-width: $modal) {
      top: 72px;
    }
  }

  &__duration {
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 160%;
    color: var(--grey-3);
    font-weight: normal;
  }

  &__menu-head {
    position: relative;
    width: 100%;
    padding: 12px 31px;
    border-bottom: 1px solid var(--grey-5);
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 20px;
    color: var(--black);

    @media (min-width: $eventForm-s) {
      padding: 16px 31px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__set-time {
    width: calc(100% - 62px);
    margin: 0 auto 24px;
    font-size: 15px;
    line-height: 125%;
    flex-shrink: 0;

    &:disabled {
      cursor: auto;
      color: var(--grey-3);
      border-color: var(--grey-3);
      opacity: 0.6;
    }

    @media (min-width: $eventForm-s) {
      margin: 0 auto 32px;
    }
  }

  &__close {
    position: absolute;
    width: 12px;
    height: 12px;
    top: calc(50% - 6px);
    right: 22px;

    @media (min-width: $modal) {
      right: 28px;
    }
  }

  .react-time-picker__wrapper {
    border: 0;
  }

  &__slots {
    display: flex;
    flex-wrap: wrap;
    margin: 0 24px 0;
  }

  &__slot {
    display: flex;
    align-items: center;
    margin: 8px 8px 0 0;
    padding: 4px 16px;
    height: 35px;
    background: var(--light-gray);
    border-radius: 8px;
    color: var(--grey-3);
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 125%;
    text-align: center;
  }

  &__slots-title {
    margin: 8px 24px 0;
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: var(--black);
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 4px 16px;
    margin-bottom: 16px;
    text-align: center;
    background: rgba(159, 60, 71, 0.08);
    border-radius: 8px;
    font-size: 13px;
    line-height: 160%;
    color: var(--stiletto);
  }

  .time-picker {
    width: 100%;
  }
}
