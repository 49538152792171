@import 'src/breakpoints';

.upcoming-sessions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 16px 0;

  &__title {
    padding: 0 20px;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--grey-3);
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.empty-data {
      justify-content: center;
      min-height: 250px;
    }

    .spinner__wrapper {
      span {
        display: none;
      }
    }
  }

  @media screen and (min-width: $full) {
    &__title {
      padding: 0 24px;
    }
  }

  @media screen and (max-width: ($desktop - 1px)) {
    margin-top: 0;
  }

  @media screen and (max-width: $tablet) {
    &__title {
      padding: 0 12px;
      font-size: 18px;
    }
  }
}
