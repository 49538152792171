.edit-schedule-checkbox__item {
  cursor: pointer;
  display: flex;
  align-items: center;

  &--checked {
    .edit-schedule-checkbox__item-box {
      background: rgba(46, 121, 218, 0.1);
      border: 1px solid #2e79da;
    }

    .edit-schedule-checkbox__item-label {
      color: #2e79da;
    }
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 58.57px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }

  &-input {
    display: none;
  }

  &-label {
    font-weight: var(--font-weight-medium);
    color: #212121;
    font-size: 14px;
    line-height: 21px;
  }
}
