.edit-schedule {
  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 125%;
    color: #212121;
  }
}
