@import 'src/breakpoints';

.upcoming-session-item {
  width: 100%;
  padding: 20px;
  border-top: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;

  &:first-child {
    border-top: none;
  }

  .main-info {
    width: 100%;
    display: flex;

    &__data {
      display: flex;
      flex-direction: column;
      width: calc(100% - 80px);

      .name {
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.0015em;
        color: var(--black1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .date {
        font-weight: var(--font-weight-regular);
        font-size: 13px;
        line-height: 160%;
        color: var(--grey-3);
      }
    }

    &__link {
      width: 40px;
      text-decoration: none;
      margin-left: 40px;
    }
  }

  .session-details {
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .users-avatars {
      display: flex;
      .avatar {
        width: 30px;
        height: 30px;
        border: 2px solid #ffffff;
        border-radius: 50%;

        &.coach {
          margin-left: -9px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .session-status {
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 26px;
      background: #fdc94c;
      border-radius: 100px;
      font-weight: var(--font-weight-medium);
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      color: var(--black1);
      text-transform: capitalize;
    }
  }

  &:hover {
    cursor: pointer;

    .main-info {
      &__data {
        .name {
          color: var(--blue-3);
        }
      }
    }
  }

  @media screen and (min-width: $full) {
    padding: 20px 24px;

    .main-info {
      &__data {
        .name {
          font-size: 17px;
        }
      }
    }
  }

  @media screen and (max-width: $tablet) {
    padding: 16px 12px;
    .main-info {
      &__data {
        .name {
          font-size: 16px;
        }

        .date {
          font-size: 14px;
        }
      }
    }
  }

  .session-details {
    .users-avatars {
      .avatar {
        width: 32px;
        height: 32px;
      }
    }
  }
}
