@import 'src/breakpoints';

.dashboard-links {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .link-item {
    max-width: 47%;
  }

  @media screen and (max-width: ($desktop - 1px)) {
    display: none;
  }
}
