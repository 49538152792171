@import "src/breakpoints";

.marks-stat {
    padding: 12px 0;

    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 1px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__question {
        flex-shrink: 0;
        width: 230px;
        padding-right: 20px;
        font-size: 12px;
        line-height: 120%;

        @media (min-width: $desktop) {
            width: 260px;
        }
    }

    &__grades {
        display: flex;
        flex-grow: 1;
    }

    &__grade {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-right: 1px;
        background: var(--grey-gun);
        border-radius: 4px;
        font-size: 14px;
        line-height: 120%;
    }
}