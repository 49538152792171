@import "src/breakpoints";

.session-card {
  position: relative;
  width: 100%;
  margin: 24px 0;
  padding: 32px 24px 12px;
  border: 1px solid var(--grey-girl);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: white;
  color: var(--black);

  &--pending {
    .session-card__line {
      background-color: var(--session_pending);
    }

    .session-card__status {
      background-color: var(--session_pending);
    }
  }

  &--confirmed,
  &--accepted {
    .session-card__line {
      background-color: var(--session_confirmed);
    }

    .session-card__status {
      background-color: var(--session_confirmed);
    }
  }

  &--completed {
    .session-card__line {
      background-color: var(--session_completed);
    }

    .session-card__status {
      background-color: var(--session_completed);
    }
  }

  &--expired,
  &--noCoach {
    .session-card__line {
      background-color: var(--session_passed);
    }

    .session-card__status {
      background-color: var(--session_passed);
    }
  }

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    border-radius: 12px 12px 0 0;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 24px;

    @media (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
  }

  &__status {
    align-self: flex-start;
    flex-shrink: 0;
    height: 31px;
    padding: 4px 12px;
    margin-top: 12px;
    border-radius: 100px;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;

    &::first-letter {
      text-transform: uppercase;
    }

    @media (min-width: $tablet) {
      align-self: flex-end;
      margin: 0;
    }
  }

  &__details {
    margin: 12px 0 0;
    font-size: 15px;
  }

  &__alert {
    margin: 12px 0 0;
    font-size: 15px;
    color: var(--session_late_canceled);
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    @media (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-self: center;
    }
  }

  &__users {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    @media (min-width: $tablet) {
      margin: 0 16px 0 0;
    }
  }

  &__user {
    position: relative;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
    border-radius: 100px;
    object-fit: cover;
    border: 2px solid white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));

    @media (min-width: $tablet) {
      width: 44px;
      height: 44px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-self: flex-end;

    @media (min-width: $tablet) {
      flex-shrink: 0;
    }

    .button {
      margin: 0 12px 12px 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__btn {
    padding: 12px 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
    border: 0;
    background-color: var(--blue-3);

    @media (hover: hover) {
      &:hover {
        background-color: var(--blue);
      }
    }
  }

  &__edit-btn {
    display: flex;
    align-items: center;
    height: 48px;
    margin: 0 12px 12px 0;
    padding: 10px 24px 10px 0;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--grey-3);
    cursor: pointer;
    transition: color 0.25s linear;

    @media (hover: hover) {
      &:hover {
        color: var(--silver);
      }
    }

    .icon {
      position: relative;
      bottom: 1px;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  &__cancel-btn {
    padding: 12px 24px;
    border: 2px solid var(--pink);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background-color: transparent;
    color: var(--pink);

    @media (hover: hover) {
      &:hover {
        border-color: var(--pinky);
        color: var(--pinky);
      }
    }
  }

  .event-info {
    margin-bottom: 12px;
  }
}
