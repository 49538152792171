.search-users__section {
    width: auto;
    display: flex;
    justify-content: flex-end;
    

    @media (min-width: 768px) {
        margin-right: 20px;
    }

    &--open {
        width: 100%;
    }

    .input__wrapper {
        position: relative;
        width: 100%;
        display: flex;

        .search-input {
            width: 100%;
            display: flex;
            padding-right: 25px;
            border: none;
        }

        svg {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(0, -50%);
            cursor: pointer;
        }
    }

    .search-icon__wrapper {
        cursor: pointer;
    }
}