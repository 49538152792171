@import 'src/breakpoints';

.suggested-coaches__notification {
  margin: 6px 0;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 12px 24px rgba(209, 207, 205, 0.4);
  border-radius: 12px;
  background: url('../../../assets/vector/suggested-coaches-notification/bg-image.svg')
    #2e79da top right no-repeat;

  .notification__title {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0015em;
    color: #ffffff;
  }

  .notification__description {
    margin-top: 8px;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
  }

  .notification__link {
    margin-top: 16px;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      font-weight: var(--font-weight-medium);
      font-size: 14px;
      line-height: 125%;
      letter-spacing: 0.0015em;
      color: #ffffff;
      vertical-align: middle;
    }

    img {
      margin-left: 10px;
    }
  }

  @media screen and (min-width: $full) {
    padding: 24px;
    .notification__title {
      font-size: 20px;
    }

    .notification__description {
      font-size: 15px;
    }

    .notification__link {
      margin-top: 24px;
      a {
        font-size: 16px;
      }
    }
  }
}
