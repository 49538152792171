@import 'src/breakpoints';

.form-stat {
  display: flex;

  &__name {
    flex-shrink: 0;
    width: 230px;
    border-bottom: 1px solid var(--grey-jedi);
    padding: 5px 20px 9px 0;
    font-weight: var(--font-weight-medium);
    font-size: 12px;
    line-height: 140%;

    @media (min-width: $desktop) {
      font-size: 14px;
      line-height: 120%;
      width: 260px;
    }
  }

  &__sessions {
    display: flex;
    flex-grow: 1;
    padding-bottom: 9px;
    border-bottom: 1px solid var(--grey-jedi);

    &:last-child {
      margin: 0;
    }
  }

  &__session {
    flex-shrink: 0;
    width: 48px;
    margin-right: 1px;
  }

  &__session-cell {
    padding: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 120%;
  }

  &__session-cell--title {
    height: 24px;
    background: var(--grey-gun);
    border-radius: 4px;
    font-weight: var(--font-weight-medium);
    font-size: 14px;
  }

  &__session-cell--percent {
    color: var(--grey-3);
  }
}
