.session-table {
  width: 100%;

  &__wrap {
    padding: 8px;
    background: var(--light-gray-2);
    // border-radius: 24px;

    @media (min-width: 1320px) {
      // margin: 0 40px 8px;
      padding: 17px;
    }
  }

  &__head {
    display: none;

    @media screen and (min-width: 1320px) {
      display: table-row;
    }
  }

  &__head-col {
    font-size: 12px;
    line-height: 150%;
    color: var(--grey-3);
    font-weight: var(--font-weight-regular);
    text-align: right;
    padding: 15px 8px;

    &:nth-child(1) {
      text-align: center;
    }
    &:nth-child(2) {
      text-align: left;
    }
    &:nth-child(3) {
      text-align: left;
    }

    @media screen and (min-width: 1920px) {
      font-size: 15px;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 24px 0;

    @media screen and (min-width: 1320px) {
      display: table-row;
      margin: unset;
    }
  }

  &__col {
    font-size: 12px;
    line-height: 150%;
    color: var(--gen_font_color);
    text-align: right;
    padding: 0 15px;

    @media screen and (min-width: 1920px) {
      font-size: 15px;
    }

    &:nth-child(1) {
      text-align: center;
    }
    &:nth-child(2) {
      text-align: left;
    }
    &:nth-child(3) {
      text-align: left;
    }

    &.session__number {
      display: block;
      order: 1;
      font-weight: var(--font-weight-medium);

      @media screen and (min-width: 1320px) {
        display: table-cell;
        order: unset;
        font-weight: normal;
      }
    }

    &.session__name {
      flex: 1 1 auto;
      order: 2;
      font-weight: var(--font-weight-medium);

      @media (min-width: 1320px) {
        // min-width: 220px;
        width: auto;
        order: unset;
        font-weight: normal;
        padding: 0 15px;
      }
    }

    &.session__topics {
      width: 100%;
      order: 3;
      margin-bottom: 16px;

      .categories__list {
        margin-block-end: 0;
      }

      @media (min-width: 1320px) {
        width: auto;
        order: unset;
        margin-bottom: unset;

        .categories__list {
          margin-block-end: 1em;
        }
      }
    }

    &.session__scheduled {
      order: 4;
      text-align: left;
      @media (min-width: 1320px) {
        order: unset;
        text-align: right;
      }
    }

    &.session__completed {
      order: 5;
      text-align: left;

      @media (min-width: 1320px) {
        order: unset;
        text-align: right;
        margin-top: unset;
      }
    }

    &.session__status {
      order: 7;
      width: 100%;
      text-align: left;
      margin-top: 16px;

      @media (min-width: 1320px) {
        order: unset;
        width: auto;
        text-align: right;
        margin-top: unset;
      }
    }

    &.session__cancelled {
      order: 6;
      text-align: left;
      @media (min-width: 1320px) {
        order: unset;
        text-align: right;
      }
    }

    &--name {
      display: block;
      color: #929292;
      font-size: 12px;
      line-height: 160%;

      @media screen and (min-width: 1320px) {
        display: none;
      }
    }

    .categories__list {
      padding-inline-start: 20px;
      font-weight: normal;
    }

    @media (min-width: 1320px) {
      font-weight: var(--font-weight-medium);
    }
  }

  &__icon {
    display: inline-block;
    padding: 4px 8px;
    background-color: white;
    font-weight: var(--font-weight-medium);
    font-size: 12px;
    line-height: 150%;

    @media screen and (min-width: 1920px) {
      font-size: 15px;
    }

    &--booked {
      color: var(--gomo);
    }

    &--completed {
      color: var(--blue-3);
    }

    &--canceled {
      color: var(--grey-3);
    }
  }
}
