.generate-invoice {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 44px;
  background-color: var(--blue-3);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  cursor: pointer;
}
