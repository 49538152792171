.init-loader {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);

    &__logo {
        width: 97px;
        height: auto;
        flex-shrink: 0;

        @media (min-width: 768px) {
            width: 120px;
        }
    }
}