@import 'src/breakpoints';

.side-menu-notifications {
  width: 225px;
  position: fixed;
  left: 10px;
  bottom: 38px;
  display: flex;
  flex-direction: column;
  z-index: 100;

  @media screen and (max-width: $program-m) {
    width: 92%;
    left: 50%;
    bottom: 34px;
    transform: translate(-50%, 0);
  }

  @media screen and (min-width: $full) {
    width: 325px;
    left: 20px;
    padding: 32px 20px;
  }
}
