.section__container {
  width: 100%;
  max-width: 90%;
  margin: 50px auto 0px;
  position: relative;
  z-index: 2;
  min-height: calc(
    100vh - var(--auth-footer-min-height) - var(--auth-header-min-height) - 80px
  );

  @media screen and (min-width: 1280px) {
    max-width: 80%;
    margin: 110px auto 0px;
  }

  @media screen and (min-width: 1500px) {
    min-width: 1400px;
  }

  .questionnaire__title {
    display: flex;
    flex-direction: column;

    .title_text {
      max-width: 90%;
      margin: 0 auto;
      text-align: center;
      font-weight: var(--font-weight-medium);
      font-size: 32px;
      line-height: 110%;
      letter-spacing: 0.01em;

      @media screen and (min-width: 768px) {
        font-size: 40px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 50px;
        max-width: 710px;
      }
      span {
        // for ILTB
        // color: var(--orange-2);
        color: var(--pink);
      }
    }

    .subtitle_text {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      text-align: center;

      @media screen and (min-width: 1280px) {
        margin-top: 40px;
      }

      span {
        margin-top: 12px;
        font-size: 12px;
        line-height: 150%;
        color: #333333;

        @media screen and (min-width: 1280px) {
          font-size: 15px;
        }

        &.bold {
          margin-top: 0;
          font-weight: var(--font-weight-medium);
          font-size: 15px;
          line-height: 140%;
          letter-spacing: 0.0015em;
          color: #333333;

          @media screen and (min-width: 1280px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .questionnaire__cards {
    margin-top: 55px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .card {
      margin-top: 20px;
      max-width: 681px;
      width: 100%;
      // for ILTB
      // background: linear-gradient(0deg, #faf4f0, #faf4f0);
      background: #fff;
      border-radius: 16px;
      padding: 30px;

      &:first-child {
        margin-top: unset;
        margin-right: 32px;
      }

      @media screen and (min-width: 768px) {
        padding: 50px;
        // width: 49%;
        margin-top: unset;
      }

      @media screen and (min-width: 1920px) {
        padding: 40px 175px 40px 110px;
      }

      &__image {
        width: 100%;
        max-width: 400px;
        img {
          width: 100%;
          height: auto;
        }
      }

      &__title {
        // max-width: 400px;
        margin-top: 40px;
        font-size: 24px;
        line-height: 125%;
        color: #101821;
        font-weight: var(--font-weight-medium);
      }

      &__description {
        // max-width: 400px;
        font-weight: var(--font-weight-regular);
        font-size: 15px;
        line-height: 150%;
        color: #101821;
        p {
          margin: 20px 0;
          .card__link {
            font-weight: var(--font-weight-medium);
            color: #101821;
            text-decoration: none;
          }
          span {
            font-weight: var(--font-weight-medium);
          }
        }
      }
    }
  }

  .questionnaire__start {
    position: relative;
    margin-top: 55px;

    @media screen and (min-width: 1280px) {
      margin-top: 105px;
    }

    .start__title {
      font-weight: var(--font-weight-medium);
      font-size: 32px;
      line-height: 125%;
      text-align: center;
      letter-spacing: 0.015em;
      color: #101821;
      span {
        color: var(--orange-2);
      }
    }

    .start__button {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      .start_link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 48px;
        border: none;
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 125%;
        color: #ffffff;
        // for ILTB
        // background-color: var(--orange-2);
        // border-radius: 56px;
        background-color: var(--black);
        border-radius: 8px;
        text-decoration: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .start__decor {
      position: absolute;
      display: none;

      @media screen and (min-width: 768px) {
        top: 100px;
        right: 50px;
        display: block;
      }

      @media screen and (min-width: 1280px) {
        top: 100px;
        right: 200px;
      }
    }
  }

  .questionnaire__description {
    position: relative;
    margin: 0 auto;
    margin-top: 55px;
    max-width: 1394px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // for ILTB
    // background: linear-gradient(0deg, var(--william), var(--william));
    // background: linear-gradient(0deg, rgba(56, 29, 219, 0.02), rgba(56, 29, 219, 0.02)), #FFFFFF;
    background: #fff;
    border-radius: 16px;
    padding: 30px;

    @media screen and (min-width: 768px) {
      margin-top: 105px;
      padding: 50px;
      flex-direction: row;
    }

    @media screen and (min-width: 1000px) {
      margin-top: 105px;
      padding: 100px 120px;
      flex-direction: row;
    }

    @media screen and (min-width: 1280px) {
      padding: 102px 110px;
    }

    .decor_img {
      position: absolute;
      bottom: 0;
      left: 40px;
      z-index: 0;
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    .column {
      width: 100%;
      margin-top: 30px;
      max-width: 470px;

      &:first-of-type {
        margin-top: unset;
      }

      @media screen and (min-width: 768px) {
        margin-top: unset;
        width: 40%;
      }

      @media screen and (min-width: 1280px) {
        width: 45%;
      }
    }

    .description__text {
      z-index: 1;
      font-weight: var(--font-weight-medium);
      font-size: 22px;
      line-height: 125%;
      letter-spacing: 0.015em;
      // for ILTB
      // color: #ffffff;
      color: #000;

      span {
        // for ILTB
        // color: var(--yellow-3);
        color: var(--pink);
      }

      @media screen and (min-width: 1280px) {
        font-size: 32px;
      }
    }

    .description__list {
      .list {
        margin: 0;
        padding-inline-start: 20px;

        @media screen and (min-width: 1280px) {
          padding-inline-start: 40px;
        }
        .list__item {
          font-size: 16px;
          line-height: 130%;
          // for ILTB
          // color: #ffffff;
          color: #000;
          padding-left: 10px;
          margin-top: 15px;

          @media screen and (min-width: 1280px) {
            margin-top: 30px;
            font-size: 18px;
            padding-left: 17px;
          }

          &:first-child {
            margin-top: 0;
          }

          &::marker {
            font-size: 18px;
            line-height: 22px;
            // for ILTB
            // color: #548a84;
            color: var(--pink);
            font-weight: var(--font-weight-medium);
            @media screen and (min-width: 1280px) {
              font-size: 25px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
