@import 'src/breakpoints';

.program-card {
  width: 100%;
  display: flex;
  text-decoration: none;
  padding: 12px 0;
  border-top: 1px solid #d8d8d8;

  &:first-child {
    border-top: none;
  }

  &__img {
    width: 100%;
    max-width: 160px;
    height: 88px;
    border-radius: 12px;
    object-fit: cover;

    @media screen and (min-width: $full) {
      height: 100px;
    }
  }

  &__content {
    margin-left: 16px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $full) {
      margin-left: 24px;
    }

    .content__wrapper {
      display: flex;
      width: 100%;

      .program__data {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
      }
    }
  }

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 130%;
    color: var(--black1);

    @media screen and (min-width: $full) {
      font-weight: var(--font-weight-medium);
      font-size: 18px;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 8px 0 0;
  }

  &__info-item {
    display: flex;
    align-items: center;
    margin: 0 12px 0 0;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 21px;
    color: var(--grey-3);

    @media screen and (min-width: $full) {
      font-weight: var(--font-weight-medium);
      font-size: 15px;
      line-height: 22px;
    }

    &::before {
      content: '\00B7';
      display: inline-block;
      margin-right: 12px;
      font-size: 24px;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__progress {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  &__progress-bar {
    position: relative;
    flex-grow: 1;
    max-width: 100%;
    height: 6px;
    margin: 0 8px 0 0;
    border-radius: 12px;
    background-color: var(--grey-4);

    @media (min-width: 1024px) {
      margin: 0 13px 0 0;
    }

    @media (min-width: $full) {
      max-width: 400px;
      height: 8px;
    }
  }

  &__progress-fill {
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 12px;
    background-color: var(--blue-3);
    transition: width 0.35s linear;
  }

  &__progress-num {
    flex-shrink: 0;
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 18px;
    color: var(--black1);

    @media screen and (min-width: $full) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  &__coach {
    margin-left: 16px;
    padding: 4px 8px;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 175px;
    min-height: 44px;
    background: #f8f9fa;
    border-radius: 12px;

    @media screen and (min-width: $full) {
      padding: 12px;
      margin-left: 24px;
      height: 68px;
      max-width: 223px;
    }

    .coach-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #ffffff;

      @media screen and (min-width: $full) {
        width: 44px;
        height: 44px;
      }
    }

    .coach-info {
      display: flex;
      flex-direction: column;
      margin-left: 4px;

      span {
        font-size: 12px;
        line-height: 160%;
        color: #929292;

        @media screen and (min-width: $full) {
          font-size: 13px;
        }
      }

      &__name {
        width: 100%;
        font-weight: var(--font-weight-medium);
        font-size: 12px;
        line-height: 120%;
        color: var(--black1);
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

        @media screen and (min-width: $full) {
          font-weight: var(--font-weight-medium);
          font-size: 15px;
        }
      }
    }
  }

  &:hover {
    .program-card__title {
      color: var(--blue-3);
    }
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;

    &__img {
      max-width: 100%;
      height: auto;
      max-height: 140px;
    }

    &__content {
      margin: 8px 0 0;

      .content__wrapper {
        flex-direction: column;
      }
    }

    &__info {
      margin: 6px 0 0;
    }

    &__coach {
      margin: 16px 0 0;
      max-width: 200px;
    }

    &__progress {
      margin-top: 23px;
    }
  }
}
