.edit-schedule {
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .form-buttons__wrapper {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add_time_of__button {
        width: 150px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #212121;
        border-radius: 8px;
        background-color: #ffffff;
        cursor: pointer;
        font-weight: var(--font-weight-medium);
        font-size: 14px;
        line-height: 21px;
        color: #212121;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .form__submit {
        width: 167px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2e79da;
        border-radius: 8px;
        font-weight: var(--font-weight-medium);
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        border: none;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: auto;
        }
      }
    }

    .array-item__wrapper {
      position: relative;
    }
  }

  &__checkboxes-container {
    flex: 1 1 auto;
    flex-direction: column;

    .checkboxes__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &__break-days {
    display: flex;
    flex-direction: column;
    position: relative;

    .title {
      margin-top: 24px;
      font-weight: var(--font-weight-medium);
      font-size: 15px;
      line-height: 150%;
      color: #929292;
    }

    .fields {
      width: 100%;
      margin-top: 24px;
      display: flex;
      align-items: flex-start;

      .edit-schedule__timepickers {
        .EditScheduleTImePicker {
          height: 44px;
        }
      }
    }
  }

  &__timepickers {
    display: flex;
    margin-left: 16px;
    align-items: center;

    .separator {
      width: 6px;
      height: 3px;
      margin: 0 8px;
      background: #212121;
    }

    .react-datepicker-popper {
      .react-datepicker {
        font-family: 'Poppins', sans-serif;
      }
      .react-datepicker.react-datepicker--time-only {
        border: 1px solid #edebf9;
        box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.14);

        .react-datepicker__triangle {
          display: none;
        }

        .react-datepicker__time-container {
          width: 134px;

          @media screen and (max-width: 859px) {
            width: 175px;
          }

          @media screen and (max-width: 450px) {
            width: 140px;
          }

          @media screen and (max-width: 375px) {
            width: 120px;
          }

          .react-datepicker__header {
            display: none;
          }

          .react-datepicker__time {
            border-radius: 8px;

            .react-datepicker__time-box {
              width: 100%;
              .react-datepicker__time-list {
                .react-datepicker__time-list-item {
                  font-weight: var(--font-weight-regular);
                  margin: 8px 0;
                  font-size: 15px;
                  line-height: 150%;
                  color: #212121;

                  &--selected {
                    font-weight: var(--font-weight-medium);
                    background-color: #f7f6fe;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__dailyBreak {
    margin-top: 30px;
    width: 100%;
    display: flex;
    position: relative;

    .switcher {
      flex: 1 1 auto;
      height: 44px;
      display: flex;
      align-items: center;

      &__label {
        margin-left: 12px;
        font-weight: var(--font-weight-medium);
        font-size: 14px;
        line-height: 125%;
        color: #212121;
      }
    }

    .edit-schedule__timepickers {
      .EditScheduleTImePicker {
        height: 44px;
      }
    }
  }

  &__timeOff {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #f7f7f7;
    border-radius: 12px;

    .remove__icon {
      cursor: pointer;
      margin-left: 20px;
    }

    .label {
      font-weight: var(--font-weight-medium);
      font-size: 15px;
      line-height: 150%;
      color: #212121;

      .remove__icon {
        display: none;
      }
    }

    .edit-schedule__timepickers {
      .EditScheduleTImePicker {
        width: 104px;
        height: 44px;
        background-color: #ffffff;
      }

      .react-datepicker-popper {
        .react-datepicker.react-datepicker--time-only {
          .react-datepicker__time-container {
            width: 104px;
          }
        }
      }
    }
  }

  &__datePickers {
    display: flex;
    margin-left: 16px;
    align-items: center;

    .separator {
      width: 6px;
      height: 3px;
      margin: 0 8px;
      background: #212121;
    }

    .react-datepicker-popper {
      @media screen and (min-width: 860px) {
        left: -40px !important;
      }
      .react-datepicker {
        background: #ffffff;
        border: 1px solid #cdcbcb;
        border-radius: 16px;

        .react-datepicker__triangle {
          display: none;
        }

        .react-datepicker__navigation.react-datepicker__navigation--next {
          background-image: url(../../../assets/vector/short_left.svg);
          background-repeat: no-repeat;
          background-position: center;
        }

        .react-datepicker__navigation.react-datepicker__navigation--previous {
          background-image: url(../../../assets/vector/short_left.svg);
          background-repeat: no-repeat;
          background-position: center;
          transform: rotate(180deg);
        }

        .react-datepicker__navigation-icon::before {
          display: none;
        }

        .react-datepicker__month-container {
          .react-datepicker__header {
            background-color: #ffffff;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }

          .react-datepicker__month {
            .react-datepicker__week {
              .react-datepicker__day {
                &--selected {
                  border-radius: 50%;
                  background-color: #2e79da;
                }
              }
            }
          }
        }
      }
    }
  }

  &__customError {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding: 15px;
    background: rgba(236, 78, 124, 0.1);
    border-radius: 12px;

    .error-message {
      margin-left: 13px;
      font-size: 14px;
      line-height: 125%;
      color: var(--pink);
    }
  }

  @media screen and (max-width: 859px) {
    &__form {
      .form-buttons__wrapper {
        margin-top: 16px;
        flex-direction: column;
        align-items: flex-start;

        .form__submit {
          margin-top: 24px;
          width: 100%;
        }
      }
    }

    &__checkboxes-container {
      flex: 1 1 auto;
      flex-direction: column;

      .checkboxes__wrapper {
        width: 100%;
        flex-wrap: wrap;
        justify-content: start;
        .edit-schedule-checkbox__item {
          margin: 4px 4px 0 0;
        }
      }
    }

    &__break-days {
      padding-bottom: 24px;
      border-bottom: 1px solid#D8D8D8;
      .fields {
        flex-direction: column;

        .edit-schedule__timepickers {
          width: 100%;
          justify-content: center;
        }
      }
    }

    &__timepickers {
      margin: 16px 0 0 0;
    }

    &__dailyBreak {
      flex-direction: column;
      width: 100%;
      padding-bottom: 24px;
      border-bottom: 1px solid#D8D8D8;

      .switcher {
        height: auto;
      }

      .edit-schedule__timepickers {
        justify-content: center;
      }
    }

    &__timeOff {
      flex-direction: column;
      .remove__icon {
        display: none;
      }

      .label {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .remove__icon {
          display: block;
        }
      }

      .edit-schedule__timepickers {
        .EditScheduleTImePicker {
          width: 175px;
          height: 44px;
          background-color: #ffffff;

          @media screen and (max-width: 450px) {
            width: 130px;
          }

          @media screen and (max-width: 375px) {
            width: 120px;
          }
        }

        .react-datepicker-popper {
          .react-datepicker {
            font-family: 'Poppins', sans-serif;
          }
          .react-datepicker.react-datepicker--time-only {
            border: 1px solid #edebf9;
            box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.14);

            .react-datepicker__triangle {
              display: none;
            }

            .react-datepicker__time-container {
              width: 175px;

              @media screen and (max-width: 450px) {
                width: 130px;
              }

              @media screen and (max-width: 375px) {
                width: 120px;
              }
            }
          }
        }
      }
    }

    &__datePickers {
      margin: 16px 0 0 0;
    }
  }
}
