.page-calendar {
  position: relative;
  box-sizing: border-box;
  z-index: 1;

  .rbc-row {
    max-height: 32px;
  }

  .rbc-time-column {
    margin-top: -1px;
    &.rbc-time-gutter {
      .rbc-time-slot {
        background: #fff !important;
        &::before {
          content: none;
        }
      }
    }
  }

  .rbc-current-time-indicator {
    background-color: #e75b32;
    &::before {
      content: '';
      position: absolute;
      left: -6px;
      top: -5px;
      width: 12px;
      height: 12px;
      background-color: #e75b32;
      border-radius: 50%;
    }
  }

  &.day__view {
    .rbc-timeslot-group {
      min-height: 130px;
    }
    .rbc-calendar .rbc-time-view .rbc-time-content .rbc-timeslot-group {
      border-bottom: 2px solid #edebf9;
      z-index: 1;
    }
    .rbc-day-slot .rbc-time-slot {
      border-top: none;
    }
    .rbc-time-header-gutter {
      display: none;
    }
    .rbc-header {
      justify-content: center !important;
    }
  }

  .rbc-calendar {
    .rbc-time-view .rbc-row {
      min-height: auto;
    }
    position: relative;
    .rbc-month-view {
      min-height: 725px;
      border: 1px solid #edebf9;
      border-radius: 4px;

      .rbc-today {
        background-color: #fff;
      }

      .event__title {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: horizontal;
        width: 100%;
        font-size: 10px;
        line-height: 18px;
      }
    }
    .rbc-off-range-bg {
      background: var(--background) !important;
    }
    .rbc-row-bg {
      background-color: #fff;
    }
    .rbc-month-header {
      font-family: Poppins;
      font-style: normal;
      font-weight: var(--font-weight-medium);
      font-size: 16px !important;
      line-height: 125%;
      min-height: 32px;
      background: var(--background);

      .rbc-header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: var(--font-weight-medium);
        span {
          &:before {
            display: none;
          }
        }

        &:nth-child(6),
        &:nth-child(7) {
          background: var(--background);
        }
      }
    }

    .rbc-row-segment {
      padding: 4px 1px 0;
    }
    .rbc-date-cell {
      position: relative;
      font-family: Poppins;
      font-style: normal;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;

      .absence-dates__wrapper {
        order: 1;
        display: flex;
        align-items: center;

        .month-header-icon {
          cursor: pointer;
        }

        .unavailable-dates {
          &__list {
            visibility: hidden;
            position: absolute;
            width: 120%;
            top: 30px;
            left: -10%;
            border-radius: 12px;
            background: #ffffff;
            border: 1px solid #f7f7f7;
            box-shadow: 0px 12px 24px rgba(209, 207, 205, 0.4);
            border-radius: 8px;
            padding: 12px;
            transition: all 0.3s linear;
            text-align: left;
            z-index: 100;

            ul {
              padding-inline-start: 20px;
            }

            @media screen and (min-width: 1600px) {
              width: 110%;
              left: -5%;
              z-index: 100;
            }

            &.active {
              visibility: visible;
            }
          }

          &__list-title {
            margin-bottom: 8px;
            font-weight: var(--font-weight-medium);
            font-size: 15px;
            line-height: 150%;
            color: #929292;
          }

          &__list-item {
            font-weight: var(--font-weight-medium);
            font-size: 16px;
            line-height: 130%;
            color: #212121;

            span {
              color: #929292;
            }
          }
        }
      }

      a {
        text-decoration: none;
        order: 2;
        font-weight: var(--font-weight-medium);
        font-size: 12px;
        line-height: 18px;
        color: #101821;
        z-index: 1;
      }

      &.rbc-off-range {
        a {
          color: var(--grey-3);
        }
      }
    }
    .rbc-header {
      border-left-color: #edebf9;
      border-left-width: 1px;
      border-bottom: none;
      span {
        position: relative;
        font-size: 16px;
        font-weight: var(--font-weight-medium);
        &:before {
          content: '';
          position: absolute;
          right: -5px;
          top: -5px;
          width: 30px;
          height: 30px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 100px;
        }
      }
      &.rbc-today {
        background-color: #2e79da !important;
        color: #fff;

        span {
          font-weight: var(--font-weight-medium);

          &:before {
            background: rgba(255, 255, 255, 0.1) !important;
            //temp
            display: none;
          }
        }
      }
    }

    // the code below is needed to overlay the bottom row of the calendar with a tooltip with unavailable dates
    .rbc-month-row {
      overflow: visible;
      z-index: 7;
    }
    .rbc-month-row + .rbc-month-row {
      border-top: none;
      z-index: 6;
    }
    .rbc-month-row + .rbc-month-row + .rbc-month-row {
      z-index: 5;
    }
    .rbc-month-row + .rbc-month-row + .rbc-month-row + .rbc-month-row {
      z-index: 4;
    }
    .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row {
      z-index: 3;
    }
    .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row {
      z-index: 2;
    }
    .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row
      + .rbc-month-row {
      z-index: 1;
    }

    .rbc-day-bg {
      border-left-color: #edebf9;
      border-top: 2px solid #edebf9;
      border-left-width: 1px;
    }
    .rbc-time-view {
      border-color: #edebf9;
      border-radius: 4px;
      overflow: hidden;
      .rbc-time-header {
        background: var(--background);
        max-height: 46px;
        margin-right: 0 !important;
      }
      .rbc-time-header-cell {
        min-height: 36px;

        .rbc-header {
          &.unavailableData {
            background-image: none !important;
            background-repeat: no-repeat !important;
          }
        }
      }
      .rbc-time-header-gutter {
        background: var(--background);
      }
      .rbc-time-content > .rbc-time-gutter {
        .rbc-time-slot {
          .rbc-label {
            display: block;
            margin-top: -12px;
            font-size: 12px;
            line-height: 18px;
          }
        }

        .rbc-timeslot-group {
          border-color: #fff;
          &:first-child {
            .rbc-time-slot {
              .rbc-label {
                display: none;
              }
            }
          }
        }
      }
      .rbc-time-content {
        background-color: #fff;
        border-color: #edebf9;
        max-height: calc(100vh - 330px);
        margin-bottom: -2px;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        }
        .rbc-time-slot {
          background-color: #fff;
          min-height: 10px;
          border-top: none;
        }
        .rbc-timeslot-group {
          z-index: 1;
          border-color: #edebf9;
        }
        .rbc-events-container {
          height: 100%;
          background-color: #fff;
          border: none;

          .rbc-event {
            min-height: 93px;
            border: none;
            margin: 4px 5px;
            padding: 12px 8px 16px;
            align-items: flex-start;
            z-index: 3;
            border-radius: 12px;
            &::before {
              content: none;
            }
            .rbc-event-label {
              font-weight: var(--font-weight-medium);
              max-width: 100%;
              font-size: 10px;
              line-height: 15px;
            }
            &.google-event {
              .rbc-event-label {
                max-width: calc(100% - 30px);
              }
            }
            .event__title {
              margin-top: 4px;
              overflow: hidden;
              font-weight: var(--font-weight-medium);
              font-size: 14px;
              line-height: 120%;
              align-self: self-end;
              max-height: calc(100% - 12px);
            }
            .rbc-event-content {
              display: flex;
            }
            ul.avatars {
              display: flex;
              flex-direction: row-reverse;
              list-style-type: none;
              margin: 0;
              padding: 0;
              position: absolute;
              top: 8px;
              right: 15px;
              width: calc(100% - 100px);
              height: 30px;
              overflow: hidden;
              display: flex;
              flex-wrap: wrap;
              li {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid white;
                display: inline-block;
                position: relative;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                -webkit-transition: 0.2s ease;
                transition: 0.2s ease;
                overflow: hidden;
                margin-left: -5px;

                @media screen and (min-width: 1920px) {
                  width: 30px;
                  height: 30px;
                  margin-left: -10px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  background: rgb(230, 229, 229);
                }
              }
            }
            .google-icon {
              display: block;
              position: absolute;
              right: 10px;
              top: 10px;
              &::before {
                content: '';
                height: 26px;
                position: absolute;
                right: 0;
                top: 0;
                width: 26px;
                background-image: url(/assets/images/googleIconColored.svg);
                background-size: cover;
                background-position: center center;
                border-radius: 50%;
                background-color: #fff;
                border: 2px solid #fff;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
              }
            }
          }
        }
      }
    }
    .rbc-time-header-cell {
      .rbc-header {
        min-height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--background);
        border-bottom-color: var(--background);
      }
    }
    .rbc-time-header-content {
      border-color: var(--background);
    }
    .rbc-time-header-cell-single-day {
      display: block !important;
      .rbc-today,
      .rbc-header {
        font-family: Poppins;
        font-style: normal;
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        line-height: 125%;
        min-height: 32px;
        background: var(--background);
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .rbc-allday-cell {
      .rbc-today {
        background-color: #fff;
      }
      .rbc-day-bg + .rbc-day-bg {
        border: none !important;
      }
    }
    .rbc-day-bg.rbc-today {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 3px;
        right: 3px;
        width: 16px;
        height: 16px;
        background: var(--yellow-4);
        border-radius: 50%;
      }
    }
    .rbc-event {
      .avatars {
        display: none;
      }
      .google-icon {
        display: none;
        span {
          display: none;
        }
      }
    }
    .rbc-month-view {
      .rbc-event {
        position: relative;
      }
    }
    // Events
    .rbc-event,
    .rbc-day-slot .rbc-background-event {
      color: #929292;
      border-radius: 6px;
      min-height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 1px 4px;
      width: calc(100% - 8px);
      padding-left: 30px;
      &.rbc-selected {
        background-color: rgba(104, 155, 149, 0.2);
        outline: none;
      }
      &:focus {
        outline: none;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 25px;
        height: 28px;
        background-position: center !important;
        background: rgba(146, 146, 146, 0.15);
        background-image: url(/assets/images/googleIcon.svg);
        background-repeat: no-repeat !important;
        border-radius: 2px;
      }
    }
    .rbc-event {
      min-height: 28px;
      &.google-event {
        color: #000;
        border: 1px solid darkgrey;
        // position: relative;
        &::before {
          background-image: url(/assets/images/googleIconColored.svg);
          background-color: #fff;
          background-size: contain;
          height: 100%;
        }
      }
      &.confirmed {
        color: #126f63;
        &::before {
          background: rgba(46, 121, 218, 0.15);
          background-image: url(/assets/images/userIcon.svg);
        }
      }
      &.pending {
        color: #2e79da;
        &::before {
          background: rgba(46, 121, 218, 0.15);
          background-image: url(/assets/images/userIconBlue.svg);
        }
      }
      &.late_canceled {
        color: #ec4e7c;
        &::before {
          background: rgba(236, 78, 124, 0.15);
          background-image: url(/assets/images/userIconPink.svg);
        }
      }
      &.completed {
        // background-image: repeating-linear-gradient(-40deg,transparent 4px 10px,#d8d8d8 8px 20px);
        &::before {
          background-color: #d8d8d8;
        }
      }
    }
    .rbc-show-more {
      text-decoration: none;
      position: relative;
      background: rgba(237, 189, 72, 0.2);
      color: #513e0f;
      border-radius: 2px;
      min-height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 1px 4px;
      width: calc(100% - 8px);
      padding: 0 10px;
      overflow: hidden;
    }
  }
  &.day__view {
    .rbc-time-view {
      .rbc-time-header-cell {
        min-height: 32px;
      }
      .rbc-time-content {
        .rbc-events-container {
          .rbc-event {
            min-height: 100px;
            .rbc-event-content {
              display: block;
            }
            ul.avatars {
              position: relative;
              width: fit-content;
              padding-left: 18px;
              margin-top: 6px;
            }
            &.google-event {
              ul.avatars {
                display: none;
              }
              .google-icon {
                position: relative;
                span {
                  display: block;
                  padding: 6px 44px;
                }
                &::before {
                  right: unset;
                  left: 8px;
                }
              }
            }
          }
        }
      }
    }
    .rbc-header {
      span {
        &:before {
          right: -8px;
          top: -3px;
          width: 38px;
          height: 38px;
        }
      }
    }
  }

  &.week__view {
    .rbc-calendar {
      .rbc-time-view {
        .rbc-time-content {
          .rbc-events-container {
            .rbc-event {
              .rbc-event-content {
                .event__title {
                  margin-top: 4px;
                  overflow: hidden;
                  font-weight: var(--font-weight-medium);
                  font-size: 12px;
                  line-height: 120%;
                  align-self: self-end;
                  max-height: calc(100% - 12px);
                }

                ul.avatars {
                  right: 6px;
                  li {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.rbc-timeslot-group .unavailableSlot {
  position: relative;

  &:nth-child(7n) {
    &:before {
      content: 'Unavailable';
      top: -8px;
      font-weight: var(--font-weight-regular);
      font-size: 14px;
      line-height: 120%;
      color: #929292;
      text-align: center;
      position: absolute;
      width: 100%;
      height: auto;
      z-index: 999;
    }
  }

  &.UA {
    &:before {
      display: none;
    }
  }
}
