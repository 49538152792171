.event-info {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;

  &__icon {
    margin-right: 12px;
    flex-shrink: 0;
  }
}
