@import 'src/breakpoints';

.recent-chat-item {
  width: 100%;
  display: flex;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  margin: 6px 0;
  cursor: pointer;

  &__logo {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__content {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: calc(100% - 40px - 12px - 34px);

    .user-name {
      width: 100%;
      font-style: normal;
      font-weight: var(--font-weight-medium);
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.0015em;
      color: var(--black1);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .last-message {
      width: 100%;
      font-weight: var(--font-weight-regular);
      font-size: 14px;
      line-height: 150%;
      color: var(--black1);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &__time {
    font-weight: var(--font-weight-regular);
    font-size: 11px;
    line-height: 160%;
    color: var(--grey-3);
  }

  @media screen and (min-width: $full) {
    &__logo {
      width: 56px;
      height: 56px;
    }

    &__content {
      width: calc(100% - 56px - 12px - 34px);

      .user-name {
        font-size: 17px;
      }
      .last-message {
        font-size: 15px;
      }
    }

    &__time {
      font-size: 13px;
    }
  }
}
