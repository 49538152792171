@import 'src/breakpoints';

.todo-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-top: 1px solid #d8d8d8;

  &:first-child {
    border-top: none;
  }

  &__program-name {
    font-weight: var(--font-weight-regular);
    font-size: 15px;
    line-height: 150%;
    color: #929292;
  }

  &__name {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 140%;
    color: var(--black1);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: var(--blue-3);
    }
  }

  &__list {
    margin: 22px 0 0;
    padding: 0;

    .action-list__item {
      padding: 5px 0;
      margin: 0;
    }

    .action-list__item-due {
      font-size: 14px;
    }

    .action-list__item-text {
      font-weight: var(--font-weight-regular);
      font-size: 16px;
    }

    .action-list__item-box {
      width: 16px;
      height: 16px;

      &::before {
        width: 10px;
        height: 10px;
      }
    }
  }

  @media screen and (min-width: $full) {
    &__program-name {
      font-weight: var(--font-weight-medium);
    }

    &__name {
      font-weight: var(--font-weight-medium);
      font-size: 18px;
    }

    &__list {
      .action-list__item {
        padding: 10px 0;
        margin: 0;
      }
      .action-list__item-due {
        font-size: 15px;
      }

      .action-list__item-text {
        font-size: 18px;
      }

      .action-list__item-box {
        width: 24px;
        height: 24px;

        &::before {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  @media screen and (max-width: $tablet) {
    padding: 16px 0;

    &__program-name {
      font-size: 12px;
    }

    &__name {
      font-size: 14px;
    }

    &__list {
      margin: 16px 0 0;
    }
  }
}
