@import 'src/breakpoints';

.reports-tabs {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;

  @media (min-width: $tablet-4) {
    margin-bottom: 26px;
  }

  &::after {
    content: none;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 2px;
    background-color: var(--grey-5);
    z-index: 1;

    @media (min-width: $tablet-4) {
      content: '';
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 8px 24px;
    background: var(--white);
    border: 1px solid #cdcbcb;
    border-radius: 32px;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: var(--black);
    cursor: pointer;

    @media (min-width: $tablet-4) {
      display: none;
    }
  }

  &__toggle-icon {
    margin-left: 16px;
    flex-shrink: 0;
  }

  &__btn-group {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 56px;
    left: 0;
    padding: 16px 12px;
    background-color: var(--white);
    border: 1px solid var(--grey-5);
    box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
    border-radius: 12px;
    z-index: 3;

    @media (min-width: $tablet-4) {
      position: static;
      flex-direction: row;
      width: auto;
      padding: 0;
      background-color: transparent;
      border: none;
      box-shadow: none;
      // z-index: 2;
    }
  }

  &__btn {
    position: relative;
    border: 0;
    padding: 12px;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;
    text-align: left;
    color: var(--gen_font_color);
    background-color: transparent;
    border-radius: 12px;
    transition: background-color 0.25s;
    cursor: pointer;

    @media (min-width: $tablet-4) {
      padding: 0 0 15px;
      margin-right: 32px;
      border-bottom: 5px solid transparent;
      background-color: transparent;
      font-weight: var(--font-weight-medium);
      font-size: 16px;
      line-height: 125%;
      border-radius: 0;
      color: var(--gen_font_color);
      transition: color 0.2s, border-bottom-color 0.2s;
      z-index: 5;
    }

    @media (min-width: $desktop-1) {
      margin-right: 48px;
    }

    @media (min-width: $full) {
      font-size: 24px;
    }

    &:hover {
      @media (max-width: $tablet-4 - 1px) {
        background-color: var(--light-gray);
      }
    }

    &--active {
      @media (min-width: $tablet-4) {
        // fot ILTB
        // border-bottom: 5px solid var(--william);
        // color: var(--william);
        border-bottom: 3px solid var(--blue-3);
        color: var(--blue-3);
      }
    }
  }
}
