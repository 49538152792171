@import 'src/breakpoints';

.billing-table {
  background-color: #fff;
  padding: 0 12px 10px;

  @media screen and (min-width: $tablet-1) {
    padding: 0 24px 10px;
  }

  .table__wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  &__head {
    width: 100%;
    display: flex;
    padding: 16px 0;
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    line-height: 150%;

    @media screen and (min-width: $desktop-2) {
      padding: 16px 8px 16px 56px;
    }

    .head-col {
      width: 50%;

      &.right {
        display: flex;
        justify-content: space-between;

        .details {
          font-weight: var(--font-weight-regular);
          font-size: 12px;
          line-height: 150%;
          color: var(--blue-3);
          cursor: pointer;
        }
      }
    }
  }
  &__details {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 24px;
    padding: 0 24px;

    .table__row {
      width: 100%;
      display: flex;
      padding: 0 0 7px 0;

      &.head {
        padding: 7px 0;
        .table__col {
          color: var(--db_total);
          border-bottom: none;
        }
      }
    }

    .table__col {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1 0 auto;
      width: calc((100% - 50px) / 8);
      font-weight: var(--font-weight-regular);
      font-size: 12px;
      line-height: 150%;
      padding: 5px;
      border-bottom: 1px solid #d8d8d8;

      &.number {
        width: 50px;
        justify-content: center;
      }

      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        padding: 4px 10px;
        text-transform: capitalize;
        font-weight: var(--font-weight-medium);
        color: var(--gomo);
        background-color: #fff;
      }
    }

    &.scrollbar-container {
      height: auto;
      min-width: 1000px;

      > .ps__rail-y {
        opacity: 1;
        width: 6px;
        border-radius: 8px;
        background-color: var(--grey-gun);

        &:hover,
        &:focus,
        &.ps--clicking {
          background-color: var(--grey-gun);
        }

        .ps__thumb-y {
          width: 100%;
          right: 0;
          border-radius: 8px;
          background-color: var(--grey-5);
        }
      }

      > .ps__rail-x {
        opacity: 1;
        height: 6px;
        border-radius: 8px;
        background-color: var(--grey-gun);

        &:hover,
        &:focus,
        &.ps--clicking {
          background-color: var(--grey-gun);
        }

        .ps__thumb-x {
          height: 100%;
          bottom: 0;
          border-radius: 8px;
          background-color: var(--grey-5);
        }
      }
    }
  }
}
