.mobile-sorting {
  list-style: none;
  padding: 0 16px;
  margin: 0 0 45px;

  &__sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border-radius: 32px;
    margin-bottom: 8px;
    padding: 8px 4px 8px 24px;
    border: 1px solid rgba(66, 150, 229, 0.3);
    font-size: 17px;
    line-height: 140%;
    color: var(--blue);
    cursor: pointer;

    &--active {
      background: var(--blue-3);
      color: white;
      border: 1px solid rgba(66, 150, 229, 0.3);

      .mobile-sorting__direct {
        background-color: white;
      }
    }
  }

  &__direct {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: var(--blue-3);
    cursor: pointer;
  }

  &__icon {
    flex-shrink: 0;

    &--down {
      transform: rotate(180deg);
    }
  }

  &__btn {
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 47px;
    border-radius: 8px;
    background-color: var(--blue-3);
    color: #fff;
    outline: none;
    border: none;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 125%;
    cursor: pointer;

    &--disabled {
      opacity: 0.5;
    }
  }
}
