.auth-header {
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  height: var(--auth-header-min-height);
  padding: 8px 4.5%;
  // for ILoveToBe:
  // background-color: var(--green);
  background-color: #ffffff;
  border-bottom: 1px solid #d8d8d8;

  @media (min-width: 1024px) {
    padding: 20px 6.25%;
  }

  &__logo {
    width: 56px;
    height: auto;
    flex-shrink: 0;

    @media (min-width: 768px) {
      width: 97px;
    }

    @media (min-width: 1024px) {
      width: 110px;
    }
  }
}
