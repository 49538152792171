@import 'src/breakpoints';

.single-report {
  //display: flex;
  height: calc(100% - var(--header-height));
  width: 100%;
  max-width: 1258px;
  margin: 0 auto;
  padding: 32px 16px;
  background-color: var(--background);

  @media (min-width: 1920px) {
    max-width: 100%;
  }

  &__head {
    padding: 16px 16px 32px;
    margin-bottom: 16px;
    border-radius: 12px;
    background-color: var(--white);

    @media (min-width: $tablet-4) {
      padding: 17px 32px;
    }
  }

  &__topic-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 150%;
    color: var(--grey-3);

    @media (min-width: $tablet-4) {
      padding-left: 70px;
    }
  }

  &__topic-link {
    text-decoration: none;

    .back-icon {
      margin-right: 16px;
      flex-shrink: 0;

      @media (min-width: $tablet-4) {
        display: none;
      }
    }
  }

  &__topic-num {
    margin-right: 8px;
    font-size: 14px;
    line-height: 130%;
    color: var(--gen_font_color);
  }

  &__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    color: var(--gen_font_color);

    @media (min-width: $tablet-4) {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 110%;
      font-weight: 700;
      letter-spacing: 0.01em;
    }

    .heading {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
    svg {
      path {
        fill: var(--blue-3);
      }
    }
    .back-icon {
      display: none;
      flex-shrink: 0;

      @media (min-width: $tablet-4) {
        display: block;
        margin-right: 35px;
        width: 32px;
        height: auto;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;

    color: var(--gen_font_color);
    display: flex;
    justify-content: space-between;
    .userInfo {
      display: flex;
      align-items: center;
    }

    @media (min-width: $tablet-4) {
      padding-left: 70px;
      font-weight: var(--font-weight-medium);
      font-size: 14px;
      line-height: 125%;
    }
  }

  &__user-img {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 12px;
    object-fit: cover;

    @media (min-width: $full) {
      width: 64px;
      height: 64px;
      margin-right: 16px;
    }
  }
  &__user-name {
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 125%;
    color: var(--gen_font_color);
  }
  &__user-role {
    font-size: 12px;
    line-height: 130%;
    color: var(--grey-3);
  }
}
