@import 'src/breakpoints';

.link-item {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 3px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  text-decoration: none;

  &__label {
    position: absolute;
    top: 8px;
    left: 12px;
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 125%;
    color: var(--black1);

    @media screen and (min-width: $full) {
      font-size: 18px;
      top: 16px;
      left: 24px;
    }
  }
}
