.empty-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    margin-top: 16px;
    font-size: 14px;
    line-height: 160%;
    color: var(--black1);
  }
  &__button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 199px;
    height: 44px;
    border: 2px solid var(--blue-3);
    border-radius: 8px;
    color: var(--blue-3);
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 24px;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
  }

  &__image {
    margin-top: 12px;
  }
}
