@import "../../../variables.scss";

section.questionnaire-section {
  position: relative;
  width: 100%;
  background-color: var(--background);
  
  // for switch
  &:after {
    content: "";
    width: 100%;
    height: 80%;
    position: absolute;
    z-index: 0;
    left: 0px;
    top: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/vector/gradients/gradient-3.svg);
  }

  .decor-image-left {
    position: absolute;
    top: 345px;
    left: 0;
    @media screen and (max-width: 1299px) {
      display: none;
    }
  }

  .decor-image-right {
    position: absolute;
    top: 170px;
    right: 110px;
    @media screen and (max-width: 1299px) {
      display: none;
    }
  }

  .auth-footer {
    margin-top: 80px;
  }
}
