.coaches__container {
  width: 100%;

  .coaches__title {
    margin: 55px 0 0;
    @media screen and (min-width: 1280px) {
      margin: 65px 0 0;
    }
    p {
      font-weight: var(--font-weight-medium);
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.0015em;
      color: #101821;

      @media screen and (min-width: 768px) {
        font-size: 17px;
      }
    }

    .title__text {
      margin-top: 15px;
      font-size: 32px;
      line-height: 125%;
      color: #101821;
    }
  }

  .no-coach__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 32px;
    background: #ffffff;
    border-radius: 18px;
    padding: 28px 32px;
    overflow: hidden;
    // margin: 50px auto 0;
    // font-size: 30px;
    // line-height: 110%;
    // text-align: center;
    // letter-spacing: 0.01em;
    // color: #101821;
    // max-width: 820px;

    // @media screen and (min-width: 1280px) {
    //   font-size: 40px;
    // }

    &:before {
      content: '';
      position: absolute;
      width: 194.1px;
      height: 175.9px;
      left: -7%;

      background: linear-gradient(177.65deg, #ed915d 34.23%, #f0dfb4 95.49%);
      opacity: 0.2;
      filter: blur(30px);
      transform: matrix(-0.74, 0.67, 0.67, 0.74, 0, 0);
    }

    .image__wrapper {
      display: none;
      width: 54px;
      height: 54px;
      background: #ffffff;
      box-shadow: 0px 4px 14px rgba(14, 23, 41, 0.14);
      border-radius: 13px;
      z-index: 1;

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .text__content {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        margin-left: 32px;
      }

      .title {
        font-weight: var(--font-weight-medium);
        font-size: 17px;
        line-height: 140%;
        letter-spacing: 0.0015em;
        color: #000000;
      }

      .description {
        margin-top: 4px;
        font-weight: var(--font-weight-regular);
        font-size: 15px;
        line-height: 150%;
        font-feature-settings: 'liga' off;
        color: #000000;
      }
    }
  }

  .coaches__wrapper {
    margin-top: 30px;
  }

  .another-coach {
    margin-top: 50px;
    padding: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: #ffffff;
    // background-image: url(../../../../../assets/vector/questionnaire/another_coach_bg.svg);
    // background-size: cover;
    // background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      right: -20%;
      top: 70%;
      width: 688px;
      height: 669px;
      background: linear-gradient(
        180deg,
        #fc50b7 0%,
        rgba(255, 197, 111, 0.46) 100%
      );
      opacity: 0.3;
      filter: blur(200px);
      transform: matrix(-0.14, 0.99, 1, 0.12, 0, 0);
    }

    @media screen and (min-width: 1280px) {
      padding: 55px 65px;
      margin-top: 90px;
    }

    // for ILTB
    // &__image {
    //   width: 100%;
    //   @media screen and (min-width: 768px) {
    //     width: 35%;
    //   }
    //   img {
    //     width: 100%;
    //     height: auto;
    //   }
    // }

    &__description {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        width: 70%;
      }

      .description__title {
        font-weight: var(--font-weight-medium);
        font-size: 20px;
        line-height: 125%;
        color: var(--black);

        @media screen and (min-width: 768px) {
          font-size: 36px;
        }
      }
      p {
        margin-top: 16px;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;

        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }

      .another-coach__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px 0 0;
        width: 190px;
        height: 40px;
        background: var(--blue-3);
        border-radius: 8px;
        border: none;
        font-weight: var(--font-weight-medium);
        font-size: 14px;
        line-height: 125%;
        color: #ffffff;
        z-index: 2;

        @media screen and (min-width: 768px) {
          width: 197px;
          height: 47px;
          font-size: 16px;
          line-height: 24px;
          margin: 32px 0 0;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
