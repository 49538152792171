@import '../../variables.scss';

section.coach-preview {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(209, 207, 205, 0.4);
  border-radius: 12px;
  margin-bottom: 16px;

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 30px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      padding: 24px 40px;
      align-items: center;
    }

    .coach-image {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        height: 250px;
        border-radius: 12px;
        object-fit: cover;

        @media screen and (min-width: 768px) {
          width: 160px;
          height: 160px;
        }
      }
    }

    .info__wrapper {
      display: flex;
      justify-content: space-between;
      flex: 1 1 auto;

      .coach-info {
        text-align: center;
        width: 100%;
        @media screen and (min-width: 768px) {
          margin-left: 25px;
          text-align: left;
        }
        .coach-name {
          margin-top: 15px;
          font-weight: var(--font-weight-medium);
          font-size: 20px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: #101821;

          @media screen and (min-width: 768px) {
            margin-top: unset;
          }

          @media screen and (min-width: 1280px) {
            font-size: 24px;
          }
        }

        .coach-location {
          font-weight: normal;
          font-size: 16px;
          line-height: 30px;
          color: #929292;

          @media screen and (min-width: 1280px) {
            font-size: 20px;
          }
        }

        .coach-experience {
          display: flex;
          flex-wrap: wrap;
          padding-inline-start: 20px;

          li {
            font-size: 14px;
            line-height: 130%;
            align-items: center;
            color: #101821;
            margin: 0 37px 13px 0;

            @media screen and (min-width: 1280px) {
              font-size: 16px;
            }

            &::marker {
              color: var(--yellow-3);
            }
          }
        }
      }

      .coach-buttons {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1023px) {
          display: none;
        }

        @media screen and (min-width: 1280px) {
          margin-left: 150px;
        }

        &.mobile {
          display: none;
          justify-content: space-between;
          width: 100%;

          @media screen and (max-width: 1023px) {
            display: flex;
            flex-direction: column;
          }

          .buttons__wrapper {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            button.choose-btn {
              margin: 0;
            }
          }
        }

        .matching-percentage {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 130px;
          height: 40px;
          background: #f5f5f5;
          border-radius: 32px;
          font-size: 18px;
          line-height: 130%;
          color: #929292;

          @media screen and (min-width: 768px) {
            width: 150px;
            font-size: 15px;
          }

          @media screen and (min-width: 1280px) {
            width: 151px;
            height: 48px;
            margin-right: unset;
          }
        }

        button {
          width: 130px;
          height: 44px;
          box-sizing: border-box;
          border-radius: 12px;
          font-weight: var(--font-weight-medium);
          font-size: 14px;
          line-height: 125%;
          text-align: center;
          border: none;
          border: 2px solid var(--blue-3);
          transition: all 0.2s linear;

          @media screen and (min-width: 768px) {
            width: 150px;
            margin-right: 20px;
            font-size: 15px;
          }

          @media screen and (min-width: 1280px) {
            width: 158px;
            margin-right: unset;
          }

          &.choose-btn {
            background-color: var(--blue-3);
            color: #fff;
            margin: 0 0 8px;

            &:hover {
              cursor: pointer;
              background-color: #fff;
              color: var(--blue-3);
            }
          }
        }

        .profile-btn {
          background-color: #fff;
          color: var(--blue-3);
          width: 130px;
          height: 44px;
          box-sizing: border-box;
          border-radius: 12px;
          font-weight: var(--font-weight-medium);
          font-size: 14px;
          line-height: 125%;
          border: none;
          border: 2px solid var(--blue-3);
          transition: all 0.2s linear;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (min-width: 768px) {
            width: 150px;
            margin-right: 20px;
            font-size: 15px;
          }

          @media screen and (min-width: 1280px) {
            width: 158px;
            margin-right: unset;
          }

          &:hover {
            cursor: pointer;
            color: #fff;
            background-color: var(--blue-3);
          }
        }
      }
    }
  }
}
