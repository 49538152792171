.custom-checkbox__item {
  cursor: pointer;
  display: flex;
  align-items: center;

  &--checked {
    .custom-checkbox__item-box::before {
      opacity: 1;
      transform: scale(1);
      background-color: #2e79da;
    }
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid black;
    border-radius: 2px;

    &.disabled {
      opacity: 0.5;
    }

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      opacity: 0;
      transform: scale(0.8);
      transition: transform 0.2s, background-color 0.2s linear, opacity 0.2s;
    }
  }

  &-input {
    display: none;
  }
  &-label {
    display: block;
    margin-left: 12px;
    color: #101821;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: black;

    &.disabled {
      opacity: 0.5;
    }
  }
}
