.radio-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.outer-circle {
  width: 40px;
  height: 40px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;
  background-color: #0d5bc6;

  &.unselected {
    background-color: rgba(13, 91, 198, 0.2);
  }
}

.inner-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.1s linear;
  border: 2px solid #2a6bbf;

  img {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
}

.radio-helper-text {
  margin-left: 16px;
  font-size: 17px;
  line-height: 140%;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.026px;
}

@media screen and (max-width: 767px) {
  .radio-outer-circle {
    width: 50px;
    height: 50px;
  }

  .radio-inner-circle {
    width: 34px;
    height: 34px;
  }
}
