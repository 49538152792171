@import 'src/breakpoints';

.users-table {
  padding: 0 12px 18px 0;
  margin-top: 12px;

  @media (min-width: $tablet-1) {
    margin-top: 14px;
  }

  &--limit-scroll {
    max-height: 295px;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 160%;
    text-align: center;

    .icon {
      flex-shrink: 0;
      margin-bottom: 16px;
    }
  }

  &.scrollbar-container {
    height: auto;

    > .ps__rail-y {
      opacity: 1;
      width: 6px;
      border-radius: 8px;
      background-color: var(--grey-gun);

      &:hover,
      &:focus,
      &.ps--clicking {
        background-color: var(--grey-gun);
      }

      .ps__thumb-y {
        width: 100%;
        right: 0;
        border-radius: 8px;
        background-color: var(--grey-5);
      }
    }

    > .ps__rail-x {
      opacity: 1;
      height: 6px;
      border-radius: 8px;
      background-color: var(--grey-gun);

      &:hover,
      &:focus,
      &.ps--clicking {
        background-color: var(--grey-gun);
      }

      .ps__thumb-x {
        height: 100%;
        bottom: 0;
        border-radius: 8px;
        background-color: var(--grey-5);
      }
    }
  }

  &__row {
    display: flex;
    margin-bottom: 5px;

    @media (min-width: $desktop-2) {
      margin-bottom: 10px;
    }

    &--head {
      align-items: flex-start;

      @media (min-width: $desktop-2) {
        margin-bottom: 21px;
      }

      .users-table__col {
        padding: 0 8px;
        font-weight: var(--font-weight-regular);
        font-size: 13px;
        line-height: 160%;
        color: var(--grey-3);
      }
    }
  }

  &__col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 7px 8px;
    font-size: 13px;
    line-height: 150%;
    width: 100px;
    text-align: right;
    white-space: pre-wrap;

    @media (min-width: $desktop-2) {
      font-size: 15px;
    }

    &:first-child {
      width: 185px;
      padding: 0 8px 0 0;
      text-align: left;
      justify-content: flex-start;

      @media (min-width: $mobile) {
        width: 200px;
      }

      @media (min-width: 1700px) {
        width: 250px;
      }
    }

    &:last-child {
      padding: 0 0 0 8px;
    }

    &--name {
      font-weight: var(--font-weight-medium);
      font-size: 13px;

      @media (min-width: $desktop-2) {
        font-size: 15px;
      }
    }

    .accent {
      font-weight: var(--font-weight-medium);
      color: var(--black);

      @media (min-width: $desktop-2) {
        font-size: 17px;
      }
    }
  }

  &__avatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50px;
    object-fit: cover;
    flex-shrink: 0;

    @media (min-width: $desktop-2) {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }
}
