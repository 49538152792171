@import 'src/breakpoints';

.my-profile {
  margin: 0;
  position: relative;

  .short-info {
    background: var(--white);
    //box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
    border-radius: 12px;
    padding: 40px 120px;
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    flex-wrap: wrap;

    .my-avatar {
      margin: 0 24px 0 0;
      width: 110px;
      display: flex;

      img {
        border-radius: 12px;
        width: 110px;
        height: 110px;
        object-fit: cover;
      }
    }

    .my-short-info {
      padding: 0 20px 0 0;
      width: calc(100% - 250px);

      .name {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 125%;
        color: var(--gen_font_color);
        margin: 0 0 8px;
      }

      .country {
        font-weight: var(--font-weight-regular);
        font-size: 18px;
        line-height: 130%;
        margin: 0 0 8px;
        color: var(--grey-3);
      }

      .program {
        font-weight: var(--font-weight-regular);
        font-size: 18px;
        line-height: 130%;
      }
    }

    .my-full-info {
      .view-profile {
        text-decoration: none;
        font-weight: 700;
        font-size: 15px;
        line-height: 125%;
        transition: all 0.2s ease-out;
        // for ILTB
        // border: 2px solid var(--orange-2);
        // background: var(--orange-2);
        border: 2px solid var(--gen_button_color);
        background: var(--gen_button_color);
        border-radius: 8px;
        color: var(--white);
        display: flex;
        align-items: center;
        padding: 12px 24px;
        justify-content: center;

        &:hover {
          background: var(--white);
          // for ILTB
          // color: var(--orange-2);
          color: var(--gen_button_color);
        }
      }
    }

    .download-cv {
      margin: 28px 16px 0 0;
      background: var(--blue-3);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 174px;
      height: 44px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon-attached {
        margin: 0 20px;
      }

      .text-download {
        font-weight: var(--font-weight-medium);
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0015em;
        // color: var(--william);
        color: #fff;
      }
      svg {
        path {
          fill: var(--blue-3);
        }
      }

      .icon-download {
        margin: 0 40px 0 60px;
      }
    }

    .download-report-button__wrapper {
      margin: 28px 16px 0 0;
    }

    .edit-profile {
      margin: 28px 16px 0 0;
      padding: 14px 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      // for ILTB
      // background: #366b65;
      background: transparent;
      border-radius: 8px;
      color: var(--blue-3);
      border: 2px solid var(--blue-3);
      font-weight: var(--font-weight-medium);
      font-size: 14px;
      line-height: 125%;
      font-weight: var(--font-weight-medium);
      text-decoration: none;
    }
  }

  .full-info {
    background: var(--white);
    //box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
    border-radius: 12px;
    padding: 32px 142px;
    display: flex;
    flex-direction: column;

    .section-info-block {
      margin: 0 0 64px;

      .summary-description {
        font-weight: var(--font-weight-regular);
        font-size: 14px;
        line-height: 130%;
        color: var(--gen_font_color);

        @media screen and (min-width: $full) {
          font-size: 17px;
        }

        .show-more {
          font-weight: var(--font-weight-medium);
          font-size: 14px;
          line-height: 140%;
          display: flex;
          align-items: center;
          letter-spacing: 0.0015em;
          color: var(--blue-3);
          //text-decoration: none;
          margin: 8px 0 0;

          @media screen and (min-width: $full) {
            font-size: 17px;
            margin: 20px 0 0;
          }
        }
      }

      .block-title {
        font-weight: var(--font-weight-medium);
        font-size: 20px;
        line-height: 125%;
        color: var(--black);
        margin: 0 0 20px;

        @media screen and (min-width: $full) {
          font-size: 24px;
        }
      }

      .block-items {
        display: flex;
        flex-wrap: wrap;

        .single-item {
          padding: 8px 20px;
          background: rgba(253, 201, 76, 0.3);
          border-radius: 24px;
          margin: 0 12px 12px 0;
          font-weight: var(--font-weight-medium);
          font-size: 14px;
          line-height: 150%;
          color: #6a541f;

          @media screen and (min-width: $full) {
            font-size: 15px;
            padding: 12px 24px;
          }
        }
      }

      .work-experience-block {
        .experience-item {
          margin: 0 0 20px;
          display: flex;
          flex-wrap: wrap;

          .experience-item-left {
            width: 60%;
          }

          .experience-item-right {
            width: 40%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          .position {
            font-weight: var(--font-weight-medium);
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            color: var(--gen_font_color);
            margin: 0 0 8px;
          }

          .company {
            font-weight: var(--font-weight-medium);
            font-size: 14px;
            line-height: 150%;
            color: #6a541f;
            padding: 8px 20px;
            background: rgba(253, 201, 76, 0.3);
            border-radius: 24px;
            display: inline-flex;
            margin: 0 15px 0 0;

            @media screen and (min-width: $full) {
              font-size: 15px;
              padding: 12px 24px;
            }
          }

          .date {
            font-weight: var(--font-weight-regular);
            font-size: 16px;
            line-height: 130%;
            color: #929292;
            margin: 0 0 10px;
          }

          .job-responsibilities {
            font-weight: var(--font-weight-regular);
            font-size: 16px;
            line-height: 130%;
            color: var(--gen_font_color);
          }
        }
      }

      .coaching-experience {
        .coaching-experience-item {
          display: flex;
          align-items: center;
          margin: 8px 0 18px;

          .icon-bullet {
            margin: 0 20px 0 0;
          }

          .coaching-information-label {
            font-weight: var(--font-weight-regular);
            font-size: 16px;
            line-height: 130%;
            color: var(--gen_font_color);
            margin: 0 20px 0 0;
          }

          .coaching-information-value {
            font-weight: var(--font-weight-medium);
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            color: var(--gen_font_color);
          }
        }
      }

      .languages-block {
        .language {
          display: flex;
          align-items: center;
          margin: 0 0 20px;

          .flag {
            width: 25px;
            height: 16px;
            margin: 0 20px 0 0;
            object-fit: cover;
            border-radius: 4px;
          }

          .language {
            font-weight: var(--font-weight-medium);
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            margin: 0 20px 0 0;
            color: var(--gen_font_color);
          }

          .experience {
            font-weight: var(--font-weight-regular);
            font-size: 16px;
            line-height: 130%;
            color: var(--gen_font_color);
          }
        }
      }

      .publications-block {
        .publications-item {
          margin: 0 0 30px;

          .item-author {
            font-weight: normal;
            font-size: 16px;
            line-height: 130%;
            margin: 0 0 12px;
            color: #929292;
          }

          .item-name {
            font-weight: var(--font-weight-medium);
            font-size: 20px;
            line-height: 125%;
            margin: 0 0 12px;
            color: var(--gen_font_color);

            @media screen and (min-width: $full) {
              font-size: 24px;
            }
          }

          .item-publishing-house {
            font-weight: var(--font-weight-medium);
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            margin: 0 0 12px;
            color: var(--gen_font_color);
          }

          .item-overview {
            font-weight: var(--font-weight-regular);
            font-size: 16px;
            line-height: 130%;
            color: var(--gen_font_color);
            white-space: pre-wrap;
          }
        }
      }

      .goal-block {
        .text {
          font-weight: var(--font-weight-regular);
          font-size: 16px;
          line-height: 130%;
          color: var(--gen_font_color);
          white-space: pre-wrap;
        }
      }
    }
  }

  @media (min-width: $tablet-1) and (max-width: $tablet-4) {
    .short-info {
      padding: 48px 20px 30px;
    }

    .full-info {
      padding: 32px 20px;
    }
  }

  @media (max-width: $tablet) {
    .short-info {
      padding: 48px 16px 30px;
      margin: 0 0 16px;
      // align-items: flex-start;

      .my-short-info {
        .name {
          font-size: 24px;
        }
      }
      .my-avatar {
        margin: 0 16px 0 0;
        width: 64px;

        img {
          width: 64px;
          height: 64px;
        }
      }

      .edit-profile-icon {
        margin-top: 16px;
      }

      .download-cv {
        width: 235px;
        display: flex;
        justify-content: center;
      }

      .my-full-info {
        position: absolute;
        right: 0;
        top: 0;

        .edit-profile-icon {
          right: 25px;
          top: 25px;
          position: relative;
        }
      }

      .my-short-info {
        width: calc(100% - 84px);
      }
    }

    .full-info {
      padding: 32px 16px;

      .section-info-block {
        margin: 0 0 20px;

        .work-experience-block {
          .experience-item {
            flex-direction: column;
            margin: 0 0 50px;

            .experience-item-left {
              width: 100%;
              margin-bottom: 15px;
            }

            .experience-item-right {
              width: 100%;
              align-items: flex-start;
            }
          }
        }

        .block-items {
          .single-item {
            padding: 8px 16px;
          }
        }

        .coaching-experience {
          .coaching-experience-item {
            align-items: flex-start;
            flex-direction: column;

            .icon-bullet {
              display: none;
            }
          }
        }
      }
    }
  }
}
