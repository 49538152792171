@import 'src/breakpoints';

.container-chat {
  &.open-chat {
    .chat-overlay {
      position: absolute;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: flex-end;

      .chat-wrapper {
        opacity: 1;
        visibility: visible;
        overflow: initial;
        position: relative;
        top: unset;
      }
    }
  }

  .chat-overlay {
    background: linear-gradient(
      270.35deg,
      rgba(40, 34, 31, 0.3) 0.36%,
      rgba(40, 34, 31, 0) 94.18%
    );
    top: 64px;
    right: 0;
    width: 0;
    height: 0;
    transition: all 0.35s ease-out;
    position: relative;

    @media screen and (min-width: $full) {
      top: 92px;
    }

    .chat-wrapper {
      opacity: 0;
      visibility: hidden;
      transition: all 0.35s ease-out;
      background: var(--white);
      position: fixed;
      right: 0;
      top: 92px;
      width: 100%;
      max-width: 480px;
      z-index: 1;
      height: 100vh;
      overflow: hidden;

      .chat-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 24px 24px;
        height: 96px;

        .text-chat {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          color: var(--gen_font_color);
        }

        .icon-block {
          display: flex;

          .icon-add {
            margin: 0 20px 0 0;
          }

          .icon-search {
          }
        }

        .chat-search-user {
          border: none;
          width: 100%;
          //margin-left: 24px;
          margin-right: 24px;
        }
      }

      .user-chat-header {
        min-height: 96px;
        background: var(--white);
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        padding: 0 24px;
        margin: 0 0 16px;

        .back-to-chat-list {
          margin: 0 20px 0 0;
          cursor: pointer;
        }

        .info-block {
          display: flex;
          align-items: center;

          .avatar {
            width: 56px;
            height: 56px;
            border-radius: 50px;
            object-fit: cover;
            margin: 0 12px 0 0;
          }

          .user-name {
            font-weight: var(--font-weight-medium);
            font-size: 24px;
            line-height: 125%;
            color: var(--black);
          }
        }
      }

      .chat-list {
        height: calc(100% - 92px);

        .chat-list-items {
          padding: 0 24px 24px;

          .item {
            .user {
              width: 100%;
              padding: 12px 16px 12px 12px;
              background: var(--background);
              border-radius: 8px;
              margin: 0 0 6px;
              display: flex;
              align-items: center;
              transition: all 0.35s ease-out;
              cursor: pointer;

              &.chat-not-readed {
                background: rgba(46, 121, 218, 0.2);

                .info {
                  .top {
                    .user-name,
                    .time {
                      color: var(--blue-3);
                    }
                  }

                  .bottom {
                    .last-message {
                      color: var(--blue-3);
                    }
                  }
                }
              }

              .avatar {
                display: flex;

                margin: 0 12px 0 0;

                img {
                  border-radius: 50%;
                  object-fit: cover;
                  width: 56px;
                  height: 56px;
                }
              }

              .info {
                display: flex;
                flex-direction: column;
                width: 100%;
                overflow: hidden;

                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .user-name {
                    font-weight: var(--font-weight-medium);
                    font-size: 17px;
                    line-height: 140%;
                    color: var(--black);
                  }

                  .time {
                    font-size: 13px;
                    line-height: 160%;
                    color: var(--grey-3);
                  }
                }

                .bottom {
                  .last-message {
                    overflow: hidden;
                    width: 100%;
                    font-size: 15px;
                    line-height: 150%;
                    color: var(--black);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1;
                    -webkit-box-orient: vertical;

                    ul,
                    ol {
                      padding-inline-start: 15px;
                      margin: 0;
                    }
                  }
                }
              }

              &:hover {
                background: var(--grey-4);
              }
            }
          }
        }
      }

      .empty-page-chat {
        height: calc(100% - 96px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        // for Switch
        &:after {
          content: '';
          width: 100%;
          height: 75%;
          position: absolute;
          z-index: 0;
          top: 0px;
          left: 0;
          z-index: -1;
          opacity: 0.4;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../../assets/vector/gradients/gradient-6.svg);
        }

        .empty-page-title {
          margin: 24px 0 0 0;
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          color: var(--black);
        }
      }

      .chat-with-user {
        .chat-dashboard-list {
          padding: 0 24px 16px;
          height: calc(100vh - 320px);

          .chat-message-item {
            margin: 0 0 8px 0;

            &.my-self-message {
              justify-content: flex-end;
              display: flex;
            }

            .opponent {
              display: inline-flex;
              padding: 16px 24px;

              .message {
                word-break: break-word;
              }
            }

            .my-self {
              display: inline-flex;
              padding: 16px 24px;

              .message {
                word-break: break-word;
              }
            }

            .message {
              &.my-self-message {
                display: inline-block;
              }

              &.opponent-message {
                display: inline-block;
              }

              .message-info {
                margin: 0 0 2px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .name {
                  font-weight: var(--font-weight-medium);
                  font-size: 15px;
                  line-height: 150%;
                  margin: 0 15px 0 0;
                }

                .time {
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 160%;
                }
              }

              .text-message {
                font-weight: normal;
                font-size: 15px;
                line-height: 150%;

                ul,
                ol {
                  margin: 0;
                  padding-inline-start: 15px;
                }
              }
            }
          }

          .chat-date {
            display: flex;
            justify-content: center;
            margin: 32px 0 8px;

            &:first-child {
              margin: 0 0 8px;
            }

            .value {
              font-weight: normal;
              font-size: 13px;
              line-height: 160%;
              border-radius: 16px;
              border: 1px solid var(--grey-8);
              color: var(--grey-3);
              padding: 4px 16px;
            }
          }

          .opponent {
            background: var(--chat-opponent);
            border-radius: 16px 16px 16px 0;

            .message {
              .message-info {
                .name {
                  color: var(--green-3);
                }

                .time {
                  color: var(--green-3);
                }
              }

              .text-message {
                color: var(--green-6);
              }
            }
          }

          .my-self {
            background: rgba(0, 140, 206, 0.2);
            border-radius: 16px 16px 0 16px;

            .message {
              .message-info {
                .name {
                  color: var(--blue-1);
                }

                .time {
                  color: var(--blue-1);
                }
              }

              .text-message {
                color: var(--green-7);
              }
            }
          }
        }

        .type-message {
          background: var(--light-gray);
          display: flex;
          align-items: center;
          padding: 20px 24px;
          justify-content: space-between;
          position: fixed;
          bottom: 0;
          width: 480px;

          .quill {
            margin-bottom: 0;
            width: 100%;
            max-width: calc(100% - 24px);
            height: auto;
            background-color: transparent;

            .ql-toolbar {
              border: 1px solid #cdcbcb;

              .ql-formats {
                .ql-header {
                  .ql-picker-label {
                    display: flex;
                  }
                }
              }
            }

            .ql-tooltip {
              left: 0 !important;
              top: -5px !important;
            }

            .ql-container {
              min-height: unset;
              max-height: 200px;
              overflow: auto;
              // border: unset;

              .ql-editor {
                font-size: 15px;
                line-height: 150%;
              }
            }
          }

          .chat-message-input {
            width: 100%;
            border: none;
            background: transparent;
            font-size: 15px;
            line-height: 150%;
            resize: none;
            overflow: auto;
            height: auto;
            margin-right: 5px;

            &::placeholder {
              font-size: 15px;
              line-height: 150%;
              color: var(--grey-3);
            }

            &:focus {
              outline: none;
            }

            &::-webkit-scrollbar {
              width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px var(--grey-4);
              border-radius: 8px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: var(--grey-4);
              border-radius: 8px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: var(--grey-3);
            }
          }
        }
      }

      .add-participants-to-chat {
        background: rgba(40, 34, 31, 0.4);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon {
        cursor: pointer;
      }
    }
  }
}

//$desktop-1: 1366px;
//$tablet-4: 1194px;
//$tablet-2: 920px;
//$tablet-1: 768px;

@media (min-width: $tablet-2) and (max-width: $desktop-1 - 1px) {
  .container-chat {
    .chat-overlay {
      top: 64px;

      .chat-wrapper {
        top: 65px;
        height: calc(100vh - 64px);
      }
    }

    &.open-chat {
      .chat-overlay {
        height: calc(100vh - 64px);
      }
    }
  }
}

@media (min-width: $tablet-1) and (max-width: $tablet-2 - 1px) {
  .container-chat {
    .chat-overlay {
      top: 47px;

      .chat-wrapper {
        top: 48px;
        height: calc(100vh - 47px);
      }
    }

    &.open-chat {
      .chat-overlay {
        height: calc(100vh - 47px);
      }
    }
  }
}

@media (max-width: $tablet) {
  .container-chat {
    .chat-overlay {
      top: 47px;

      .chat-wrapper {
        top: 48px;
        width: calc(100% - 48px);
        height: calc(100vh - 48px);

        .chat-header {
          padding: 0 16px;
          height: 64px;
        }

        .empty-page-chat {
          padding: 0 32px;
          text-align: center;
        }

        .chat-list {
          .chat-list-items {
            padding: 0 16px 45px;

            .item {
              .user {
                .avatar {
                  width: 40px;
                  height: 40px;
                  margin: 0 8px 0 0;

                  img {
                    width: 40px;
                    height: 40px;
                  }
                }

                .info {
                  .top {
                    .user-name {
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        .user-chat-header {
          min-height: 64px;
          padding: 0 20px;

          .back-to-chat-list {
            margin: 0 12px 0 0;
          }

          .info-block {
            .avatar {
              width: 40px;
              height: 40px;
            }

            .user-name {
              font-size: 16px;
            }
          }
        }

        .chat-with-user {
          .chat-dashboard-list {
            padding: 0 16px 85px;
            height: calc(100vh - 230px);
          }

          .type-message {
            padding: 10px 48px 10px 24px;
            width: 100%;
          }
        }

        .icon {
          display: flex;
        }
      }
    }
  }
}
