@import 'src/breakpoints';

.auth-register {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(
    100vh - var(--auth-header-min-height) - var(--auth-footer-min-height)
  );
  overflow: hidden;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  .decor {
    position: absolute;
    z-index: 1;
  }

  &__company {
    position: relative;
    width: 100%;
    padding: 32px 4.5% 76px;
    // for ILTB
    // background-color: var(--rafia);
    background-color: #fff;
    border-bottom: 1px solid #d8d8d8;

    @media (min-width: 1024px) {
      width: 42%;
      //   max-width: 800px;
      padding: 150px 6.25% 150px;
    }

    * {
      position: relative;
      z-index: 5;
    }
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      left: 0px;
      bottom: 0px;
      background-position: left bottom;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/vector/gradients/gradient-14.svg);
    }

    .decor--1 {
      left: 0;
      top: 76px;
    }

    .decor--2 {
      right: 16px;
      bottom: 12px;
      @media (min-width: 1024px) {
        right: 75px;
        bottom: 90px;
      }
    }
  }

  &__company-logo {
    max-width: 100%;
    height: auto;
    max-height: 80px;
    @media (min-width: 768px) {
      max-height: 250px;
    }
  }

  &__company-title {
    max-width: 100%;
    margin-top: 8px;
    font-weight: var(--font-weight-medium);
    font-size: 40px;
    line-height: 44px;
    color: var(--black1);
    // for ILTB
    // color: var(--white);

    @media (min-width: 768px) {
      margin-top: 20px;
      font-size: 50px;
      line-height: 110%;
    }
  }

  &__company-name {
    margin-top: 12px;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    color: var(--black1);
    // for ILTB
    // color: var(--white);

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  &__company-text {
    margin-top: 32px;
    font-size: 18px;
    line-height: 160%;
    font-weight: var(--font-weight-regular);
    color: var(--black1);

    @media (min-width: 768px) {
      margin-top: 40px;
      font-size: 20px;
    }

    p {
      margin-top: 12px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 70px 4.4% 80px;
    flex-grow: 1;
    // for ILTB
    // background-color: var(--grey);
    background-color: #f8f9fa;
    border-bottom: 1px solid #d8d8d8;

    @media (min-width: 1024px) {
      width: 60%;
      padding: 80px 4.4% 63px;
      align-items: flex-start;
    }

    @media (min-width: 1200px) {
      padding: 80px 9% 63px;
    }

    * {
      position: relative;
      z-index: 5;
    }

    .decor--3 {
      width: 73px;
      height: auto;
      right: 16px;
      top: 24px;

      @media (min-width: 1024px) {
        width: 118px;
        right: 110px;
        top: 80px;
      }
    }
  }

  &__form-title {
    margin-bottom: 32px;
    font-weight: var(--font-weight-medium);
    font-size: 28px;
    line-height: 35px;
    color: var(--black);

    @media (min-width: $tablet-1) {
      margin-bottom: 40px;
      font-size: 32px;
      line-height: 125%;
    }

    .default-auth__notification {
      top: -70px;
    }
  }
}
