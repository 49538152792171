.category-editor {
  width: 100%;
  padding: 24px 24px 21px;
  border-radius: 12px;
  background-color: var(--bird-grey);

  &__label {
    margin: 20px 0 12px;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: var(--black);

    &:first-of-type {
      margin: 0 0 12px;
    }
  }

  &__multi-row {
    margin-top: 20px;
  }

  &__textarea {
    width: 100%;
    min-height: 100px;
    margin: 0;
    border: 1px solid var(--grey-5);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 25px 23px;
    outline: none;
    font-family: Poppins, sans-serif;
    color: var(--black);
    font-size: 18px;
    line-height: 180%;
    resize: none;
    transition: border-color 0.25s;

    &:focus {
      border-color: var(--blue-3);
    }
  }

  &__error {
    margin: 7px 0 0 24px;
    font-size: 15px;
    line-height: 150%;
    color: var(--blood);
  }

  &__btn-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;

    .button {
      height: 44px;
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;

      &:last-of-type {
        margin: 8px 0 0 16px;
      }
    }

    .tabs__category-edit {
      margin-right: auto;
    }
  }

  .date-picker-kit {
    margin-top: 20px;
  }
}
