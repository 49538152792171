.sorting-buttons__wrapper {
    display: inline-flex;
    flex-direction: column;
    margin-right: 10px;
    vertical-align: middle;

    .icon {
        cursor: pointer;
    }

    .icon__down {
        transform: rotate(180deg);
    }

}