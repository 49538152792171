@import 'src/breakpoints';

.school-overview {
  width: 29%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100vh - var(--header-height));

  .section__title {
    min-height: 32px;
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 125%;
    color: #212121;
    display: flex;
    align-items: center;
  }

  .spinner__wrapper {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
    flex-direction: column;
    span {
      font-weight: var(--font-weight-regular);
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      opacity: 0;
    }
  }

  .credit-graph--total {
    margin-top: 24px;
    background: #ffffff;
    box-shadow: 0px 1.34387px 6.71937px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .credit-graph {
      margin-top: 14px;

      .credit-graph__head {
        .credit-graph__title {
          display: none;
        }

        .credit-graph__info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .pie-charts__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .chart-item {
      border: none;
      margin-top: 14px;
      box-shadow: 0px 1.34387px 6.71937px rgba(0, 0, 0, 0.08);

      .chart-info-block {
        .columns {
          flex-direction: column;
        }
      }
    }
  }

  .show-charts__button {
    margin-top: 14px;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(42, 107, 191, 0.1);
    border-radius: 8px;
    cursor: pointer;

    span {
      font-weight: var(--font-weight-medium);
      font-size: 14px;
      line-height: 125%;
      color: #2e79da;
      margin-right: 12px;
    }

    .dropdown-arrow {
      transition: all 0.2s ease-out;

      &.rotate-arrow {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 1023px) {
    order: 1;
    width: 100%;
    min-height: 230px;
    padding-bottom: 32px;
    border-bottom: 1px solid #d8d8d8;

    .section__title {
      display: none;
    }

    .spinner__wrapper {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .credit-graph--total {
      margin-top: 8px;
    }

    .pie-charts__section {
      margin-top: 14px;
      flex-direction: row;
      justify-content: space-between;

      .chart-item {
        margin-top: 0px;
        max-width: 49%;
      }
    }
  }

  @media screen and (min-width: $desktop-2) {
    .pie-charts__section {
      margin-top: 14px;
      flex-direction: row;
      justify-content: space-between;

      .chart-item {
        margin-top: 0px;
        max-width: 48%;
      }
    }
  }

  @media screen and (min-width: $full) {
    .section__title {
      margin-top: 12px;
      font-size: 24px;
    }
  }
}
