.download-report {
  width: 100%;
  max-width: 90%;
  margin: 25px auto 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 50px;
  border-radius: 24px;
  //  for ILTB
  // background: linear-gradient(
  //     0deg,
  //     rgba(255, 255, 255, 0.77),
  //     rgba(255, 255, 255, 0.77)
  //   ),
  //   #fdc94c;
  background: #fff;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1110px;
    padding: 15px 65px;
  }

  .download__section {
    max-width: 470px;
    display: flex;
    flex-direction: column;
    align-self: center;

    .title {
      font-weight: var(--font-weight-medium);
      font-size: 30px;
      line-height: 110%;
      letter-spacing: 0.01em;
      font-feature-settings: 'liga' off;
      color: #101821;

      @media screen and (min-width: 1280px) {
        font-size: 36px;
      }
    }

    .description {
      font-weight: var(--font-weight-regular);
      margin-top: 12px;
      font-size: 18px;
      line-height: 130%;
      color: #101821;
    }

    .download-report-button__wrapper {
      margin-top: 32px;
    }
  }

  .image__section {
    margin-top: 50px;

    img {
      width: 100%;
      height: auto;
    }

    @media screen and (min-width: 768px) {
      margin-top: unset;
      width: 322px;
      height: 292px;
    }
  }
}
