.program-dropdown {
  width: 100%;
  max-width: 370px;
  height: 44px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f7f7f7;
  border-radius: 12px;
  position: relative;

  @media screen and (max-width: 1023px) {
    position: relative;
  }

  @media screen and (min-width: 1920px) {
    height: 48px;
  }

  .user-program {
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 150%;
    color: var(--black);
    margin: 0 0 4px 38px;
  }

  .select-user-program {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    color: var(--black);
    font-style: normal;
    cursor: pointer;

    .program-name {
      width: calc(100% - 32px);
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      font-weight: var(--font-weight-medium);
      font-size: 20px;
      line-height: 125%;
      color: #212121;

      svg {
        margin-right: 10px;
      }

      span {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      @media screen and (min-width: 920px) {
        font-size: 14px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 18px;
      }

      @media screen and (min-width: 1920px) {
        font-size: 20px;
      }
    }

    .dropdown__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      .dropdown-arrow {
        transition: all 0.1s linear;

        &.rotate-arrow {
          transform: rotate(180deg);
        }
      }

      .program-list {
        opacity: 0;
        visibility: hidden;
        background: #fff;
        border: 1px solid #f7f7f7;
        box-shadow: 0px 12px 24px rgba(209, 207, 205, 0.4);
        border-radius: 8px;
        position: absolute;
        top: 58px;
        left: 0;
        width: 100%;
        max-width: 300px;
        height: 212px;
        padding: 12px;

        .program-list-wrap {
          .item {
            display: flex;
            align-items: center;
            font-weight: var(--font-weight-medium);
            font-size: 15px;
            line-height: 150%;
            cursor: pointer;
            padding: 4px;
            color: #101821;
            background-color: #ffffff;

            &.active {
              background: #f7f7f7;
              border-radius: 8px;
            }

            svg {
              margin-right: 10px;
            }
          }

          .last-element {
            width: 100%;
            height: 2px;
          }
        }

        &.open {
          opacity: 1;
          visibility: visible;
          z-index: 100;
        }
      }
    }
  }
}
