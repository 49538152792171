.reports-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: 20;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 16px 16px;
    margin-bottom: 16px;
  }

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 125%;
    color: var(--black);
  }

  &__close {
    margin-left: 16px;
    cursor: pointer;
  }

  .reports-filter {
    padding: 0 16px;

    &__filter {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .reports-tabs {
    margin-bottom: 24px;
    &::after {
      content: '';
    }

    &__btn-group {
      position: static;
      flex-direction: row;
      width: auto;
      padding: 0 16px;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    &__btn {
      padding: 0 0 12px;
      margin-right: 36px;
      border-bottom: 5px solid transparent;
      background-color: transparent;
      font-weight: var(--font-weight-medium);
      font-size: 17px;
      line-height: 140%;
      border-radius: 0;
      color: var(--gen_font_color);
      transition: color 0.2s, border-bottom-color 0.2s;
      z-index: 5;

      &:hover {
        background-color: transparent;
      }

      &--active {
        border-bottom: 5px solid var(--black);
        color: var(--black);
      }
    }
  }
}
