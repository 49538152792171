@import 'src/breakpoints';

.default-auth {
  position: relative;
  width: 100%;
  padding: 40px 4.4% 60px;
  min-height: calc(
    100vh - var(--auth-header-min-height) - var(--auth-footer-min-height)
  );
  background-color: var(--background-default);
  display: flex;
  justify-content: center;
  align-items: center;
  // for Switch
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../../../assets/vector/gradients/gradient.svg);
  }

  @media (min-width: 768px) {
    padding: 40px 40px 60px;
  }

  * {
    position: relative;
    z-index: 5;
  }

  .sign-up {
    margin: 0 auto;
  }

  &__content {
    max-width: 967px;
    width: 100%;
    background: #ffffff;
    border-radius: 24px;
    padding: 0 26px 64px;
    box-shadow: 0px 6px 12px rgba(210, 217, 225, 0.4);
  }

  &__error {
    // position: absolute;
    margin: -28px 0 6px 16px;
    font-size: 14px;
    line-height: 22px;
    color: var(--stiletto);
  }

  &__notification {
    position: absolute;
    top: -85px;
    width: 100%;
    max-height: 100px;
    padding: 16px 24px;
    border: 1px solid var(--stiletto);
    border-radius: 12px;
    background: var(--soft-peach);
    font-size: 15px;
    text-align: left;
    font-weight: var(--font-weight-regular);
    line-height: 22px;
    color: var(--stiletto);
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
    z-index: 10;

    &--enter {
      opacity: 0;
      transform: scale(0.9);
    }

    &--enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 0.2s, transform 0.3s;
    }
    &--exit {
      opacity: 1;
    }
    &--exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 200ms, transform 0.3s;
    }
  }

  &__title {
    max-width: 539px;
    margin: 0 auto;
    margin: 56px auto 32px;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--black);
    font-weight: var(--font-weight-medium);

    @media (min-width: $tablet-1) {
      font-size: 50px;
      line-height: 55px;
    }

    &--reset {
      margin-bottom: 20px;
    }

    &--email {
      margin: 40px auto 20px;
    }
  }

  &__check-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__info-text {
    margin: 0 auto 40px;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    color: var(--black);
  }

  &__return-link {
    display: inline-flex;
    align-items: center;
    font-weight: var(--font-weight-medium);
    font-size: 21px;
    line-height: 30px;
    // for ILTB
    // color: var(--william);
    color: var(--blue-3);
    text-decoration: none;
    padding: 20px;
    svg {
      path {
        fill: var(--blue-3);
      }
    }

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  &__return-icon {
    width: 30px;
    height: auto;
    margin-right: 12px;

    @media (min-width: 768px) {
      width: 37px;
      margin-right: 18px;
    }
  }

  .decor {
    position: absolute;
    z-index: 1;

    &--1 {
      display: none;

      @media (min-width: 768px) {
        display: block;
        left: 0;
        top: 253px;
      }
    }

    &--2 {
      display: none;
      @media (min-width: 1200px) {
        display: block;
        bottom: 146px;
        right: 18%;
      }
    }

    &--3 {
      width: 73px;
      height: auto;
      top: 32px;
      right: 16px;

      @media (min-width: 768px) {
        width: 118px;
        top: 80px;
        right: 110px;
      }
    }
  }
}
