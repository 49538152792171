@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	ul,
	ol {
		list-style: revert;
	}
}
