@import 'src/breakpoints';

.report-content {
  width: 100%;
  border-collapse: collapse;

  @media (min-width: $tablet-4) {
    background-color: var(--white);
  }

  &__head {
    display: none;

    @media (min-width: $tablet-4) {
      display: table-row;
      // for ILTB
      // background-color: var(--blue);
      // color: var(--white);
      background-color: var(--reports-sorting_bg);
      color: var(--brown-3);
      // border-color: red;
    }
    svg {
      path {
        fill: var(--brown-3);
      }
    }
    th {
      padding: 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 150%;
      vertical-align: middle;
      text-align: left;

      &:nth-child(1),
      &:nth-child(5) {
        padding: 0;
        min-width: 20px;
      }

      &:nth-child(2) {
        width: 8%;
        min-width: 100px;
        padding: 18px 18px 18px 40px;
        // &.head-count{
        width: 100px;
        // }
      }

      &:nth-child(3) {
        width: 46%;
        // padding: 18px;
      }

      &:nth-child(4) {
        width: 46%;
        // padding: 18px 40px 18px 18px;
      }

      .reports-sorting__sort {
        vertical-align: middle;
      }
    }
  }

  &__question {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding: 24px 16px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 150%;
    color: var(--black);
    background-color: var(--white);

    @media (min-width: $tablet-4) {
      display: table-row;
      padding: 0;
      margin: 0;

      td {
        padding: 16px 12px;
        vertical-align: top;
        color: var(--gen_font_color);

        &:first-child,
        &:last-child {
          padding: 0;
        }
      }
    }

    td {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(5) {
        display: none;

        @media (min-width: $tablet-4) {
          display: table-cell;
        }
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3) {
        margin-bottom: 12px;
      }
    }

    &--marked {
      background-color: rgba(147, 203, 255, 0.2);

      @media (min-width: $tablet-4) {
        background-color: transparent;

        td {
          background-color: rgba(147, 203, 255, 0.2);

          &:nth-child(2) {
            border-radius: 12px 0 0 12px;
          }

          &:nth-child(4) {
            border-radius: 0 12px 12px 0;
          }

          &:first-child,
          &:last-child {
            background-color: var(--white);
          }
        }
      }
    }
  }

  &__note {
    display: block;
    font-size: 14px;
    line-height: 160%;
    color: var(--grey-3);

    @media (min-width: $tablet-4) {
      display: none;
    }
  }
}
