@import 'src/breakpoints';

.topics-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__outer-wrap {
    position: relative;

    .request-session {
      margin: 28px auto 14px;
      border: 2px solid var(--gen_button_color);
      color: var(--gen_button_color);
      &:hover {
        border: 2px solid var(--gen_button_color) !important;
        color: var(--white) !important;
        background-color: var(--gen_button_color);
      }
    }
  }

  &__inner-wrap {
    @media (min-width: $tablet-4) {
      width: 360px;
      height: calc(100vh - 200px);
      overflow: hidden !important;
    }

    @media (min-width: $full) {
      height: calc(100vh - 232px);
    }

    @media (min-width: $program-l) {
      width: 377px;
    }

    @media (min-width: $full) {
      width: 577px;
    }

    &.scrollbar-container {
      position: static;

      > .ps__rail-y {
        display: none;
      }
    }
  }

  &__item {
    margin-bottom: 12px;
    padding: 0 0 0 24px;
    .topics-list__item-icon {
      svg {
        path {
          fill: var(--opened_activity);
        }
      }
    }

    @media (min-width: $tablet-4) {
      display: flex;
      padding: 0 8px 0 63px;
    }

    @media (min-width: $program-l) {
      display: flex;
      padding: 0 8px 0 81px;
    }

    &--topic-header {
      padding: 0 0 0 24px;

      @media (min-width: $tablet-4) {
        padding: 0 8px 0 44px;
      }

      .topics-list__item-icon {
        width: 48px;
        height: 48px;
        margin: 0 12px 0 -24px;

        svg {
          width: 24px;
        }

        @media (min-width: $tablet-4) {
          width: 72px;
          height: 72px;
          margin: 0 16px 0 -45px;

          svg {
            width: auto;
          }
        }

        @media (min-width: $full) {
          width: 88px;
          height: 88px;
          margin: 0 16px 0 -60px;

          svg {
            width: auto;
          }
        }
      }

      .topics-list__item-num {
        font-weight: var(--font-weight-medium);
      }

      .topics-list__item-title {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        line-height: 20px;

        @media (min-width: $full) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    &--active {
      @media (min-width: $tablet-4) {
        padding-right: 0;
      }

      .topics-list__item-description {
        // background-color: var(--san-marino);
        background-color: var(--opened_activity);

        @media (min-width: $tablet-4) {
          border-radius: 12px 0 0 12px;
        }
      }

      .topics-list__item-num {
        color: var(--white);
      }

      .topics-list__item-title {
        color: var(--white);
      }
    }

    &--active.topics-list__item--completed {
      .topics-list__item-description {
        background-color: var(--completed_activity);
      }

      .topics-list__item-num {
        color: var(--white);
      }

      .topics-list__item-title {
        color: var(--white);
      }
    }
    &.topics-list__item--completed {
      .topics-list__item-icon {
        svg {
          path {
            fill: var(--completed_activity);
          }
        }
      }
    }

    &--active.topics-list__item--blocked {
      .topics-list__item-description {
        background: var(--blocked_activity);
      }

      .topics-list__item-title {
        color: var(--white);
      }
    }

    &.topics-list__item--blocked {
      .topics-list__item-icon {
        svg {
          path {
            fill: var(--blocked_activity);
          }
        }
      }
    }

    &--completed {
      .topics-content {
        border-color: var(--completed_activity);
      }

      .topics-list__item-icon {
        border-color: var(--completed_activity);
        svg {
          path {
            fill: var(--completed_activity);
          }
        }
      }
    }

    &--blocked {
      .topics-content {
        border-color: var(--blocked_activity);
      }

      .topics-list__item-description {
        border-color: var(--blocked_activity);
        background: transparent;
      }

      .topics-list__item-title {
        color: var(--blocked_activity);
      }
      .topics-list__item-icon {
        border-color: var(--blocked_activity);
      }
    }
  }

  &__item-description {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px 16px 7px 0;
    border-radius: 12px;
    background: var(--white);
    cursor: pointer;
    transition: background-color 0.25s linear, border-color 0.25s linear;
    z-index: 20;

    @media (min-width: $tablet-4) {
      padding: 0px 16px;
    }
  }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin: 0 12px 0 -24px;
    // border: 2px solid var(--san-marino);
    border: 2px solid #2a6bbf;
    background-color: var(--white);
    border-radius: 100px;

    svg {
      width: 24px;
    }

    @media (min-width: $tablet-4) {
      width: 64px;
      height: 64px;
      margin: 0 24px 0 -42px;
      // border: 4px solid var(--san-marino);
      border: 4px solid var(--opened_activity);

      svg {
        width: auto;
      }
    }

    @media (min-width: $full) {
      width: 72px;
      height: 72px;
      margin: 0 24px 0 -52px;
      // border: 4px solid var(--san-marino);
      border: 4px solid var(--opened_activity);

      svg {
        width: auto;
      }
    }
  }

  &__lock-icon {
    margin-left: auto;
    flex-shrink: 0;
  }

  &__item-content {
    margin-right: 16px;
  }

  &__item-num {
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 18px;
    color: var(--blocked_activity);
    transition: color 0.25s linear, font-size 0.25s linear;

    @media (min-width: $full) {
      margin-bottom: 4px;
      font-size: 15px;
      line-height: 22px;
    }
  }

  &__item-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
    transition: color 0.25s linear, font-size 0.25s linear;

    @media (min-width: $full) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
