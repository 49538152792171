.EditScheduleDatePicker {
  display: flex;
  align-items: center;
  width: 160px;
  height: 44px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-size: 15px;
  line-height: 22.5px;
  font-weight: var(--font-weight-regular);
  padding: 0 13px;
  color: #212121;
  overflow: hidden;
  cursor: pointer;

  .icon {
    margin-right: 13px;
  }

  .value {
    white-space: pre;
  }

  &.error {
    border: 1px solid var(--pink);
  }

  &.react-datepicker-ignore-onclickoutside {
    border: 1px solid #2e79da;
  }

  @media screen and (max-width: 859px) {
    width: 175px;
  }

  @media screen and (max-width: 450px) {
    width: 130px;

    .value {
      max-width: 77px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 375px) {
    width: 120px;
    .value {
      max-width: 65px;
    }
  }
}
