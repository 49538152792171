.filter-wrapper {
  margin-bottom: 24px;
  form {
    display: flex;
    .filter_item {
      position: relative;
      font-weight: var(--font-weight-medium);
      font-size: 17px;
      line-height: 140%;
      letter-spacing: 0.0015em;
      color: #101821;
      padding: 0;
      margin-right: 16px;
      max-width: 354px;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .mobile {
    &__filters-headers {
      display: none;
      margin-bottom: 22px;
    }
    &__filters-title {
      font-weight: var(--font-weight-medium);
      font-size: 32px;
      line-height: 125%;
      letter-spacing: 0.015em;
    }
    &__filters-close {
      width: 16px;
      height: 16px;
      cursor: pointer;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: 6px;
        width: 1px;
        height: 18px;
        background: #929292;
        transform: rotate(45deg);
      }
      &:after {
        content: '';
        position: absolute;
        top: -2px;
        left: 6px;
        width: 1px;
        height: 18px;
        background: #929292;
        transform: rotate(-45deg);
      }
    }
  }

  .mobile__filters {
    background: #ffffff;
    color: #2e79da;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 8px;
      path {
        fill: var(--blue-3);
      }
    }
  }
}

// .css-isv2re-control,
// .css-wem4zo-control {
//   .css-1rhbuit-multiValue {
//     background: rgba(253, 201, 76, 0.3);
//     border-radius: 24px;
//     padding: 5px 16px;
//     .css-12jo7m5 {
//       color: #6a541f !important;
//     }
//     .css-xb97g8 {
//       display: none;
//     }
//   }
// }

.select_checkboxes {
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1wa3eu0-placeholder {
    font-weight: var(--font-weight-medium);
    padding: 0;
    font-size: 17px;
    line-height: 140%;
    color: var(--grey-3);
  }
  input {
    -webkit-appearance: checkbox !important;
    -moz-appearance: auto;
    appearance: auto;
  }
  label {
    margin-left: 10px;
  }
}
