@import "src/breakpoints";

.reports-search {
  position: absolute;
  display: flex;
  align-items: center;
  transition: border-color 0.15s;
  right: 0;
  top: 0;
  z-index: 2;

  &--mobile {
    border: 2px solid transparent;
    border-radius: 50px;

    .reports-search__search {
      width: 48px;
      height: 48px;
      padding: 12px;
      border: 2px solid var(--orange-2);
      border-radius: 24px;
    }
  }

  &--is-open {
    background-color: var(--background);
    border-radius: 50px;
    padding-left: 20px;

    .reports-search__input-group {
      min-width: 290px;
    }
  }

  &__search {
    cursor: pointer;
    flex-shrink: 0;
  }

  &__input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &--enter {
      opacity: 0;
      max-width: 0;
    }

    &--enter-active {
      opacity: 1;
      max-width: 1000px;
      transition: opacity 0.3s, max-width 0.4s;
    }
    &--exit {
      opacity: 1;
      max-width: 1000px;
    }
    &--exit-active {
      opacity: 0;
      max-width: 0;
      transition: opacity 0.4s 0.1s, max-width 0.4s;
    }
  }

  &__input {
    width: calc(100% - 22px);
    padding-left: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 130%;
    color: var(--black);

    @media (min-width: 520px) {
      font-size: 14px;
    }

    @media (min-width: $tablet-4) {
      font-size: 18px;
      padding-left: 20px;
    }

    &::placeholder {
      color: var(--grey-3);
    }
  }

  &__clear {
    flex-shrink: 0;
    cursor: pointer;
    margin-left: 8px;
  }
}
