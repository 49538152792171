@import '../../../../variables.scss';

section.starting-page {
  position: relative;
  width: 100%;
  background-color: var(--background);

  .container {
    width: 100%;
    margin: 0 auto;
    height: auto;
    min-height: calc(
      100vh - var(--auth-footer-min-height) - var(--auth-header-min-height)
    );
    background-image: url(../../../../assets/vector/gradients/gradient-3.svg);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 60px 0;

    @media screen and (min-width: 1280px) {
      width: 90%;
      padding: 120px 0;
    }

    .title {
      max-width: 90%;
      margin: 0 auto;
      font-size: 42px;
      line-height: 125%;
      text-align: center;

      @media screen and (min-width: 768px) {
        max-width: 80%;
      }

      @media screen and (min-width: 1100px) {
        max-width: 900px;
        font-size: 52px;
      }

      span {
        color: var(--pink);
      }
    }

    .description {
      max-width: 90%;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      line-height: 140%;
      text-transform: uppercase;

      @media screen and (min-width: 768px) {
        max-width: 80%;
      }

      @media screen and (min-width: 1100px) {
        max-width: 900px;
      }

      .first {
        margin-top: 60px;
      }

      .second {
        margin-top: 30px;
        font-weight: var(--font-weight-medium);
      }
    }

    .links {
      max-width: 900px;
      margin: 50px auto 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: 769px) {
        margin: 80px auto 0;
        max-width: 900px;
        flex-direction: row;
      }

      .link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        height: 52px;
        font-size: 16px;
        line-height: 24px;
        font-weight: var(--font-weight-medium);
        text-decoration: none;
        border-radius: 8px;
        margin: 10px 0;

        @media screen and (min-width: 769px) {
          margin: 0 20px;
        }

        &.coach_matching {
          background-color: var(--blue-3);
          color: #fff;
        }

        &.start_link {
          border: 2px solid var(--blue-3);
          color: var(--blue-3);
        }
      }
    }
  }
}
