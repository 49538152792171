@import 'src/breakpoints';

.page-dashboard {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .program-overview__wrapper {
    display: flex;
    flex-direction: column;
    width: 68%;

    .overview__title {
      min-height: 32px;
      width: 100%;
      font-weight: var(--font-weight-medium);
      font-size: 20px;
      line-height: 125%;
      color: #212121;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        margin-left: 6px;
      }

      .program-status {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 32px;
        background: #3d8b82;
        border-radius: 100px;
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 150%;
        color: #ffffff;
      }
    }

    .program-overview {
      position: relative;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      padding: 25px;
      background: #ffffff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      min-height: calc(100vh - 180px);

      .spinner__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-direction: column;
        span {
          font-weight: var(--font-weight-regular);
          font-size: 14px;
          line-height: 150%;
          color: #000000;
        }
      }

      .attend {
        margin: 0 0 14px;

        @media (min-width: $desktop) {
          margin: 24px 0;
        }
      }

      .top__section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: -25px;
          bottom: -12px;
          width: calc(100% + 50px);
          height: 1px;
          background-color: #d8d8d8;

          @media screen and (max-width: 1023px) {
            display: none;
          }
        }

        .left__side {
          width: 43%;
          display: flex;
          flex-direction: column;
        }

        .credit-graph--total {
          width: 43%;
          padding: 0;

          @media screen and (min-width: 1450px) and (max-width: 1680px) {
            width: 45%;
          }

          .title {
            display: none;
          }

          .credit-graph {
            margin-top: 0;
          }

          .credit-graph__stats {
            flex-direction: row;
            justify-content: flex-end;

            .credit-graph__stat {
              margin: 0 0 0 12px;
            }

            @media screen and (min-width: 1450px) and (max-width: 1680px) {
              justify-content: flex-end;

              .credit-graph__stat {
                margin: 0 0 0 12px;
              }
            }
          }
        }
      }

      .group-sessions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0 0 50px;

        .section-title {
          font-weight: 700;
          font-size: 32px;
          line-height: 125%;
          letter-spacing: 0.015em;
          margin: 0;
          color: var(--gen_font_color);
        }

        .credits {
          display: flex;

          .values {
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            display: flex;

            .from {
              color: #101821;
            }

            .to {
              color: #828282;
            }
          }

          .text {
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            color: #101821;
            margin: 0 0 0 8px;
          }
        }
      }

      .charts-list {
        width: 100%;
        justify-content: space-between;
        display: flex;
        margin: 36px 0 0;

        .chart-item {
          max-width: 32%;

          @media screen and (min-width: 2100px) {
            max-width: 30%;
          }
        }
      }

      .chart-line {
        .chart-item {
          .apexcharts-toolbar {
            z-index: 1;
          }
        }

        .chart-item-not-available {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          justify-content: center;
          height: 425px;
          .block-subtitle {
            color: var(--gen_font_color);
          }

          &:before {
            position: absolute;
            content: '';
            bottom: -36px;
            right: -40px;
            width: calc(100% + 80px);
            height: calc(100% + 30px);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../../../assets/vector/gradients/gradient-10.svg);
          }
        }
      }

      .attendance-rate-section {
        position: relative;
        .section-title {
          color: var(--gen_font_color);
        }
        .sessions-list {
          display: flex;
          margin: 16px 0 32px;
          overflow: scroll;
          z-index: 2;
          position: relative;

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--grey-5);
            border: none;
            border-radius: 25px;
          }

          &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 25px;
          }

          .sessions-list-item {
            position: relative;

            .rate-wrap {
              display: flex;
              height: 200px;
              align-items: flex-end;
              margin: 0 28px 0 0;

              .rate-line {
                border-radius: 8px 8px 0 0;
                width: 8px;
              }
            }

            .title {
              font-weight: var(--font-weight-regular);
              font-size: 12px;
              line-height: 24px;
              transform: rotate(180deg);
              writing-mode: vertical-rl;
              margin: 10px 0 0 0;
              color: #bdbdbd;
            }
          }
        }

        .attendance-rate-info {
          display: flex;

          .info-item {
            display: flex;
            align-items: center;
            margin: 0 15px 10px 0;

            .color-square {
              width: 16px;
              height: 16px;
              border-radius: 4px;
              margin: 0 12px 0 0;
            }

            .count {
              font-weight: var(--font-weight-medium);
              font-size: 14px;
              line-height: 20px;
              color: var(--gen_font_color);
              margin: 0 8px 0 0;
            }

            .text {
              font-weight: var(--font-weight-regular);
              font-size: 14px;
              line-height: 20px;
              color: var(--gen_font_color);
            }
          }
        }

        .subtitle {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;

          letter-spacing: 0.015em;

          margin: 0 0 12px;

          color: var(--gen_font_color);
        }

        .sessions-list-not-available {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          justify-content: center;
          height: 390px;
          width: calc(100% + 80px);
          left: -40px;

          .block-subtitle {
            color: var(--gen_font_color);
          }
          &:before {
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            width: calc(100%);
            height: calc(100%);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../../../assets/vector/gradients/gradient-10.svg);
          }
        }
      }
    }
  }

  .apexcharts-legend.position-bottom.apexcharts-align-center,
  .apexcharts-legend.position-top.apexcharts-align-center {
    justify-content: flex-start;
  }

  @media (max-width: 1449px) {
    .program-overview__wrapper {
      .program-overview {
        .top__section {
          flex-direction: column;
          align-items: flex-start;
          .left__side {
            width: 50%;
          }

          .credit-graph--total {
            margin-top: 20px;
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    .program-overview__wrapper {
      .program-overview {
        .charts-list {
          flex-direction: column;
          .chart-item {
            max-width: 100%;
            margin-bottom: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: unset;

    .program-overview__wrapper {
      order: 2;
      width: 100%;
      .program-overview {
        margin-top: 0;
        min-height: calc(100vh - var(--header-height) - 230px);
        background: none;
        padding: 32px 0 0;
        box-shadow: none;

        .overview__title {
          font-size: 20px;
        }

        .top__section {
          flex-direction: column;

          .left__side {
            width: 100%;
          }

          .credit-graph--total {
            width: 100%;
            padding: 14px 12px 12px;
            background: #ffffff;
            box-shadow: 0px 1.34387px 6.71937px rgba(0, 0, 0, 0.08);
            border-radius: 8px;

            .credit-graph {
              margin-top: 0;
            }

            .credit-graph__stats {
              justify-content: flex-start;

              .credit-graph__stat {
                margin: 0 12px 0 0;
              }
            }
          }
        }

        .charts-list {
          flex-direction: column;
          margin: 14px 0 0;
          .chart-item {
            max-width: 100%;
            margin-bottom: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 499px) {
    flex-direction: column;
    .program-overview__wrapper {
      .program-overview {
        .top__section {
          .credit-graph--total {
            .credit-graph__stats {
              flex-direction: column;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  @media (min-width: $full) {
    .program-overview__wrapper {
      .overview__title {
        margin-top: 12px;
        font-size: 24px;
      }
    }
  }
}
