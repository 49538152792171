@import 'src/breakpoints';

.sign-up {
  width: 100%;
  max-width: 540px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    margin: 0;
  }

  &__label {
    display: block;
    margin-bottom: 8px;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 140%;
    color: var(--black);

    @media (min-width: $tablet-1) {
      margin-bottom: 10px;
      font-size: 17px;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 15px 50px 15px 24px;
    margin-bottom: 32px;
    border-radius: 12px;
    background-color: var(--white);
    color: #101821;
    font-size: 15px;
    line-height: 150%;
    // border: 1px solid var(--white);
    border: 1px solid #edebf9;
    transition: border-color 0.25s;

    &::placeholder {
      font-size: 15px;
      line-height: 150%;
      color: var(--grey-3);
    }

    &--error {
      border: 1px solid var(--stiletto);
    }
  }

  &__input-wrap {
    position: relative;
    width: 100%;

    &--password {
      .icon {
        position: absolute;
        right: 24px;
        top: 23px;
        cursor: pointer;

        path {
          transition: all 0.25s;
        }

        &.is-shown {
          path {
            fill: var(--orange-2);
          }
        }
      }
    }
  }

  &__select {
    &--error {
      .sign-up-select__control {
        border: 1px solid var(--stiletto);
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--sign-in {
      align-items: center;
    }

    @media (min-width: 640px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 24px;
    height: 52px;
    width: 131px;
    margin: 0 0 32px 0;
    // for ILTB
    // background: var(--orange-2);
    // border-radius: 80px;
    background: var(--blue-3);
    border-radius: 8px;
    border: 0;
    color: var(--white);
    cursor: pointer;
    transition: all 0.2s linear;

    @media (min-width: 640px) {
      flex-direction: row;
      align-items: center;
      margin: 0 32px 0 0;
    }

    &:hover {
      // for ILTB
      // background: var(--orange-4);
      background: var(--blue-3);
    }

    &:disabled {
      // for ILTB
      color: var(--rafia);
      // background: var(--orange-3);
      background: var(--blue-3);
    }
  }

  &__bottom-text {
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 24px;
    color: #101821;
  }

  &__login-link {
    display: inline-block;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;
    // for ILTB
    // color: var(--orange-2);
    color: var(--blue-3);
    text-decoration: none;
    transition: all 0.2s linear;

    &:hover {
      // for ILTB
      // color: var(--orange-4);
      color: var(--blue-3);
    }
  }
}
