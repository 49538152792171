@import 'src/variables';

.attend {
  padding: 16px;
  background-color: white;
  border: 1px solid var(--grey-girl);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  @media (min-width: $tablet-3) {
    border-radius: 12px;
    box-shadow: none;
    padding: 14px 14px 19px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--grey-girl);

    @media (min-width: $tablet-3) {
      padding-bottom: 12px;
    }
  }

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 140%;

    @media (min-width: $tablet-2) {
      flex-shrink: 0;
      margin-right: 16px;
      flex-grow: 1;
    }

    @media (min-width: $tablet-3) {
      font-size: 14px;
      line-height: 125%;
    }

    @media (min-width: $desktop) {
      font-size: 16px;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 16px 0 0;

    @media (min-width: $mobile) {
      justify-content: center;
      flex-wrap: nowrap;
    }

    @media (min-width: $tablet-2) {
      width: auto;
      flex-shrink: 0;
      margin: 0;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0 16px 4px 0;
    font-size: 12px;
    line-height: 150%;

    @media (min-width: $mobile) {
      margin: 0 24px 0 0;
    }

    @media (min-width: $tablet-2) {
      margin: 0 10px 0 0;
    }

    @media (min-width: 1100px) {
      margin: 0 24px 0 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: '';
      display: block;
      position: relative;
      bottom: 1px;
      width: 8px;
      height: 8px;
      background: var(--db_confirmed_session);
      border-radius: 40px;
      margin-right: 4px;
    }

    &--completed {
      &::before {
        background: var(--db_completed_session);
      }
    }

    &--not-attended {
      &::before {
        background: var(--db_not_attended_session);
      }
    }
  }
}
