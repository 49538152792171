@import 'src/variables';

.rate {
  padding: 4px 0 8px;

  @media (min-width: $desktop) {
    padding: 8px 0 8px;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 360px;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 160%;
    text-align: center;

    .icon {
      flex-shrink: 0;
      margin-bottom: 16px;
    }
  }

  &__row {
    display: flex;
    align-items: center;

    &--sessions {
      .rate__col {
        &:first-child {
          font-weight: var(--font-weight-medium);
          font-size: 12px;
          line-height: 140%;

          @media (min-width: $desktop) {
            font-size: 14px;
            line-height: 120%;
          }
        }

        &:nth-child(n + 2) {
          height: 24px;
          background: var(--grey-gun);
          border-radius: 4px;
          font-weight: var(--font-weight-medium);
          font-size: 12px;
          line-height: 120%;

          @media (min-width: $desktop) {
            font-size: 14px;
          }
        }
      }
    }

    &--graph {
      padding-bottom: 0;
      align-items: flex-start;

      .rate__col {
        padding: 4px 8px 8px;
      }
    }
  }

  &__col {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 1px;
    white-space: pre-wrap;

    &:first-child {
      width: 150px;
      padding-right: 5px;
      justify-content: flex-start;
      font-size: 12px;
      line-height: 160%;
      text-align: left;

      @media (min-width: 650px) {
        width: 220px;
      }
      @media (min-width: 1000px) and (max-width: 1180px) {
        width: 150px;
      }
    }

    &:nth-child(n + 2) {
      width: 75px;
      font-size: 12px;
      line-height: 140%;
    }

    &:last-child {
      margin-right: 0;
    }

    &--add-col {
      padding: 4px 8px 4px;
      border: 1px solid var(--grey-gun);
      border-top: 0;
      border-bottom: 0;
    }

    &--add-graph {
      border: 1px solid var(--grey-gun);
      border-top: 0;
      border-radius: 4px;
    }

    .accent {
      color: var(--grey-3);
    }
  }
}
