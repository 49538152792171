@import 'src/breakpoints';

.invited-participants {
  &__label {
    display: inline-block;
    margin-bottom: 7px;
    padding: 0 24px 0;
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 150%;
    color: var(--black);

    @media (min-width: $modal) {
      font-weight: var(--font-weight-medium);
      font-size: 16px;
      line-height: 140%;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    margin: 0 0 16px;
    padding: 26px 20px;
    border: 1px solid var(--grey-5);
    border-radius: 12px;
    list-style: none;

    @media (max-width: 325px) {
      grid-template-columns: 100%;
    }

    @media (min-width: 460px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__user {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 110px;
    padding: 5px;
    font-size: 12px;
    color: var(--black);

    @media (min-width: 460px) {
      width: 110px;
    }
  }

  &__user-img {
    width: 44px;
    height: 44px;
    margin-bottom: 8px;
    object-fit: cover;
    border-radius: 80px;

    &.is-declined {
      outline: 1px solid var(--blood);
      opacity: 0.5;
    }
  }

  &__user-status {
    margin-top: 4px;
    color: var(--blood);
    font-weight: var(--font-weight-medium);
  }

  &__user-name {
    line-height: 120%;
    text-align: center;
    font-weight: var(--font-weight-medium);
  }

  &__user-zone {
    margin-top: 4px;
    font-weight: var(--font-weight-medium);
    line-height: 125%;

    .hours {
      display: block;
      color: var(--grey-3);

      &.alert {
        color: var(--pink);
      }
    }
  }

  &__remove {
    position: absolute;
    top: -14px;
    right: 18px;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}
