.action-list {
  &__list {
    margin-top: 58px;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    margin-bottom: 33px;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: 520px) {
      flex-wrap: nowrap;
    }

    &--completed {
      .action-list__item-box::before {
        opacity: 1;
        transform: scale(1);
        background-color: var(--blue-3);
      }

      .action-list__item-text {
        color: var(--grey-3);

        &::before {
          width: 100%;
        }
      }
    }
  }

  &__item-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 4px 16px 0 0;

    @media (min-width: 520px) {
      margin: 0 16px 0 0;
    }
  }

  &__item-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background: var(--white);
    border: 1px solid var(--grey-5);
    border-radius: 2px;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      opacity: 0;
      transform: scale(0.8);
      transition: transform 0.4s, background-color 0.25s linear, opacity 0.2s;
    }
  }

  &__item-text {
    position: relative;
    font-size: 18px;
    line-height: 130%;
    color: var(--black);
    transition: color 0.25s;
    word-break: break-all;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      top: 50%;
      background-color: var(--grey-3);
      transition: width 0.25s ease;
    }
  }

  &__item-actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-top: 8px;

    @media (min-width: 520px) {
      margin: 0;
    }
  }

  &__item-due {
    font-size: 15px;
    line-height: 22px;
    color: var(--grey-3);
  }

  &__item-delete {
    margin-left: 16px;
    cursor: pointer;
    flex-shrink: 0;
  }

  &__add-btn {
    padding: 8px 32px;
    margin: 62px 0 0 0px;
    border: 2px solid var(--gen_button_color);
    color: var(--gen_button_color);
    font-size: 16px;
    line-height: 24px;
    &:hover {
      background-color: var(--gen_button_color);
      color: var(--white);
    }
  }
}
