.attached-file-wrap {
  margin: 50px 0 0;

  .file-attached-title {
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 140%;

    margin: 0 0 16px;
    color: #101821;
  }

  .attached-file-item {
    display: flex;
    .attached-file {
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      overflow: hidden;
      padding: 16px 18px;
      background: var(--grey-7);
      border-radius: 12px;
      margin: 0 0 15px;

      &-download__icon {
        margin: 0 0 0 16px;
        width: 16px;
        height: 16px;

        .icon {
          min-width: 16px;
        }
      }

      &__remove {
        position: relative;
        top: 2px;
        width: 15px;
        height: auto;
        margin-left: 16px;
        flex-shrink: 0;
      }

      &__icon {
        margin-right: 28px;
        flex-shrink: 0;
        // for Switch
        path {
          fill: var(--blue-3);
        }
      }

      &__link {
        text-decoration: none;
        font-weight: var(--font-weight-medium);
        font-size: 17px;
        line-height: 24px;
        color: var(--william);
        max-width: calc(100% - 85px);
        word-break: break-all;
      }
    }
  }
}
