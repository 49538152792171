@import 'src/breakpoints';

.activity-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid #d8d8d8;
  text-decoration: none;

  &:first-child {
    border-top: none;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid var(--blue-3);

    svg {
      width: 20px;

      path {
        fill: var(--blue-3);
      }
    }
  }

  &__content {
    margin-left: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
  }

  &__type {
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 150%;
    color: #646466;
    text-transform: capitalize;

    @media screen and (min-width: $full) {
      font-weight: var(--font-weight-medium);
      font-size: 15px;
    }
  }

  &__name {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 140%;
    color: var(--black1);

    @media screen and (min-width: $full) {
      font-weight: var(--font-weight-medium);
      font-size: 18px;
    }
  }

  &__actions {
    margin: 0 3px 0 18px;
  }

  &__complete-btn {
    width: 154px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--blue-3);
    color: var(--blue-3);
    border-radius: 4px;
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.01em;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
  }

  &:hover {
    .activity-item__name {
      color: var(--blue-3);
    }
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    padding: 24px 0;

    &:first-child {
      padding-top: 12px;
    }

    &__icon {
      width: 56px;
      height: 56px;

      svg {
        width: 28px;
      }
    }

    &__content {
      margin: 16px 0 0;
      align-items: center;
    }

    &__actions {
      margin: 24px 0 0;
      width: 100%;
    }

    &__complete-btn {
      width: 99%;
      height: 42px;
    }
  }
}
