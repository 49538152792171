.chart-item {
  margin: 0;
  max-width: 100%;
  width: 100%;
  padding: 14px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  background-color: #ffffff;

  .chart-item-title {
    font-size: 14px;
    line-height: 140%;
  }

  .chart-wrap-chart {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .pie-chart-data {
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.frequency {
        background: rgba(91, 203, 201, 0.1);
      }

      .title-chart {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.015em;
        margin: 0 0 2px;
        color: #212121;
      }

      .subtitle-chart {
        font-weight: var(--font-weight-regular);
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        color: #212121;
      }
    }

    svg {
      path {
        filter: drop-shadow(-1px -1px #ffffff) drop-shadow(1px 1px #ffffff)
          drop-shadow(-1px 1px #ffffff) drop-shadow(1px -1px #ffffff);
      }
    }
  }

  .chart-info-block {
    width: 100%;
    margin: 20px 0 0;
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;

    .columns {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 1440px) {
        flex-direction: row;
        justify-content: space-between;
      }

      @media screen and (min-width: 2100px) {
        justify-content: space-around;
      }
    }

    &.frequency {
      align-items: center;
      justify-content: center;
    }

    .chart-item-title {
      font-weight: var(--font-weight-medium);
      font-size: 17px;
      line-height: 140%;
      letter-spacing: 0.0015em;
      color: var(--gen_font_color);
      margin: 0 0 8px;
    }

    .pie-chart-statistic {
      margin: 0;
      padding: 0;

      .statistic-item {
        width: 100%;
        display: flex;
        align-items: center;

        .color {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: 0 4px 0 0;

          &.not_great {
            background-color: var(--db_satisfaction_bad);
          }
          &.okay {
            background-color: var(--db_satisfaction_neutral);
          }
          &.amazing {
            background-color: var(--db_satisfaction_good);
          }

          &.completed {
            background-color: var(--db_completed_session);
          }
          &.not_attended {
            background-color: var(--db_not_attended_session);
          }
          &.confirmed {
            background-color: var(--db_confirmed_session);
          }
          &.assigned {
            background-color: var(--db_assigned_session);
          }
          &.not_assigned {
            background-color: var(--db_not_assigned_session);
          }
        }

        .value {
          margin: 0 4px 0 0;
          font-weight: var(--font-weight-medium);
          font-size: 12px;
          line-height: 125%;
          letter-spacing: 0.0015em;
          color: var(--gen_font_color);
        }

        .status {
          font-weight: var(--font-weight-regular);
          font-size: 12px;
          line-height: 160%;
          text-align: center;
          color: var(--gen_font_color);

          &.frequency {
            color: #929292;
            line-height: 140%;

            @media screen and (min-width: 1680px) {
              padding: 0 15%;
            }
          }
        }
      }
    }
  }

  .chart-footer {
    margin-top: 8px;
    padding-top: 7px;
    font-size: 12px;
    line-height: 160%;
    color: #212121;
    font-weight: var(--font-weight-regular);
    text-align: center;
    border-top: 1px solid #d8d8d8;

    span {
      font-size: 14px;
      line-height: 125%;
      font-weight: var(--font-weight-medium);
    }
  }
}
