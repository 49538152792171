@import 'src/breakpoints';

.page-not-found {
  height: calc(100vh - 92px - 127px);

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    height: 100%;

    .title {
      font-weight: var(--font-weight-medium);
      font-size: 32px;
      line-height: 125%;

      letter-spacing: 0.015em;
      color: #212121;
    }

    .img-page-not-found {
      width: auto;
    }

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: calc(100%);
      height: calc(100%);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../../assets/vector/gradients/gradient-11.svg);
    }
  }

  @media (max-width: $tablet-4) {
    height: calc(100vh - 84px);
    .wrapper {
      .img-page-not-found {
        width: 100%;
      }
    }
  }

  @media (max-width: $tablet) {
    height: calc(100vh - 48px);

    .wrapper {
      .title {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        margin: 50px 0 0 0;
      }

      .img-page-not-found {
        width: 100%;
      }
    }
  }
}
