@import 'src/breakpoints';

.reports-filter {
  display: flex;
  flex-wrap: wrap;

  &__filter {
    min-width: 220px;
    margin: 0 8px 24px 0;

    @media screen and (min-width: $reports-table-s) {
      margin: 0 12px 24px 0;
    }

    @media screen and (min-width: $full) {
      margin: 0 24px 24px 0;
    }
  }

  .sign-up-select__control {
    padding: 4px;
  }

  .mobile__submit {
    margin-top: 40px;
    width: 141px;
    height: 47px;
    background: #2e79da;
    border-radius: 8px;
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}
