@import 'src/breakpoints';

.calendar-event {
  position: relative;

  &__type {
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 125%;
    margin-bottom: 12px;
    color: var(--gen_font_color);

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-bottom: 24px;
    padding: 4px 12px;
    background: var(--grey-gun);
    border-radius: 100px;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    text-transform: capitalize;

    &.confirmed,
    &.Confirmed {
      background-color: var(--session_confirmed);
      color: white;
    }

    &.completed {
      background-color: var(--session_completed);
      color: white;
    }

    &.late_canceled,
    &.canceled {
      background-color: var(--session_late_canceled);
      color: white;
    }

    &.pending {
      background-color: var(--session_pending);
    }
  }

  &__date {
    display: flex;
    align-items: center;
  }

  &__time-date {
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
  }

  &__time {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .icon {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &__details {
    margin-top: 13px;
    word-wrap: break-word;
    word-break: break-all;
  }

  &__time-duration {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
  }

  &__session {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 12px;
    border: 1px solid #f1f1f1;
    border-radius: 12px;

    @media (min-width: 460px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__session-title {
    display: flex;
    align-items: center;
    margin: 0 16px 16px 0;
    font-weight: var(--font-weight-medium);
    font-size: 18px;
    line-height: 125%;
    color: var(--black);
    word-break: break-word;

    @media (min-width: 460px) {
      margin-bottom: 0;
    }

    @media screen and (min-width: 1920px) {
      font-size: 20px;
    }

    .icon {
      flex-shrink: 0;
      margin-right: 14px;
    }
  }

  &__session-name {
    height: 44px;
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 24px;
    padding: 8px 24px;
  }

  &__members-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: var(--grey-3);
    margin: 24px 0 12px;
  }

  &__members {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    gap: 12px 24px;
  }

  &__member-info {
    display: flex;
    align-items: center;

    .avatar {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      object-fit: cover;
      border-radius: 50px;
      border: 2px solid #FFFFFF;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
    }

    .name {
      font-weight: 500;
      font-size: 15px;
      line-height: 150%;
    }

    .time {
      color: var(--grey-3);
      font-size: 14px;
      font-weight: 400;
    }

    @media screen and (min-width: 1920px) {
      img {
        width: 48px;
        height: 48px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: white;
    border-radius: 24px 24px 0 0;

    @media (min-width: $modal) {
      padding: 40px 40px 24px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px 24px;
    border-radius: 0 0 24px 24px;
    background-color: var(--blue-3);

    @media (min-width: $modal) {
      padding: 12px 40px;
    }
  }

  &__footer-title {
    display: flex;
    align-items: center;
    margin: 8px 24px 8px 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: white;

    .inner-text {
      display: block;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &__footer-icon {
    flex-shrink: 0;
    margin-right: 12px;
  }

  &__footer-btn {
    height: 48px;
    margin: 8px 0;
    padding: 5px 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .user_message {
    display: block;
    text-align: center;
  }

  .event-info {
    margin-bottom: 12px;
  }
}
