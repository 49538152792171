@import 'src/breakpoints';

.reports-desk {
  width: 100%;
  padding: 24px 16px;

  @media (min-width: $tablet-1) {
    padding: 32px 24px;
  }

  @media (min-width: $full) {
    padding: 40px 10px;
  }

  // @media (min-width: $desktop-2) {
  //     padding: 63px 48px 0;
  // }

  &__filters {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;

    @media (min-width: $tablet-4) {
      margin-bottom: 0;
    }

    .billing-actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .generate-invoice {
        order: 1;
      }

      .select-currency {
        order: 2;
      }

      @media screen and (min-width: $tablet-1) {
        flex-direction: row;
        justify-content: space-between;
        .generate-invoice {
          order: 2;
        }

        .select-currency {
          order: 1;
        }
      }
    }
  }
}
