@import 'src/breakpoints';

.navigation {
  display: none;
  width: 100%;
  height: calc(100% - 48px);
  flex-direction: column;
  justify-content: space-between;
  //for ILoveToBe:
  // background: var(--green);
  background: var(--white);
  //for ILoveToBe:
  // box-shadow: inset -1px 0px 0 0px var(--green-4);
  box-shadow: inset -1px 0px 0 0px #edebf9;
  z-index: 4;

  @media (min-width: 920px) {
    display: flex;
    position: fixed;
    width: 245px;
    height: 100%;
  }

  @media (min-width: $full) {
    width: 365px;
  }

  .navigation-top {
    .logo {
      display: flex;
      align-items: center;
      height: 64px;
      padding: 0 22px;
      a {
        text-decoration: none;
        height: 64px;
        display: flex;
        align-items: center;

        .user-avatar {
          object-fit: cover;
          width: 44px;
          height: 44px;
          border-radius: 50px;
          border: 2px solid #fff;
        }

        .logo-text {
          font-weight: var(--font-weight-medium);
          font-size: 16px;
          line-height: 20px;
          // max-width: 144px;
          //for ILoveToBe:
          // color: var(--white);
          color: var(--gen_font_color);
          margin: 0 0 0 12px;
          word-break: break-all;
        }
      }
    }

    .menu {
      margin-top: 12px;
      display: flex;
      flex-direction: column;

      a {
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        line-height: 150%;
        //for ILoveToBe:
        // color: var(--white);
        color: var(--gen_font_color);
        height: 52px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 0 0 70px;
        margin: 8px 0;

        @media screen and (min-width: $full) {
          font-size: 18px;
          padding: 0 0 0 84px;
        }

        &.is-active {
          //for ILoveToBe:
          // background: var(--white);
          // color: var(--green);
          // background: linear-gradient(90deg, rgba(94, 211, 242, 0.15) 2.19%, rgba(94, 211, 242, 0) 100%), #FFFF;
          background: linear-gradient(
              90deg,
              var(--active_menu) -340%,
              rgba(94, 211, 242, 0) 100%
            ),
            #ffffff;
          color: var(--active_menu);
          width: calc(100% - 1px);
          border-right: 6px solid var(--active_menu);

          .menu-icon {
            //for ILoveToBe:
            // fill: var(--green);
            fill: var(--active_menu);
          }
        }

        .menu-icon {
          position: absolute;
          //for ILoveToBe:
          // fill: var(--white);
          // fill: var(--black);
          left: 27px;
          fill: var(--gen_font_color);

          @media screen and (min-width: $full) {
            left: 42px;
          }
        }
      }
    }
  }
  &--visible {
    @media (max-width: 919px) {
      position: absolute;
      bottom: 0;
      display: flex;
      z-index: 50;
      overflow: auto;
      background: var(--blue-3);
      box-shadow: none;
      height: calc(100% - 47px);
      .navigation-top {
        .logo a {
          .logo-text {
            color: #fff;
          }
        }
        .menu {
          a {
            color: #fff;
            .menu-icon {
              fill: #fff;
            }
            .menu-name {
              color: #fff !important;
            }
            &.is-active {
              color: var(--blue-3) !important;
              .menu-icon {
                fill: var(--blue-2);
              }
              .menu-name {
                color: var(--blue-2) !important;
              }
              &:after {
                right: 0;
                left: unset;
                background: #edbd48;
              }
            }
          }
        }
      }
      .navigation-bottom {
        .copy {
          color: #fff !important;
        }
      }
    }
  }

  .navigation-bottom {
    margin: 40px 0 40px 27px;

    @media screen and (min-width: $full) {
      margin: 40px 0 40px 40px;
    }

    .copy {
      margin: 14px 0 0 0;
      font-weight: var(--font-weight-regular);
      font-size: 12px;
      line-height: 16px;
      //for ILoveToBe:
      // color: var(--green-2);
      color: var(--grey-3);

      @media screen and (min-width: $full) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
