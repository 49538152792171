.questionnaire-languages__section {
  width: 100%;
  display: flex;
  justify-content: center;

  .section-content__wrapper {
    max-width: 400px;
    display: flex;
    flex-direction: column;

    .section__title {
      font-weight: var(--font-weight-medium);
      font-size: 32px;
      line-height: 125%;
      text-align: center;
      letter-spacing: 0.015em;
      color: #222222;
    }

    .section__description {
      margin-top: 14px;
      font-weight: var(--font-weight-regular);
      font-size: 15px;
      line-height: 150%;
      text-align: center;
      color: #929292;
    }

    .section__buttons {
      margin-top: 44px;
      display: flex;
      justify-content: space-around;

      .language-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 156px;
        height: 68px;
        border: 2px solid #d8d8d8;
        border-radius: 100px;
        background-color: #fff;
        color: #212121;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.06em;
        cursor: pointer;
        transition: all 0.2s linear;

        &.active {
          color: #2e79da;
          border: 2px solid #2e79da;
        }

        &:hover {
          color: #2e79da;
          border: 2px solid #2e79da;
        }

        .language__icon {
          margin-right: 8px;
        }
      }
    }

    .proceed-button__wrapper {
      margin-top: 44px;
      display: flex;
      justify-content: center;

      .proceed-btn {
        width: 132px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2e79da;
        border: none;
        border-radius: 8px;
        color: #fff;
        font-weight: var(--font-weight-medium);
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: auto;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .section-content__wrapper {
      .section__title {
        margin-top: 20px;
      }

      .section__buttons {
        margin-top: 22px;
        flex-direction: column;
        align-items: center;

        .language-btn {
          margin: 5px 0;
        }
      }

      .proceed-button__wrapper {
        margin: 22px 0;
      }
    }
  }
}
