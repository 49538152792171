.timezone-notification__wrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  padding: 20px;
  width: 100%;
  max-width: 460px;
  background: linear-gradient(108.59deg, #f6f0fb 0%, #fefefe 100%), #f8f9fa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 12px 200px rgba(101, 105, 109, 0.5);
  border-radius: 24px;
  opacity: 0;

  &.visible {
    opacity: 1;
    z-index: 1000;
  }

  .notification__content {
    margin: 0 12px;

    .notification__title {
      font-weight: var(--font-weight-medium);
      font-size: 17px;
      line-height: 140%;
      letter-spacing: 0.0015em;
      color: #212121;
    }

    .notification__description {
      margin-top: 6px;
      font-weight: var(--font-weight-regular);
      font-size: 15px;
      line-height: 150%;
      color: #212121;
    }

    .notification__buttons {
      margin-top: 12px;
      display: flex;
      justify-content: space-around;

      .btn {
        width: 146px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #2e79da;
        border-radius: 8px;
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 150%;
        cursor: pointer;

        &.edit__btn {
          color: #fff;
          background-color: #2e79da;
        }

        &.keep__btn {
          color: #2e79da;
          background-color: #fff;
        }
      }
    }
  }

  .notification__close {
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    right: 0;
    padding: 20px 10px;

    .notification__content {
      margin: 0 6px;

      .notification__description,
      .notification__title {
        font-size: 14px;
      }

      .notification__buttons {
        .btn {
          font-size: 14px;
          width: 130px;
        }
      }
    }
  }
}
