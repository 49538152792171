@import "../../../../variables.scss";

section.matched-coach {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: var(--background);

  .decor-image-left {
    position: absolute;
    top: 345px;
    left: 0;
    @media screen and (max-width: 1299px) {
      display: none;
    }
  }

  .decor-image-right {
    position: absolute;
    top: 170px;
    right: 0;
    @media screen and (min-width: 1920px) {
      right: 110px;
    }

    @media screen and (max-width: 1299px) {
      display: none;
    }
  }

  .profile-info__container {
    margin: 65px auto 75px;
    max-width: 1200px;
    min-height: calc(
      100vh - var(--auth-footer-min-height) - var(--auth-header-min-height) -
        65px - 75px
    );

    .full-info {
      background-color: #faf4f0;
      .single-item,
      .company {
        background-color: #fff !important;
      }
    }

    .short-info {

      .back-to-all-coaches {
        width: 100px !important;
      }

      .coach-short-info {
        flex: 1 1 auto;
        width: auto !important;
      }

      .choose-coach {
        margin-right: 16px;
        width: 158px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 125%;
        background-color: var(--blue-3);
        color: #fff;
        border-radius: 56px;
        border: none;
        transition: all 0.2s ease-out;

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: var(--blue-3);
          border: 2px solid var(--blue-3);
        }
      }
    }
  }
}
