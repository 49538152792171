@media (max-width: 1200px) {
  .page-calendar {
    .rbc-month-view,
    .rbc-time-view {
      overflow-x: auto;
      .rbc-time-content {
        min-width: 1000px;
      }
    }
    .rbc-month-header,
    .rbc-month-row,
    .rbc-time-header {
      min-width: 1000px;
    }
    &.day__view {
      .rbc-month-view,
      .rbc-time-view {
        .rbc-time-content,
        .rbc-month-header,
        .rbc-month-row,
        .rbc-time-header {
          min-width: 100%;
        }
      }
    }
  }
}

@media (max-width: $tablet + 1px) {
  .page-calendar {
    margin-top: 0;
    .rbc-month-header,
    .rbc-month-row {
      min-width: 768px;
    }
    .rbc-toolbar {
      position: relative;
      top: 0;
      .rbc-btn-group,
      .rbc-toolbar-label {
        margin-bottom: 16px;
      }
      .rbc-toolbar-label {
        font-size: 20px;
      }
      .rbc-btn-group:last-child {
        margin-right: 0;
        width: 100%;
        button {
          width: 33.5%;
        }
      }
    }
    // filters
    .mobile {
      &__filters {
        display: inline-flex;
        position: relative;
        z-index: 9999;
      }
    }
    .filter_form {
      display: none;
      &.active {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        height: calc(100% + 32px);
        width: calc(100% + 32px);
        z-index: 9999;
        margin: -16px;
        padding: 30px 16px;
        .close_modal {
          display: block;
        }
      }
      form {
        flex-direction: column;
        .filter_item {
          max-width: 100%;
          margin-bottom: 20px;
        }
      }
      .mobile {
        &__filters-headers {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .calendar-event {
    &__time {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    &__time-date {
      margin-bottom: 13px;
    }
  }
  .select_checkboxes {
    .css-1wa3eu0-placeholder {
      font-size: 20px;
      padding: 0px;
    }
  }
}
