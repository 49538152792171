.empty-session {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  // for switch
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    left: 0px;
    bottom: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../../../../../../assets/vector/gradients/gradient-1.svg);
    opacity: 0.2;
  }

  &__icon {
    margin-bottom: 16px;
    z-index: 2;
    width: 100%;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--black);
  }

  &__subtext {
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: var(--grey-3);
  }

  &__add-btn {
    margin: 0;
  }
}
