.add-action {
  padding: 12px 12px 16px;
  border-radius: 12px;
  background-color: var(--light-gray);

  &__input {
    width: 100%;
    height: 80px;
    padding: 15px 24px;
    margin-bottom: 16px;
    background: var(--white);
    border: none;
    border-radius: 10px;
    font-size: 18px;
    color: var(--black);
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__add-date {
    border: none;
    background-color: transparent;
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 25px;
    color: var(--orange-2);
    cursor: pointer;
    transition: background-color 0.25s linear;

    &:focus {
      color: var(--orange-4);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--orange-4);
      }
    }
  }

  &__save {
  }
}
