.file-uploader {
  margin: 50px 0 25px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    margin-right: 16px;
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 24px;
    color: var(--black);
  }

  &__note {
    font-size: 15px;
    line-height: 22px;
    color: var(--grey-3);
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 31px 16px;
    background: var(--light-gray);
    border: 1px dashed var(--grey-5);
    border-radius: 12px;
    // for ILTB
    // color: var(--viridian);
    color: var(--blue-3);
    font-weight: var(--font-weight-medium);
    font-size: 17px;
    line-height: 24px;
    cursor: pointer;
    transition: border-color 0.25s;

    @media (hover: hover) {
      &:hover {
        border-color: var(--viridian);
      }
    }

    input[type='file'] {
      display: none;
    }
  }

  &__icon {
    margin-right: 16px;
    // for SWITCH
    path {
      fill: var(--blue-3);
    }
  }

  &__text {
    margin-right: 0;
  }

  .errors-messages {
    font-size: 15px;
    padding: 10px 20px;
    line-height: 150%;
    border-radius: 12px;
    font-weight: var(--font-weight-medium);
    letter-spacing: 0.015em;
    color: var(--stiletto);
    border: 2px solid var(--stiletto);
    margin: 30px 0;
    background: rgba(159, 60, 71, 0.2);
    transition: all ease-in 0.3s;
  }
}
