@import 'src/breakpoints';
.page-reports {
  display: flex;
  min-height: calc(100% - var(--header-height));
  background-color: var(--background);
  .page-programmes__empty {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - var(--header-height));
    text-align: center;

    // for switch
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      left: 0px;
      bottom: 0px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../../assets/vector/gradients/gradient-1.svg);
      opacity: 0.2;
    }
    .page-programmes__empty-info {
      max-width: 675px;
      margin: 0 auto;
      z-index: 1;
      .page-programmes__empty-img {
        margin: 0 0 50px;
        max-width: 445px;
      }
      .page-programmes__empty-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: 0.01em;
        color: #101821;
        margin: 0 0 32px;
        white-space: pre-line;
      }
      .page-programmes__empty-subtitle {
        font-weight: var(--font-weight-medium);
        font-size: 17px;
        line-height: 140%;
        letter-spacing: 0.0015em;
        color: #101821;
      }
    }
  }
}

@media (max-width: $tablet) {
  .page-reports {
    .page-programmes__empty {
      .page-programmes__empty-info {
        .page-programmes__empty-title {
          font-size: 30px;
        }
      }
    }
  }
}
