@import 'src/breakpoints';

.add-participants-to-chat {
  .modal-participant-list {
    background: #fff;
    border-radius: 12px;
    width: 675px;
    height: 70%;
    overflow: hidden;
    padding: 40px 45px 40px 0;

    .top-block {
      margin: 0 25px 24px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .title {
        flex: 1 1 auto;
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 125%;
        color: #101821;
      }

      .choose {
        font-weight: var(--font-weight-medium);
        font-size: 20px;
        line-height: 125%;

        color: #e75b32;
      }

      .close-modal {
        position: absolute;
        top: 50%;
        right: -30px;
        cursor: pointer;
        transform: translate(0, -50%);
      }
    }

    .participants-list-wrapper {
      display: flex;
      flex-direction: column;
      height: calc(90%) !important;

      .item {
        margin: 0 25px 8px 40px;
        transition: all 0.35s ease-out;
        background: #f5f5f5;
        border-radius: 8px;
        min-height: 88px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 32px 16px 24px;
        cursor: pointer;

        .participant-info {
          display: flex;
          align-items: center;
          //height: 88px;

          .avatar {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            object-fit: cover;
          }

          .user-name {
            font-size: 18px;
            line-height: 130%;
            margin: 0 0 0 12px;
            color: #101821;
            transition: all 0.35s ease-out;
          }
        }

        .add-participants-modal {
          svg {
            path {
              fill: #929292;
            }
          }
        }

        &:hover {
          background: #fff;
          box-shadow: 0 4px 24px rgba(168, 158, 151, 0.4);
          border-radius: 8px;

          .participant-info {
            .user-name {
              color: #e75b32;
            }
          }

          svg {
            path {
              fill: #e75b32;
            }
          }
        }
      }

      .no-searched-users {
        margin-top: 30px;
        text-align: center;
        font-size: 18px;
        line-height: 130%;
      }
    }
  }
}

@media (max-width: $tablet) {
  .add-participants-to-chat {
    .modal-participant-list {
      height: 85%;
      padding: 40px 16px 40px 0;

      .top-block {
        margin: 10px 0 32px 16px;

        .choose {
          display: none;
        }

        .close-modal {
          top: -25px;
          right: 0;
        }
      }

      .participants-list-wrapper {
        .item {
          margin: 0 0 4px 16px;
          min-height: 72px;
          padding: 8px 16px;
        }

        .no-searched-users {
          padding-left: 16px;
        }
      }
    }
  }
}
