@import 'src/breakpoints';

.single-programme {
  padding: 24px 16px;
  z-index: 1;
  position: relative;

  @media (min-width: $tablet-4) {
    height: calc(100vh - 85px);
    padding: 28px 16px 0;
    overflow: hidden;
  }

  @media (min-width: $desktop-1) {
    height: calc(100vh - 92px);
    padding: 28px 40px 0 25px;
  }

  &__head {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 12px;

    @media (min-width: $full) {
      height: 80px;
      margin-bottom: 24px;
    }
  }

  &__user {
    display: flex;
    text-decoration: none;
  }

  &__user-info {
    margin-top: 8px;
    font-weight: var(--font-weight-medium);
    margin-right: 12px;
    color: var(--black);
    font-size: 18px;
    line-height: 1.35;

    @media (min-width: $full) {
      font-size: 22px;
    }
  }

  &__user-role {
    display: block;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    text-align: right;
    line-height: 1.3;
    color: var(--grey-3);
  }

  &__user-img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 40px;
  }

  &__go-back {
    display: inline-flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: var(--font-weight-medium);
    font-size: 24px;
    line-height: 30px;
    color: var(--black);
    letter-spacing: 0.015em;
    text-transform: capitalize;
    cursor: pointer;

    @media (min-width: $tablet-4) {
      font-size: 24px;
      line-height: 40px;
    }

    .go-back-icon {
      margin-right: 16px;
      width: 24px;
    }
  }
}
