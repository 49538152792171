*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;

  &.lock {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

input,
button,
select {
  font: inherit;
  outline: none;
}

button.reset-style {
  font: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
}

.main-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;

  &--menu-open {
    .header {
      background: var(--blue-3);
      border-bottom: none;
    }
    .header-left,
    .header-right {
      display: none;
    }
    .menu-toggle {
      &__line {
        position: relative;
        &:nth-child(1) {
          top: 4px;
          transform: rotate(45deg);
          transition: 0.7s all ease;
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          top: -4px;
          transform: rotate(-45deg);
          transition: 0.7s all ease;
        }
      }
    }
    @media (max-width: 919px) {
      overflow: hidden;
      height: calc(100vh);
    }
  }
}

a.reset-style {
  text-decoration: none;
  color: inherit;

  &:active {
    color: inherit;
  }
}

////common styles for elements
a.component-link-button {
  background-color: var(--switch-select-bg-active);
  color: var(--text-on-color);
  box-shadow: 0 0 0 var(--button-primary-shadow),
    0 0 0 var(--button-primary-shadow);
  font-weight: var(--font-weight-medium);
  font-size: 14px;
  line-height: 24px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  --base-padding-horizontal: 16px;
  --base-padding-vertical: 8px;
  padding: var(--base-padding-vertical) var(--base-padding-horizontal);
  transition: all 300ms;
  position: relative;

  &:hover {
    border-radius: 100px;
    box-shadow: 0 3px 5px var(--button-primary-shadow),
      0 7px 15px var(--button-primary-shadow);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.hidden-text-input {
  display: none;
}

.center-errror-msg {
  text-align: center;
}

.hidden-submit {
  display: none !important;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.aspect-sizer {
  position: relative;
  padding-top: var(--aspect);

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.date-picker-kit {
  &__popper {
    &.react-datepicker-popper {
      z-index: 50;
    }
  }

  &__calendar {
    &.react-datepicker {
      font-family: Poppins, sans-serif;
      border: 1px solid var(--grey-5);
      border-radius: 16px;
      color: var(--black);
    }

    .react-datepicker__day {
      border-radius: 35px;
      font-weight: var(--font-weight-medium);

      &:hover,
      &:focus {
        border-radius: 35px;
      }

      &--selected {
        font-weight: var(--font-weight-medium);
        background-color: var(--blue-3);
      }

      &--disabled {
        color: var(--grey-baba);
      }
    }

    .react-datepicker__month {
      margin: 20px 4px;
      font-size: 14px;
    }

    .react-datepicker__day-names {
      font-size: 15px;
    }

    .react-datepicker__current-month {
      font-size: 20px;
      line-height: 24px;
    }

    .react-datepicker__header {
      background-color: transparent;
      border-bottom: 1px solid var(--grey-5);
      padding: 16px 0 8px;
    }

    .react-datepicker__navigation {
      top: 17px;
    }
  }
}
