@import "../../../../variables.scss";

section.coach-matching {
  padding: 10px 0 80px 0;
  position: relative;
  width: 100%;
  background-color: var(--background);
  min-height: calc(
    100vh - var(--auth-footer-min-height) - var(--auth-header-min-height) - 80px
  );
  // background-image: url(../../../../assets/vector/gradients/gradient-3.svg);
  // background-repeat: no-repeat;
  // background-position: center;


  .decor-image-left {
    position: absolute;
    top: 345px;
    left: 0;
    @media screen and (max-width: 1299px) {
      display: none;
    }
  }

  .decor-image-right {
    position: absolute;
    top: 170px;
    right: 0;
    @media screen and (min-width: 1920px) {
      right: 110px;
    }

    @media screen and (max-width: 1299px) {
      display: none;
    }
  }

  .matched-coaches__wrapper {
    max-width: 90%;
    margin: 0 auto;

    @media screen and (min-width: 1280px) {
      max-width: 1110px;
    }
  }
}
