@import 'src/breakpoints';

.page-coaches {
  display: flex;
  justify-content: space-between;

  .coaches-filters {
    width: 300px;
    margin: 0 20px 0 0;

    .filter-item {
      .filter-group-title {
        font-weight: var(--font-weight-regular);
        font-size: 14px;
        line-height: 150%;
        color: var(--grey-3);
        margin: 0 0 4px 0;
      }
      margin: 0 0 20px;
    }

    .clear-all-filters {
      .clear-btn {
        font-size: 15px;
        line-height: 125%;
        color: var(--white);
        background: transparent;
        //border: 0;
        width: 160px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        // for ILTB
        // background: var(--orange-2);
        // border: 2px solid var(--orange-2);
        background: var(--gen_button_color);
        border: 2px solid var(--gen_button_color);
        border-radius: 8px;
        font-weight: var(--font-weight-medium);
        transition: all 0.2s ease-out;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: default;

          // &:hover {
          //   // for ILTB
          //   // background: var(--orange-2);
          //   background: var(--blue-3);
          //   color: var(--white);
          // }
        }

        // &:hover {
        //   // for ILTB
        //   // color: var(--orange-2);
        //   color: var(--blue-3);
        //   background: var(--white);
        // }
      }
    }

    .apply-filters {
      display: none;
    }

    .mobile-filter-header {
      display: none;
    }
  }

  .coaches-list {
    width: calc(100% - 300px);
    position: relative;

    .coaches-filter-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;

      h2 {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: var(--gen_font_color);
      }

      .search {
        width: 325px;
        position: relative;

        input {
          display: block;
          padding: 9px 24px 9px 48px;
          width: 100%;
          line-height: 24px;
          color: var(--grey-3);
          font-weight: var(--font-weight-regular);
          font-size: 14px;
          appearance: none;
          background: var(--white);
          border: 1px solid var(--purple-light);
          box-sizing: border-box;
          border-radius: 6px;
          color: #101821;

          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 15px;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 15px;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 15px;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 15px;
          }

          &:hover {
            border-color: #2e79da;
            border-width: 2px;
            padding: 11px 23px 11px 47px;
            cursor: pointer;
          }

          &:focus {
            border-color: #2e79da;
            border-width: 2px;
            padding: 11px 23px 11px 47px;
            &::placeholder {
              color: transparent;
            }
          }
        }

        .icon {
          position: absolute;
          left: 18px;
          top: calc(50% - 10px);
          pointer-events: none;
          &.active {
            svg {
              path {
                fill: #2e79da;
              }
            }
          }
        }

        .filter-toggle-block {
          display: none;
        }
      }
    }

    .list {
      margin: 0;
      height: calc(100vh);
      overflow-y: scroll;
      padding: 16px 30px;

      .link__wrapper {
        text-decoration: none;
        .item {
          background: var(--white);
          box-shadow: 0 6px 12px rgba(209, 207, 205, 0.4);
          border-radius: 12px;
          padding: 24px;
          display: flex;
          align-items: center;
          margin: 0 0 16px;
          position: relative;

          .coach-avatar {
            margin: 0 24px 0 0;
            width: 120px;

            img {
              border-radius: 12px;
              width: 120px;
              height: 120px;
              object-fit: cover;
            }
          }

          .coach-short-info {
            padding: 0 20px 0 0;
            width: calc(100% - 350px);
            display: flex;
            flex: 1 0 auto;
            justify-content: center;
            flex-direction: column;

            .name {
              font-weight: var(--font-weight-medium);
              font-size: 18px;
              line-height: 125%;
              letter-spacing: 0.015em;
              color: var(--gen_font_color);
              margin: 0 0 8px;
              text-decoration: none;
              max-width: max-content;
            }

            .country {
              font-weight: var(--font-weight-regular);
              font-size: 14px;
              line-height: 130%;
              margin: 0 0 16px;
              color: var(--grey-3);
            }

            .coach-experience {
              display: flex;
              flex-wrap: wrap;
              padding: 0;
              margin: 0 0 0 17px;

              li {
                font-weight: var(--font-weight-regular);
                font-size: 16px;
                line-height: 150%;
                color: var(--gen_font_color);
                margin: 0 33px 0 0;

                &::marker {
                  color: var(--yellow-3);
                }
              }
            }
          }

          .coach-full-info {
            width: 165px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            .view-profile {
              text-decoration: none;
              font-weight: 700;
              font-size: 14px;
              line-height: 125%;
              transition: all 0.2s ease-out;
              letter-spacing: 0.01em;

              // for ILTB
              // border: 2px solid var(--orange-2);
              // color: var(--orange-2);
              border: 2px solid var(--gen_button_color);
              color: var(--gen_button_color);
              border-radius: 8px;
              padding: 11px 33px;

              &:hover {
                // for ILTB
                // background: var(--orange-2);
                background: var(--gen_button_color);
                color: var(--white);
              }
            }
          }
          &:hover {
            box-shadow: 0px 16px 32px rgba(210, 217, 225, 0.4);
            transition: top 0.5s ease 0s; /* only transition top property */
            top: -2px;

            .coach-short-info {
              .name {
                color: var(--blue-3);
              }
            }
          }
        }
      }
    }

    .empty-list {
      margin: 70px 0 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .searched-value {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 125%;
        margin: 24px 0;
        color: #101821;

        .text {
          color: var(--blue-3);
        }
      }

      .clear-search-field {
        cursor: pointer;
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 150%;
        text-decoration: underline;
        color: var(--blue-3);
      }
    }
  }

  @media (max-width: $tablet) {
    .coaches-filters {
      opacity: 0;
      visibility: hidden;
      width: 100%;
      margin: 0 20px 0 0;
      background: #fff;
      position: fixed;
      top: 0;
      height: 100%;
      left: 0;
      z-index: 5;
      padding: 40px 16px;
      transition: all 0.3s ease-out;
      overflow: scroll;

      &.open-filter {
        opacity: 1;
        visibility: visible;
      }

      .mobile-filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 30px;

        .filter-title {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          color: var(--gen_font_color);
        }
      }

      .apply-filters {
        display: flex;
        margin: 40px 0 0 0;

        .apply-filters-btn {
          font-size: 15px;
          line-height: 125%;
          color: var(--white);
          background: transparent;
          //border: 0;
          width: 160px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--orange-2);
          border-radius: 56px;
          font-weight: var(--font-weight-medium);
          transition: all 0.2s ease-out;
          cursor: pointer;
          border: 2px solid var(--orange-2);

          &:disabled {
            opacity: 0.5;
            cursor: default;

            &:hover {
              background: var(--orange-2);
              color: var(--white);
            }
          }

          &:hover {
            background: var(--white);
            color: var(--orange-2);
          }
        }
      }
    }

    .coaches-list {
      width: 100%;

      .coaches-filter-search {
        align-items: flex-start;
        flex-direction: column;
        padding: 0;

        h2 {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: var(--gen_font_color);
        }

        .search {
          margin: 24px 0 0;
          width: 100%;
          display: flex;

          .filter-toggle-block {
            display: flex;
            margin: 0 0 0 16px;
          }
        }
      }

      .empty-list {
        .search-is-nothing-found {
          width: 100%;
        }
      }

      .list {
        margin: 32px -15px;
        padding: 0 15px;

        .item {
          flex-wrap: wrap;
          align-items: flex-start;

          .coach-avatar {
            margin: 0 16px 0 0;
            width: 60px;

            img {
              width: 64px;
              height: 64px;
            }
          }

          .coach-short-info {
            padding: 0;
            width: calc(100% - 76px);
            flex-direction: column;
            display: flex;
            justify-content: center;

            .name {
              font-size: 22px;
              line-height: 130%;
              margin: 0 0 4px;
            }

            .program-in-progress {
              font-size: 13px;
              line-height: 160%;
            }

            .rate-information {
              .counter {
                .chart {
                  width: 120px;
                  height: 8px;
                  background: #e0e0e0;
                  border-radius: 4px;
                  position: relative;
                  margin: 0 0 0 20px;
                }
              }
            }

            .participant-block-wrapper {
              .participant-left-block {
                margin: 0 0 20px;
                flex-direction: column-reverse;
                display: flex;

                .country {
                  margin: 0;
                }

                .program-in-progress {
                  margin: 0 0 4px;
                }
              }

              .participant-right-block {
                margin: 0 0 0 -74px;

                .sessions {
                  .title {
                    font-size: 13px;
                  }

                  .counter {
                    font-size: 13px;
                  }
                }

                .rate-information {
                  .title {
                    font-size: 13px;
                  }

                  .counter {
                    .total-success {
                      font-size: 15px;
                      line-height: 150%;
                    }
                  }
                }
              }
            }
          }

          .coach-full-info {
            width: 100%;
            //display: flex;
            //flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 24px 0 0 0;
          }
        }
      }
    }
  }

  @media (max-width: $desktop-3) {
    .coaches-list {
      .list {
        .item {
          .participant-avatar {
            width: 110px;

            img {
              width: 110px;
              height: 110px;
            }
          }

          .participant-short-info {
            .rate-information {
              flex-direction: column-reverse;
            }

            .participant-block-wrapper {
              .participant-left-block {
                margin: 0;
              }
            }

            .sessions {
              flex-direction: column-reverse;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    margin: 0 -24px;

    .coaches-filters {
      opacity: 0;
      visibility: hidden;
      width: 100%;
      margin: 0 20px 0 0;
      background: #fff;
      position: fixed;
      top: 0;
      height: 100%;
      left: 0;
      z-index: 5;
      padding: 40px 16px;
      transition: all 0.3s ease-out;
      overflow: scroll;

      &.open-filter {
        opacity: 1;
        visibility: visible;
      }

      .mobile-filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 30px;

        .filter-title {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          color: var(--gen_font_color);
        }
      }

      .apply-filters {
        display: flex;
        margin: 40px 0 0 0;

        .apply-filters-btn {
          font-size: 15px;
          line-height: 125%;
          color: var(--white);
          background: transparent;
          //border: 0;
          width: 160px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--orange-2);
          border-radius: 56px;
          font-weight: var(--font-weight-medium);
          transition: all 0.2s ease-out;
          cursor: pointer;
          border: 2px solid var(--orange-2);

          &:disabled {
            opacity: 0.5;
            cursor: default;

            &:hover {
              background: var(--orange-2);
              color: var(--white);
            }
          }

          &:hover {
            background: var(--white);
            color: var(--orange-2);
          }
        }
      }
    }

    .coaches-list {
      width: 100%;

      .coaches-filter-search {
        align-items: flex-start;
        flex-direction: column;

        h2 {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: var(--gen_font_color);
        }

        .search {
          margin: 24px 0 0;
          width: 100%;
          display: flex;
          justify-content: space-between;

          input {
            width: 50%;
          }

          .filter-toggle-block {
            display: flex;
            margin: 0 0 0 16px;
          }
        }
      }

      .list {
        .item {
          .participant-avatar {
            margin: 0 16px 0 0;
            width: 64px;

            img {
              width: 64px;
              height: 64px;
            }
          }

          .participant-short-info {
            padding: 0 20px 0 0;
            width: calc(70% - 80px);
            flex-direction: column;
            display: flex;
            justify-content: center;

            .name {
              font-weight: var(--font-weight-medium);
              font-size: 32px;
              line-height: 125%;
              letter-spacing: 0.015em;
              color: var(--gen_font_color);
              margin: 0 0 8px;
            }

            .country {
              font-weight: var(--font-weight-regular);
              font-size: 17px;
              line-height: 130%;
              color: var(--grey-3);
            }

            .program-in-progress {
              font-weight: var(--font-weight-regular);
              font-size: 17px;
              line-height: 130%;
              margin: 0 0 4px;
              color: var(--gen_font_color);
            }

            .sessions {
              display: flex;
              margin: 0 0 13px;

              .title {
                font-weight: var(--font-weight-regular);
                font-size: 17px;
                line-height: 130%;
                color: var(--grey-3);
                width: 135px;
              }

              .counter {
                font-weight: var(--font-weight-medium);
                font-size: 17px;
                line-height: 140%;
                letter-spacing: 0.0015em;

                .from {
                  color: var(--gen_font_color);
                }

                .to {
                  color: var(--grey-3);
                }
              }
            }

            .rate-information {
              display: flex;

              .title {
                font-weight: var(--font-weight-regular);
                font-size: 17px;
                line-height: 130%;
                color: var(--grey-3);
                width: 135px;
              }

              .counter {
                display: flex;
                align-items: center;
                justify-content: center;

                .total-success {
                  font-weight: var(--font-weight-medium);
                  font-size: 17px;
                  line-height: 140%;
                  letter-spacing: 0.0015em;
                  color: var(--gen_font_color);
                }

                .chart {
                  width: 170px;
                  height: 8px;
                  background: #e0e0e0;
                  border-radius: 4px;
                  position: relative;
                  margin: 0 0 0 20px;

                  .success-chart {
                    position: absolute;
                    left: 0;
                    background: #366b65;
                    border-radius: 4px;
                    height: 8px;
                  }
                }
              }
            }

            .participant-block-wrapper {
              display: flex;
              //flex-wrap: wrap;

              .participant-left-block {
                width: 100%;
                margin: 0;
              }

              .participant-right-block {
                width: 50%;
              }
            }
          }

          .participant-another-info {
            width: 25%;

            .participant-right-block {
              display: flex;
              //flex-wrap: wrap;
              //width: 100%;
              flex-direction: column;

              .sessions {
                width: 100%;
                color: #929292;
                margin: 0 0 12px;

                .title {
                  font-size: 13px;
                }

                .counter {
                  font-size: 15px;

                  .from {
                    color: #101821;
                  }
                }
              }

              .rate-information {
                width: 100%;
                color: #929292;
                padding: 0 10px 0 0;

                .title {
                  font-size: 13px;
                }

                .counter {
                  display: flex;
                  align-items: center;

                  .total-success {
                    font-size: 15px;
                    line-height: 150%;
                    color: #101821;
                  }

                  .chart {
                    width: 100%;
                    height: 8px;
                    background: #e0e0e0;
                    border-radius: 4px;
                    position: relative;
                    margin: 0 0 0 12px;

                    .success-chart {
                      position: absolute;
                      left: 0;
                      background: #366b65;
                      border-radius: 4px;
                      height: 8px;
                    }
                  }
                }
              }
            }
          }

          .participant-full-info {
            width: 25%;
            align-items: center;

            .view-profile {
              text-decoration: none;
              font-weight: 700;
              font-size: 14px;
              line-height: 125%;
              transition: all 0.2s ease-out;
              letter-spacing: 0.01em;

              // for ILTB
              // border: 2px solid var(--orange-2);
              // color: var(--orange-2);
              border: 2px solid var(--gen_button_color);
              border-radius: 56px;
              color: var(--gen_button_color);
              padding: 11px 33px;

              &:hover {
                // background: var(--orange-2);
                background: var(--gen_button_color);
                color: var(--white);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet-4 - 1px) {
    margin: 0;

    .coaches-list {
      width: 100%;

      .coaches-filter-search {
        align-items: flex-start;
        flex-direction: column;
        padding: 0;

        h2 {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: var(--gen_font_color);
        }

        .search {
          margin: 24px 0 0;
          width: 100%;
          display: flex;
          justify-content: space-between;

          input {
            width: 70%;
          }

          .filter-toggle-block {
            display: flex;
            margin: 0 0 0 16px;
          }
        }
      }

      .empty-list {
        .search-is-nothing-found {
          width: 100%;
        }
      }

      .list {
        margin: 32px -15px 0;
        padding: 0 15px;

        .item {
          flex-wrap: wrap;
          align-items: flex-start;

          .participant-avatar {
            margin: 0 16px 0 0;
            width: 60px;

            img {
              width: 64px;
              height: 64px;
            }
          }

          .participant-short-info {
            padding: 0;
            width: calc(100% - 76px);
            flex-direction: column;
            display: flex;
            justify-content: center;

            .name {
              font-size: 22px;
              line-height: 130%;
              margin: 0 0 4px;
              color: var(--gen_font_color);
            }

            .program-in-progress {
              font-size: 13px;
              line-height: 160%;
            }

            .rate-information {
              .counter {
                .chart {
                  width: 120px;
                  height: 8px;
                  background: #e0e0e0;
                  border-radius: 4px;
                  position: relative;
                  margin: 0 0 0 20px;
                }
              }
            }

            .participant-block-wrapper {
              .participant-left-block {
                margin: 0 0 20px;
                width: 100%;

                .country {
                  margin: 0;
                }

                .program-in-progress {
                  margin: 0 0 4px;
                }
              }
            }
          }

          .participant-another-info {
            width: 100%;

            .participant-right-block {
              display: flex;
              flex-wrap: wrap;
              width: 100%;

              .sessions {
                width: 100%;
                color: #929292;

                .title {
                  font-size: 13px;
                }

                .counter {
                  font-size: 15px;

                  .from {
                    color: #101821;
                  }
                }
              }

              .rate-information {
                width: 100%;
                color: #929292;

                .title {
                  font-size: 13px;
                }

                .counter {
                  display: flex;
                  align-items: center;

                  .total-success {
                    font-size: 15px;
                    line-height: 150%;
                    color: #101821;
                  }

                  .chart {
                    width: 100%;
                    height: 8px;
                    background: #e0e0e0;
                    border-radius: 4px;
                    position: relative;
                    margin: 0 0 0 12px;

                    .success-chart {
                      position: absolute;
                      left: 0;
                      background: #366b65;
                      border-radius: 4px;
                      height: 8px;
                    }
                  }
                }
              }
            }
          }

          .participant-full-info {
            width: 100%;
            //display: flex;
            //flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 24px 0 0 0;
          }
        }
      }
    }
  }

  @media (max-width: $tablet-2) {
    .coaches-list {
      width: 100%;

      .coaches-filter-search {
        align-items: flex-start;
        flex-direction: column;
        padding: 0;

        h2 {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: var(--gen_font_color);
        }

        .search {
          margin: 24px 0 0;
          width: 100%;
          display: flex;

          input {
            width: 100%;
          }

          .filter-toggle-block {
            display: flex;
            margin: 0 0 0 16px;
          }
        }
      }
    }
  }
}

.coach-profile {
  @media (min-width: $full) {
    margin: 0 10%;
  }

  .short-info {
    display: flex;
    background: var(--white);
    border-radius: 12px;
    padding: 48px 24px 48px 24px;
    align-items: center;
    margin: 0 0 40px;

    @media screen and (min-width: $desktop-2) {
      padding: 48px 56px 48px 24px;
    }

    @media screen and (max-width: $tablet-4) {
      flex-wrap: wrap;
    }

    .back-to-all-coaches {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: $tablet-1) {
        width: 130px;
      }

      .link-to-page {
        display: flex;
      }
    }

    .coach-avatar {
      width: 110px;
      display: flex;

      @media (min-width: $tablet-1) {
        margin: 0 24px 0 0;
      }

      img {
        border-radius: 12px;
        width: 110px;
        height: 110px;
        object-fit: cover;
      }
    }

    .coach-short-info {
      flex: 1 1 auto;
      @media (max-width: $tablet) {
        text-align: center;
      }

      .name {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: var(--gen_font_color);
        margin: 23px 0 8px;
      }

      .country {
        font-weight: var(--font-weight-regular);
        font-size: 16px;
        line-height: 130%;
        margin: 0 0 16px;
        color: var(--grey-3);
      }
    }

    .buttons__wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @media screen and (min-width: 500px) {
        flex-direction: row;
        width: 100%;
      }

      @media screen and (min-width: $tablet-4) {
        flex-direction: column;
        margin-left: auto;
        margin-top: 0;
        width: auto;
      }

      .choose-coach__btn {
        width: 158px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 125%;
        background-color: var(--blue-3);
        color: #fff;
        border-radius: 8px;
        border: none;
        transition: all 0.2s ease-out;
        margin-bottom: 10px;

        @media screen and (min-width: 500px) {
          margin-bottom: 0;
          margin-right: 10px;
        }

        @media screen and (min-width: $tablet-2) {
          margin-bottom: 10px;
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: var(--blue-3);
          border: 2px solid var(--blue-3);
        }

        // @media screen and (min-width: $tablet-4) {
        //   margin-bottom: 0;
        // }
      }

      .coach-full-info {
        .view-profile {
          text-decoration: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 125%;
          transition: all 0.2s ease-out;
          letter-spacing: 0.01em;
          // border: 2px solid var(--orange-2);
          // color: var(--orange-2);
          border: 2px solid var(--gen_button_color);
          border-radius: 8px;
          height: 44px;
          color: var(--gen_button_color);
          display: flex;
          align-items: center;
          width: 158px;
          justify-content: center;

          &:hover {
            // background: var(--orange-2);
            background: var(--gen_button_color);
            color: var(--white);
          }
        }
      }
    }
  }

  .full-info {
    background: var(--white);
    border-radius: 12px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $tablet-4) {
      padding: 64px 142px;
    }

    .section-info-block {
      margin: 0 0 64px;

      .summary-description {
        font-weight: var(--font-weight-regular);
        font-size: 17px;
        line-height: 130%;
        color: var(--gen_font_color);

        .show-more {
          font-weight: var(--font-weight-medium);
          font-size: 17px;
          line-height: 140%;
          display: flex;
          align-items: center;
          letter-spacing: 0.0015em;
          color: var(--green);
          //text-decoration: none;
          margin: 20px 0 0;
        }
      }

      .block-title {
        font-weight: var(--font-weight-medium);
        font-size: 20px;
        line-height: 125%;
        color: var(--gen_font_color);
        margin: 0 0 20px;

        @media screen and (min-width: $full) {
          font-size: 24px;
        }
      }

      .block-items {
        display: flex;
        flex-wrap: wrap;

        .single-item {
          padding: 8px 20px;
          background: rgba(253, 201, 76, 0.3);
          color: #6a541f;
          border-radius: 24px;
          margin: 0 12px 12px 0;
          font-weight: var(--font-weight-medium);
          font-size: 14px;
          line-height: 150%;

          @media screen and (min-width: $full) {
            padding: 12px 24px;
            font-size: 15px;
          }
        }
      }

      .work-experience-block {
        .experience-item {
          margin: 0 0 20px;
          display: flex;
          flex-wrap: wrap;

          .experience-item-left {
            width: 60%;
          }

          .experience-item-right {
            width: 40%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          .position {
            font-weight: var(--font-weight-medium);
            font-size: 17px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            color: var(--gen_font_color);
            margin: 0 0 8px;
          }

          .company {
            font-weight: var(--font-weight-medium);
            font-size: 14px;
            line-height: 150%;
            padding: 8px 20px;
            background: rgba(253, 201, 76, 0.3);
            color: #6a541f;
            border-radius: 24px;
            display: inline-flex;

            @media screen and (min-width: $full) {
              padding: 12px 24px;
              font-size: 15px;
            }
          }

          .date {
            font-weight: var(--font-weight-regular);
            font-size: 17px;
            line-height: 130%;
            color: #929292;
            margin: 0 0 10px;
          }

          .job-responsibilities {
            font-weight: var(--font-weight-regular);
            font-size: 17px;
            line-height: 130%;
            color: var(--gen_font_color);
          }
        }
      }

      .coaching-experience {
        .coaching-experience-item {
          display: flex;
          align-items: center;
          margin: 8px 0 18px;

          .icon-bullet {
            margin: 0 20px 0 0;
          }

          .coaching-information-label {
            font-weight: var(--font-weight-regular);
            font-size: 17px;
            line-height: 130%;
            color: var(--gen_font_color);
            margin: 0 20px 0 0;
          }

          .coaching-information-value {
            font-weight: var(--font-weight-medium);
            font-size: 17px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            color: var(--gen_font_color);
          }
        }
      }

      .languages-block {
        .language {
          display: flex;
          align-items: center;
          margin: 0 0 20px;

          .flag {
            width: 25px;
            height: 16px;
            margin: 0 20px 0 0;
            object-fit: cover;
            border-radius: 4px;
          }

          .language {
            font-weight: var(--font-weight-medium);
            font-size: 17px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            margin: 0 20px 0 0;
            color: var(--gen_font_color);
          }

          .experience {
            font-weight: var(--font-weight-regular);
            font-size: 17px;
            line-height: 130%;
            color: var(--gen_font_color);
          }
        }
      }

      .publications-block {
        .publications-item {
          margin: 0 0 30px;

          .item-title {
            font-weight: var(--font-weight-medium);
            font-size: 24px;
            line-height: 125%;
            margin: 0 0 12px;
            color: var(--gen_font_color);
          }

          .item-year {
            font-weight: var(--font-weight-medium);
            font-size: 17px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            margin: 0 0 12px;
            color: var(--gen_font_color);
          }

          .item-author {
            font-weight: normal;
            font-size: 17px;
            line-height: 130%;
            margin: 0 0 12px;
            color: #929292;
          }

          .item-short-description {
            font-weight: var(--font-weight-regular);
            font-size: 17px;
            line-height: 130%;
            color: var(--gen_font_color);
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}
