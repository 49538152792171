.edit-schedule {
  padding: 5px 24px;
  height: 44px;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: 21px;
  background-color: #ffffff;
  border: 2px solid var(--gen_button_color);
  color: var(--gen_button_color);
}
