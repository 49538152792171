.massing-feedback-from-notification {
  margin: 6px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 12px 24px rgba(209, 207, 205, 0.4);
  border-radius: 12px;
  background: url('../../../assets/vector/suggested-coaches-notification/bg-image.svg')
    #2e79da top right no-repeat;

  .notification__description {
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
    color: #ffffff;
  }

  .notification__link {
    margin-top: 16px;
    width: 170px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    color: #342f52;
    border-radius: 100px;
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    font-size: 13px;
    line-height: 160%;
    cursor: pointer;
  }
}
