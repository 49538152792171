@import 'src/breakpoints';

.reports-item {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 12px;
  margin-bottom: 8px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  background-color: var(--white);
  text-decoration: none;

  &__cell {
    color: var(--gen_font_color);
  }

  @media (min-width: $reports-table-s) {
    display: table-row;
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;

    .reports-sorting + & {
      .reports-item__cell {
        padding-top: 16px;
      }
    }

    &:last-child {
      .reports-item__cell {
        padding-bottom: 16px;
      }
    }
  }

  @media (min-width: $reports-table-s) {
    font-size: 13px;
  }

  @media (min-width: $full) {
    font-size: 14px;
  }

  &--active {
    @media (min-width: $reports-table-s) {
      background-color: var(--light-gray);
    }
    .reports-item__cell {
      background-color: var(--light-gray);

      &--tooltip {
        color: var(--blue);
      }
    }
  }

  &--info-shown {
    .reports-item__cell:not(.reports-item__cell--user):not(.reports-item__cell--num):not(.reports-item__cell--tooltip) {
      display: block;
    }

    .reports-item__open {
      transform: rotate(180deg);
    }
  }

  &__inner-row {
    width: 100%;
    display: block;

    @media (min-width: $reports-table-s) {
      display: table-row;
    }
  }

  &__inner-col {
    width: 100%;
    display: block;

    @media (min-width: $reports-table-s) {
      display: table-cell;
    }
  }

  &__cell {
    display: none;
    width: 100%;
    margin-bottom: 12px;

    @media (max-width: 460px) {
      &--survey,
      &--coach,
      &--sessions {
        width: 48%;
      }
    }

    @media (min-width: 460px) {
      width: 48%;
      flex-grow: 1;
    }

    @media (min-width: 620px) {
      width: 33%;
      flex-grow: 0;
    }

    @media (min-width: $reports-table-s) {
      display: table-cell;
      max-width: 160px;
      padding: 8px;
      width: auto;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
    }

    &--checkbox {
      .checkbox__item {
        display: none;
        @media (min-width: $reports-table-s) {
          display: flex;
        }
      }
    }

    &--num {
      display: none;
      color: var(--grey-3);

      @media (min-width: $reports-table-s) {
        display: table-cell;
        max-width: 70px;
        padding: 8px 8px 8px;
        text-align: center;
      }
    }

    &--user {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 100%;
      margin-bottom: 16px;

      .checkbox__item {
        margin-right: 12px;
      }

      @media (min-width: $reports-table-s) {
        display: table-cell;
        width: auto;
        max-width: 220px;
        margin-bottom: 0;

        .checkbox__item {
          display: none;
          margin-right: 0;
        }
      }
    }

    &--details {
      width: 100%;

      @media (min-width: $reports-table-s) {
        width: auto;
        text-align: center !important;
      }
    }

    &--program {
      display: none;
      width: 100%;
      margin-bottom: 12px;

      @media (min-width: $reports-table-s) {
        display: table-cell;
        width: auto;
        margin: 0;
      }
    }

    &--submit {
      display: none;

      @media (min-width: $report-table-m) {
        display: table-cell;
        text-align: center;
      }
    }

    &--topic-num {
      display: none;

      @media (min-width: $report-table-m) {
        display: table-cell;
        text-align: center;
      }
    }

    &--topic-name {
      display: none;

      @media (min-width: $report-table-m) {
        display: table-cell;
      }
    }

    &--session-name {
      display: none;

      @media (min-width: $report-table-m) {
        display: table-cell;
      }
    }

    &--coach-name {
      display: none;

      @media (min-width: 1600px) {
        display: table-cell;
      }
    }

    &--submission-date {
      @media (min-width: $reports-table-s) {
        text-align: center;
      }
    }

    &--rating {
      width: 100%;

      @media (min-width: $reports-table-s) {
        padding: 8px;
      }

      @media (min-width: $report-table-m) {
        padding: 18px 24px 18px 8px;
      }

      .average-mark {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .star-rating {
        display: none;

        @media (min-width: $reports-table-s) {
          display: inline-flex;
          margin-right: 16px;
        }
      }
    }

    &--details {
      @media (min-width: $reports-table-s) {
        padding: 8px;
      }

      @media (min-width: $report-table-m) {
        padding: 18px 24px 18px 8px;
      }
    }

    &--tooltip {
      display: none;

      @media (min-width: $reports-table-s) {
        display: table-cell;
        min-width: 50px;
        padding: 8px 24px 8px 8px;
        font-size: 50px;
        color: var(--grey-3);
        text-overflow: unset;
        cursor: pointer;
      }

      @media (min-width: $report-table-m) {
        display: none;
      }
    }
  }

  &__user-img {
    width: 36px;
    height: 36px;
    margin: 0 12px 0 0;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 12px;

    @media (min-width: $reports-table-s) {
      vertical-align: middle;
    }

    @media (min-width: $full) {
      width: 48px;
      height: 48px;
    }
  }

  &__name {
    display: block;
    margin: 0 16px 4px 0;
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    font-size: 12px;
    line-height: 140%;
    color: var(--gen_font_color);

    @media (min-width: $reports-table-s) {
      display: inline;
      margin: 0;
      font-weight: var(--font-weight-medium);

      line-height: 150%;
      overflow: hidden;
      text-overflow: ellipsis;

      .star-rating {
        display: none;
      }
    }

    @media screen and (min-width: $full) {
      font-size: 17px;
    }
  }

  &__cell-name {
    display: block;
    font-size: 13px;
    line-height: 160%;
    color: var(--grey-3);

    @media (min-width: $reports-table-s) {
      display: none;
    }
  }

  &__open {
    width: auto;
    height: auto;
    padding: 8px;
    margin-left: auto;
    flex-shrink: 0;
    cursor: pointer;

    @media (min-width: $reports-table-s) {
      display: none;
    }
  }

  &__open-details {
    text-align: left;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: var(--gen_button_color);
    font-weight: var(--font-weight-medium);
    font-size: 12px;
    line-height: 140%;
    cursor: pointer;

    @media (min-width: $full) {
      font-size: 14px;
    }
  }

  &__csv {
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
    // for ILTB
    // color: var(--william);
    color: var(--blue-3);
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    svg {
      path {
        fill: var(--blue-3);
      }
    }

    @media (min-width: $reports-table-s) {
      margin-left: auto;
    }

    .csv-span {
      @media screen and (min-width: 1280px) and (max-width: 1490px) {
        display: none;
      }
    }
  }

  &__csv-icon {
    margin-right: 8px;
  }

  &.engagement {
    .reports-item__cell {
      @media screen and (min-width: $reports-table-s) {
        text-align: right;
      }

      @media screen and (max-width: 1349px) {
        .stars_rating-mark {
          display: none;
        }
      }

      &--checkbox {
        width: 40px;
      }

      &--num {
        display: none;
        color: var(--grey-3);

        @media (min-width: $reports-table-s) {
          width: 40px;
          display: table-cell;
          padding: 8px 8px 8px;
          text-align: center;
        }
      }

      &--user,
      &--program,
      &--institution {
        text-align: left;
      }

      &--coach,
      &--survey {
        @media screen and (min-width: $reports-table-s) {
          text-align: center;
        }
      }

      &--num {
        text-align: center;
      }

      &--user {
        @media screen and (min-width: $reports-table-s) {
          max-width: 160px;
        }
      }

      .star-rating {
        @media screen and (min-width: $reports-table-s) {
          justify-content: end;
        }
      }
    }

    &.reports-item--info-shown {
      @media screen and (max-width: 1349px) {
        .reports-item__cell--program {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }

      .reports-item__open {
        transform: rotate(180deg);
      }
    }
  }
}
