@import 'src/breakpoints';

.event-form {
  &__error {
    // position: absolute;
    margin: 0 0 0 24px;
    font-size: 15px;
    line-height: 22px;
    color: var(--stiletto);
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 7px 0;
    padding: 0;
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
    color: var(--black);

    @media (min-width: $modal) {
      font-size: 16px;
      line-height: 140%;
    }
  }

  &__label-tip {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 150%;
    color: var(--grey-3);
    font-weight: normal;

    &::before {
      content: '\00B7';
      color: var(--grey-3);
      margin-right: 10px;
    }
  }

  &__input {
    width: 100%;
    height: 42px;
    max-width: 100%;
    padding: 8px 16px;
    background: var(--white);
    border: 1px solid #edebf9;
    border-radius: 8px;
    font-weight: var(--font-weight-regular);
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0015em;
    color: var(--black);

    &::placeholder {
      color: var(--grey-3);
    }

    &--error {
      border: 1px solid var(--stiletto);
    }
  }

  &__add-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__add-participant {
    border: 0;
    padding: 8px 0;
    border-bottom: 2px dashed var(--orange-2);
    background-color: transparent;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 125%;
    color: var(--orange-2);
    cursor: pointer;

    &:disabled {
      color: var(--grey-4);
      border-bottom: 2px dashed var(--grey-4);
      cursor: auto;
    }
  }

  &__input-group {
    // position: relative;
    margin-bottom: 8px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0 32px;

    @media (min-width: $eventForm-s) {
      flex-direction: row;
    }

    @media (min-width: $eventForm-s) {
      margin: 32px 0;
    }
  }

  &__submit {
    width: 100%;
    height: 44px;
    margin: 0 0 8px;
    font-size: 14px;
    line-height: 125%;
    background-color: var(--gen_button_color);
    border-color: var(--gen_button_color);
    &:hover {
      background-color: transparent;
      color: var(--gen_button_color);
    }

    &:disabled {
      cursor: auto;
      color: var(--gen_button_color);
      background-color: transparent;
      border-color: var(--gen_button_color);
      opacity: 0.6;
    }

    @media (min-width: $eventForm-s) {
      width: auto;
      margin: 0 16px 0 0;
    }
  }

  &__cancel {
    width: 100%;
    height: 44px;
    font-size: 14px;
    line-height: 125%;
    border-color: var(--gen_button_color);
    color: var(--gen_button_color);
    &:hover {
      border-color: var(--gen_button_color);
      background-color: transparent;
      color: var(--gen_button_color);
    }

    @media (min-width: $eventForm-s) {
      width: auto;
    }
  }

  .choose-time {
    margin: 20px 0 0;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: $eventForm-s) {
      margin: 20px 17px 0 0;
    }

    @media (min-width: $modal) {
      margin: 24px 17px 0 0;
    }
  }

  .default-auth__notification {
    position: static;
    margin-top: 10px;
  }
}
