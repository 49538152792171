@import 'src/breakpoints';

.program-participants {
  display: flex;
  justify-content: space-between;
  height: calc(100% - var(--header-height));
  padding: 16px;

  @media screen and (min-width: 1280px) {
    padding: 24px;
  }

  .participants-filters {
    width: 300px;
    margin: 0 20px 0 0;

    .filter-item {
      .filter-group-title {
        font-weight: var(--font-weight-regular);
        font-size: 15px;
        line-height: 150%;
        color: var(--gen_font_color);
        margin: 0 0 4px 24px;
      }

      margin: 0 0 20px;
    }

    .clear-all-filters {
      .clear-btn {
        font-size: 15px;
        line-height: 125%;
        color: var(--white);
        background: transparent;
        //border: 0;
        width: 160px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        // for ILTB
        // background: var(--orange-2);
        // border: 2px solid var(--orange-2);
        background: var(--gen_button_color);
        border: 2px solid var(--gen_button_color);
        border-radius: 8px;
        font-weight: var(--font-weight-medium);
        transition: all 0.2s ease-out;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    .mobile-filter-header {
      display: none;
    }
  }

  .participants-list {
    width: calc(100% - 300px);

    .participants-filter-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;

      h2 {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: var(--black);
      }

      .search {
        width: 325px;
        position: relative;

        input {
          display: block;
          padding: 9px 24px 9px 48px;
          width: 100%;
          line-height: 24px;
          color: var(--grey-3);
          font-weight: var(--font-weight-regular);
          font-size: 14px;
          -webkit-appearance: none;
          background: var(--white);
          border: 1px solid var(--grey-5);
          box-sizing: border-box;
          border-radius: 8px;
          color: #101821;

          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 14px;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 14px;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 14px;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: var(--grey-3);
            font-weight: var(--font-weight-regular);
            font-size: 14px;
          }

          &:hover {
            border-color: #2e79da;
            border-width: 2px;
            padding: 11px 23px 11px 47px;
            cursor: pointer;
          }

          &:focus {
            border-color: #2e79da;
            border-width: 2px;
            padding: 11px 23px 11px 47px;
            &::placeholder {
              color: transparent;
            }
          }
        }

        .icon {
          position: absolute;
          left: 18px;
          top: calc(50% - 10px);
          pointer-events: none;
          &.active {
            svg {
              path {
                fill: #2e79da;
              }
            }
          }
        }

        .filter-toggle-block {
          display: none;
        }
      }
    }

    .list {
      margin: 24px 0 0 0;
      height: calc(100vh - 220px);
      overflow-y: scroll;
      padding: 15px 30px;

      .view-profile__card {
        text-decoration: none;

        .item {
          background: var(--white);
          box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
          border-radius: 12px;
          padding: 12px 24px;
          display: flex;
          align-items: center;
          margin: 0 0 16px;
          position: relative;

          &.deactivated {
            background: #edf0f2;
            box-shadow: none;

            .participant-short-info {
              .name {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                color: var(--grey-3);
                span {
                  margin-right: 18px;
                }
                .deactivated-label {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 118px;
                  height: 32px;
                  background: #ec4e7c;
                  border-radius: 100px;
                  font-weight: var(--font-weight-medium);
                  font-size: 15px;
                  line-height: 150%;
                  color: #ffffff;
                }
              }

              .program-in-progress {
                color: var(--grey-3);
              }

              .sessions {
                .counter {
                  .from {
                    color: var(--grey-3);
                  }
                }
              }
            }
          }

          .participant-avatar {
            margin: 0 24px 0 0;
            width: 160px;

            img {
              border-radius: 12px;
              width: 160px;
              height: 160px;
              object-fit: cover;
            }
          }

          .participant-short-info {
            width: calc(100% - 350px);
            flex-direction: column;
            display: flex;
            justify-content: center;

            .name {
              font-weight: var(--font-weight-medium);
              font-size: 18px;
              line-height: 125%;
              letter-spacing: 0.015em;
              color: var(--gen_font_color);
              margin: 0 0 8px;
            }

            .country {
              font-weight: var(--font-weight-regular);
              font-size: 14px;
              line-height: 130%;
              margin: 0 0 13px;
              color: var(--grey-3);
            }

            .program-in-progress {
              font-weight: var(--font-weight-regular);
              font-size: 16px;
              line-height: 130%;
              color: var(--gen_font_color);
            }

            .sessions {
              display: flex;
              margin: 0 0 13px;

              .title {
                font-weight: var(--font-weight-regular);
                font-size: 17px;
                line-height: 130%;
                color: var(--grey-3);
                width: 135px;
              }

              .counter {
                font-weight: var(--font-weight-medium);
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.0015em;

                .from {
                  color: var(--gen_font_color);
                }

                .to {
                  color: var(--grey-3);
                }
              }
            }

            .rate-information {
              display: flex;

              .title {
                font-weight: var(--font-weight-regular);
                font-size: 17px;
                line-height: 130%;
                color: var(--grey-3);
                width: 135px;
              }

              .counter {
                display: flex;
                align-items: center;
                justify-content: center;

                .total-success {
                  font-weight: var(--font-weight-medium);
                  font-size: 17px;
                  line-height: 140%;
                  letter-spacing: 0.0015em;
                  color: var(--gen_font_color);
                }

                .chart {
                  width: 170px;
                  height: 8px;
                  background: #e0e0e0;
                  border-radius: 4px;
                  position: relative;
                  margin: 0 0 0 20px;

                  .success-chart {
                    position: absolute;
                    left: 0;
                    // for ILTB
                    // background: #366B65;
                    background: var(--blue-3);
                    border-radius: 4px;
                    height: 8px;
                  }
                }
              }
            }

            .participant-block-wrapper {
              display: flex;
              //flex-wrap: wrap;

              .participant-left-block {
                width: 50%;
                margin: 0 80px 0 0;
              }

              .participant-right-block {
                width: 50%;
              }
            }
          }

          .participant-full-info {
            width: 210px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;

            .view-profile {
              text-decoration: none;
              font-weight: 700;
              font-size: 15px;
              line-height: 125%;
              transition: all 0.2s ease-out;
              letter-spacing: 0.01em;
              // for ILTB
              // border: 2px solid var(--orange-2);
              // color: var(--orange-2);
              border: 2px solid var(--gen_button_color);
              color: var(--gen_button_color);
              border-radius: 8px;
              padding: 11px 10px;

              &:hover {
                // for ILTB
                // background: var(--orange-2);
                background: var(--gen_button_color);
                color: var(--white);
              }
            }
          }
        }
        &:hover {
          .item {
            .participant-short-info {
              .name {
                color: var(--blue-3);
              }
            }
          }
        }
      }
    }

    .empty-list {
      margin: 70px 0 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .searched-value {
        font-weight: var(--font-weight-medium);
        font-size: 24px;
        line-height: 125%;
        margin: 24px 0;
        color: #101821;

        .text {
          color: var(--blue-3);
        }
      }

      .clear-search-field {
        cursor: pointer;
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 150%;
        text-decoration: underline;
        color: var(--blue-3);
      }
    }
  }

  @media (max-width: $desktop-3) {
    .participants-list {
      .list {
        .view-profile__card {
          .item {
            .participant-avatar {
              width: 110px;

              img {
                width: 110px;
                height: 110px;
              }
            }

            .participant-short-info {
              .rate-information {
                flex-direction: column-reverse;
              }

              .participant-block-wrapper {
                .participant-left-block {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    margin: 0 -24px;

    .participants-filters {
      opacity: 0;
      visibility: hidden;
      width: 100%;
      margin: 0 20px 0 0;
      background: #fff;
      position: fixed;
      top: 0;
      height: 100%;
      left: 0;
      z-index: 5;
      padding: 40px 16px;
      transition: all 0.3s ease-out;
      overflow: scroll;

      &.open-filter {
        opacity: 1;
        visibility: visible;
      }

      .mobile-filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 30px;

        .filter-title {
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          color: var(--black);
        }
      }

      .apply-filters {
        display: flex;
        margin: 40px 0 0 0;

        .apply-filters-btn {
          font-size: 15px;
          line-height: 125%;
          color: var(--white);
          background: transparent;
          //border: 0;
          width: 160px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--orange-2);
          border-radius: 56px;
          font-weight: var(--font-weight-medium);
          transition: all 0.2s ease-out;
          cursor: pointer;
          border: 2px solid var(--orange-2);

          &:disabled {
            opacity: 0.5;
            cursor: default;

            &:hover {
              background: var(--orange-2);
              color: var(--white);
            }
          }

          &:hover {
            background: var(--white);
            color: var(--orange-2);
          }
        }
      }
    }

    .participants-list {
      width: 100%;

      .participants-filter-search {
        align-items: flex-start;
        flex-direction: column;

        h2 {
          font-weight: var(--font-weight-medium);
          font-size: 32px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: var(--black);
        }

        .search {
          margin: 24px 0 0;
          width: 100%;
          display: flex;
          justify-content: space-between;

          input {
            width: 50%;
          }

          .filter-toggle-block {
            display: flex;
            margin: 0 0 0 16px;
          }
        }
      }

      .empty-list {
        .search-is-nothing-found {
          width: 100%;
        }
      }

      .list {
        .view-profile__card {
          .item {
            .participant-avatar {
              margin: 0 16px 0 0;
              width: 64px;

              img {
                width: 64px;
                height: 64px;
              }
            }

            .participant-short-info {
              width: calc(64% - 80px);
              flex-direction: column;
              display: flex;
              justify-content: center;

              .name {
                font-weight: var(--font-weight-medium);
                font-size: 32px;
                line-height: 125%;
                letter-spacing: 0.015em;
                color: var(--gen_font_color);
                margin: 0 0 8px;
              }

              .country {
                font-weight: var(--font-weight-regular);
                font-size: 17px;
                line-height: 130%;
                color: var(--grey-3);
              }

              .program-in-progress {
                font-weight: var(--font-weight-regular);
                font-size: 17px;
                line-height: 130%;
                margin: 0 0 4px;
                color: var(--gen_font_color);
              }

              .sessions {
                display: flex;
                margin: 0 0 13px;

                .counter {
                  font-weight: var(--font-weight-medium);
                  font-size: 16px;
                  line-height: 140%;
                  letter-spacing: 0.0015em;

                  .from {
                    color: var(--gen_font_color);
                  }

                  .to {
                    color: var(--grey-3);
                  }
                }
              }

              .rate-information {
                display: flex;

                .title {
                  font-weight: var(--font-weight-regular);
                  font-size: 17px;
                  line-height: 130%;
                  color: var(--grey-3);
                  width: 135px;
                }

                .counter {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .total-success {
                    font-weight: var(--font-weight-medium);
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.0015em;
                    color: var(--gen_font_color);
                  }

                  .chart {
                    width: 170px;
                    height: 8px;
                    background: #e0e0e0;
                    border-radius: 4px;
                    position: relative;
                    margin: 0 0 0 20px;

                    .success-chart {
                      position: absolute;
                      left: 0;
                      // for ILTB
                      // background: #366B65;
                      background: var(--blue-3);
                      border-radius: 4px;
                      height: 8px;
                    }
                  }
                }
              }

              .participant-block-wrapper {
                display: flex;
                //flex-wrap: wrap;

                .participant-left-block {
                  width: 100%;
                  margin: 0;
                }

                .participant-right-block {
                  width: 50%;
                }
              }
            }

            .participant-another-info {
              width: 25%;

              .participant-right-block {
                display: flex;
                //flex-wrap: wrap;
                //width: 100%;
                flex-direction: column;

                .sessions {
                  width: 100%;
                  color: #929292;
                  margin: 0 0 12px;

                  .title {
                    font-size: 13px;
                  }

                  .counter {
                    font-size: 15px;

                    .from {
                      color: #101821;
                    }
                  }
                }

                .rate-information {
                  width: 100%;
                  color: #929292;
                  padding: 0 10px 0 0;

                  .title {
                    font-size: 13px;
                  }

                  .counter {
                    display: flex;
                    align-items: center;

                    .total-success {
                      font-size: 15px;
                      line-height: 150%;
                      color: #101821;
                    }

                    .chart {
                      width: 100%;
                      height: 8px;
                      background: #e0e0e0;
                      border-radius: 4px;
                      position: relative;
                      margin: 0 0 0 12px;

                      .success-chart {
                        position: absolute;
                        left: 0;
                        background: #366b65;
                        border-radius: 4px;
                        height: 8px;
                      }
                    }
                  }
                }
              }
            }

            .participant-full-info {
              width: 30%;
              align-items: center;

              .view-profile {
                text-decoration: none;
                font-weight: 700;
                font-size: 15px;
                line-height: 125%;
                transition: all 0.2s ease-out;
                letter-spacing: 0.01em;

                border-radius: 8px;

                padding: 11px 15px;
                border: 2px solid var(--gen_button_color);
                color: var(--gen_button_color);

                &:hover {
                  // for ILTB
                  // background: var(--orange-2);
                  background: var(--gen_button_color);
                  color: var(--white);
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet-4 - 1px) {
    margin: 0;

    .participants-list {
      width: 100%;

      .participants-filter-search {
        align-items: flex-start;
        flex-direction: column;
        padding: 0;

        h2 {
          font-weight: var(--font-weight-medium);
          font-size: 32px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: var(--black);
        }

        .search {
          margin: 24px 0 0;
          width: 100%;
          display: flex;
          justify-content: space-between;

          input {
            width: 70%;
          }

          .filter-toggle-block {
            display: flex;
            margin: 0 0 0 16px;
          }
        }
      }

      .empty-list {
        .search-is-nothing-found {
          width: 100%;
        }
      }

      .list {
        margin: 32px -15px;
        padding: 0 15px;
        .view-profile__card {
          .item {
            flex-wrap: wrap;
            align-items: flex-start;

            .participant-avatar {
              margin: 0 16px 0 0;
              width: 60px;

              img {
                width: 64px;
                height: 64px;
              }
            }

            .participant-short-info {
              padding: 0;
              width: calc(100% - 76px);
              flex-direction: column;
              display: flex;
              justify-content: center;

              .name {
                font-size: 22px;
                line-height: 130%;
                margin: 0 0 4px;
              }

              .program-in-progress {
                font-size: 13px;
                line-height: 160%;
              }

              .rate-information {
                .counter {
                  .chart {
                    width: 120px;
                    height: 8px;
                    background: #e0e0e0;
                    border-radius: 4px;
                    position: relative;
                    margin: 0 0 0 20px;
                  }
                }
              }

              .participant-block-wrapper {
                .participant-left-block {
                  margin: 0 0 20px;
                  width: 100%;

                  .country {
                    margin: 0;
                  }

                  .program-in-progress {
                    margin: 0 0 4px;
                  }
                }
              }
            }

            .participant-another-info {
              width: 100%;

              .participant-right-block {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .sessions {
                  width: 100%;
                  color: #929292;

                  .title {
                    font-size: 13px;
                  }

                  .counter {
                    font-size: 15px;

                    .from {
                      color: #101821;
                    }
                  }
                }

                .rate-information {
                  width: 100%;
                  color: #929292;

                  .title {
                    font-size: 13px;
                  }

                  .counter {
                    display: flex;
                    align-items: center;

                    .total-success {
                      font-size: 15px;
                      line-height: 150%;
                      color: #101821;
                    }

                    .chart {
                      width: 100%;
                      height: 8px;
                      background: #e0e0e0;
                      border-radius: 4px;
                      position: relative;
                      margin: 0 0 0 12px;

                      .success-chart {
                        position: absolute;
                        left: 0;
                        background: #366b65;
                        border-radius: 4px;
                        height: 8px;
                      }
                    }
                  }
                }
              }
            }

            .participant-full-info {
              width: 100%;
              //display: flex;
              //flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 24px 0 0 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet-2) {
    .participants-list {
      width: 100%;

      .participants-filter-search {
        align-items: flex-start;
        flex-direction: column;
        padding: 0;

        h2 {
          font-weight: var(--font-weight-medium);
          font-size: 32px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: var(--black);
        }

        .search {
          margin: 24px 0 0;
          width: 100%;
          display: flex;

          input {
            width: 100%;
          }

          .filter-toggle-block {
            display: flex;
            margin: 0 0 0 16px;
          }
        }
      }
    }
  }
}
