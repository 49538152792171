.star-rating {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &__icon {
        margin-right: 5px;
        flex-shrink: 0;

        &:last-child {
            margin: 0;
        }
    }
}