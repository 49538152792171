@import 'src/breakpoints';

.edit-my-profile {
  position: relative;
  margin: 0 15%;

  .save-block-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .save-personal-info {
      font-weight: var(--font-weight-medium);
      font-size: 14px;
      line-height: 125%;
      color: var(--white);
      // for ILTB
      // background: var(--orange-2);
      background: var(--gen_button_color);
      border-radius: 8px;
      border: 0;
      padding: 13px 24px;
      transition: all ease-in 0.25s;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &:hover {
        // for ILTB
        // background: var(--orange-4);
        background: var(--gen_button_color);
      }

      &.active {
        cursor: pointer;
        color: var(--white);
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
          // for ILTB
          // background: var(--orange-2);
          background: var(--gen_button_color);
          color: var(--white);
        }
      }
    }
  }

  .section-info-block {
    margin: 24px 0 0 0;
    max-width: 910px;

    .section-title {
      font-weight: var(--font-weight-medium);
      font-size: 24px;
      line-height: 125%;
      letter-spacing: 0.015em;
      color: var(--gen_font_color);
      margin: 0 0 40px;

      @media screen and (min-width: $full) {
        font-size: 32px;
      }
    }

    .avatar {
      display: flex;
      align-items: center;

      margin: 0 0 32px;

      .image {
        margin: 0 16px 0 0;

        img {
          border-radius: 12px;
          width: 110px;
          height: 110px;
          object-fit: cover;
        }
      }

      .upload-image-wrap {
        .load-img {
          cursor: pointer;
          font-weight: var(--font-weight-regular);
          font-size: 15px;
          line-height: 150%;
          color: var(--william);
          text-decoration-line: underline;
          text-decoration: underline;
          margin: 0 0 8px;
        }

        .prompt {
          font-weight: normal;
          font-size: 15px;
          line-height: 150%;
          color: var(--grey-3);
        }
      }
    }

    .cv-file {
      margin: 0 0 40px 0;

      .attach-cv {
        .default-show {
          font-weight: var(--font-weight-medium);
          font-size: 20px;
          line-height: 125%;
          display: flex;
          align-items: center;
          color: var(--blue-3);
          cursor: pointer;

          .icon-cv-upload {
            margin: 0 16px 0 0;
            path {
              fill: var(--blue-3);
            }
          }
        }

        .uploaded-cv-file {
          background: var(--white);
          border: 1px solid var(--grey-3);
          margin: 16px 0 0;

          border-radius: 12px;
          height: 64px;
          display: inline-flex;
          align-items: center;

          .icon-attached {
            margin: 0 20px;
          }

          .text {
            font-weight: var(--font-weight-medium);
            font-size: 17px;
            line-height: 140%;
            /* identical to box height, or 24px */

            letter-spacing: 0.0015em;

            /* Main Green */

            color: #366b65;
          }

          .icon-clear-cv {
            cursor: pointer;
            margin: 0 24px;
          }

          svg {
            path {
              fill: var(--blue-3);
            }
          }
        }

        &.input-error {
          .uploaded-cv-file {
            border: 2px solid var(--stiletto);
          }
        }
      }
    }

    .languages-list {
      max-width: 560px;

      .item {
        display: flex;
        justify-content: space-between;
        margin: 0 0 24px;
        position: relative;

        .language {
          width: calc(50% - 8px);

          .row-select__control {
            .row-select__value-container {
              flex-wrap: nowrap;

              div {
                color: var(--black1);
                font-weight: var(--font-weight-medium);
                font-size: 14px;
              }
            }
          }
        }

        .level {
          width: calc(50% - 8px);
        }

        .remove-item-language {
          position: absolute;
          right: -20px;
          top: 55%;
          display: flex;
          cursor: pointer;
        }

        .input-error {
          .row-select__control {
            border: 2px solid var(--stiletto);
          }
        }
      }
    }

    .profile-row {
      margin: 0 0 23px;

      &__label {
        font-weight: var(--font-weight-medium);
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.0015em;
        color: var(--gen_font_color);
        margin: 0 0 8px 0;
        display: flex;

        @media screen and (min-width: $full) {
          font-size: 17px;
        }

        .prompt {
          font-weight: var(--font-weight-regular);
          font-size: 15px;
          line-height: 150%;
          margin: 0 0 0 12px;
          padding: 0 0 0 20px;

          color: var(--grey-3);

          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 10px;
            top: calc(50% - 2px);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: var(--grey-3);
          }
        }
      }

      &__input {
        display: flex;
        align-items: center;
        width: 560px;
        height: 48px;
        border-radius: 6px;
        padding: 0 16px;
        background-color: var(--white);
        color: var(--black1);
        font-weight: var(--font-weight-regular);
        font-size: 14px;
        line-height: 140%;
        border: 1px solid var(--purple-light);
        transition: border-color 0.25s;

        @media screen and (min-width: $full) {
          font-size: 17px;
          height: 48px;
        }

        &::placeholder {
          font-size: 17px;
          line-height: 140%;
          color: var(--grey-3);
        }
      }

      &__textarea {
        font-family: 'Poppins', sans-serif;
        background-color: var(--white);
        width: 100%;
        min-height: 150px;
        border: 1px solid var(--purple-light);
        border-radius: 8px;
        padding: 40px 32px;
        font-weight: var(--font-weight-regular);
        font-size: 18px;
        line-height: 130%;
        outline: none;
        resize: none;

        &.input-error {
          border: 2px solid var(--stiletto);
        }
      }

      &.input-error {
        .profile-row__input {
          border: 2px solid var(--stiletto);
        }

        .row-select__control {
          border: 2px solid var(--stiletto);
        }

        textarea {
          border: 2px solid var(--stiletto);
        }
      }

      &__error {
        color: var(--stiletto);
        margin: 0 0 0 40px;
        font-weight: var(--font-weight-regular);
        font-size: 15px;
        line-height: 150%;
      }
    }

    .add-new-info {
      font-weight: var(--font-weight-medium);
      font-size: 20px;
      line-height: 125%;
      color: var(--blue-3);
      cursor: pointer;
    }

    .seniority-level-list {
      max-width: 560px;
    }

    .multi-select {
      .multi-select-remove-item {
        display: flex;
        align-items: center;
        justify-content: center;

        path {
          transition: all ease-in 0.15s;
        }

        &:hover {
          path {
            fill: var(--black);
          }
        }
      }
    }

    .work-experience-list {
      .item {
        position: relative;
        max-width: 650px;
        margin: 0 0 50px;

        .counter-work-experience {
          margin: 0 0 30px;
          font-weight: var(--font-weight-medium);
          font-size: 24px;
          line-height: 125%;
          color: #101821;
        }

        .item-row {
          margin: 0 0 24px;

          .profile-row__input {
            &.input-error {
              border: 2px solid var(--stiletto);
            }
          }
        }

        .date-picker {
          display: flex;
          justify-content: space-between;
          max-width: 560px;

          .react-datepicker-popper {
            width: calc(50% - 52px);
            min-width: 270px;
            .react-datepicker {
              width: 100%;
              border: 1px solid #cdcbcb;
              box-sizing: border-box;
              border-radius: 16px;
              overflow: hidden;
              .react-datepicker__navigation {
                margin: 10px 0;
              }

              .react-datepicker__navigation-icon::before {
                border-color: #000;
                border-width: 2px 2px 0 0;
              }

              .react-datepicker__month-container {
                width: 100%;

                .react-datepicker__month-text {
                  padding: 10px 0;
                }

                .react-datepicker__header {
                  background-color: #fff;
                  border-bottom: 1px solid #cdcbcb;
                  padding: 15px 0;
                  .react-datepicker__current-month {
                    font-weight: var(--font-weight-medium);
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 10px;
                  }
                }
                .react-datepicker__day--in-selecting-range {
                  background-color: transparent;
                  color: #000;
                }
                .react-datepicker__day--today {
                  background-color: aliceblue;
                  border-radius: 50%;
                  color: #000;
                }
                .react-datepicker__day--selected {
                  background: #2e79da;
                  color: #fff;
                  border-radius: 50%;
                }
              }
            }
          }

          .date-from {
            width: calc(50% - 8px);
          }

          .date-to {
            width: calc(50% - 8px);
          }

          .experience-row-input {
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            padding: 0 24px;
            background-color: var(--white);
            color: var(--black1);
            font-weight: var(--font-weight-regular);
            font-size: 17px;
            line-height: 140%;
            border: 1px solid var(--purple-light);
            transition: border-color 0.25s;

            &::placeholder {
              font-size: 18px;
              line-height: 125%;
              color: var(--grey-3);
            }

            &.input-error {
              border: 2px solid var(--stiletto);
            }
          }
        }

        .remove-row {
          .remove-item-work-experience {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
          }
        }
      }
    }

    .publications-list {
      .item {
        position: relative;
        max-width: 650px;
        margin: 0 0 50px;

        .counter-publication {
          margin: 0 0 30px;
        }

        .item-row {
          margin: 0 0 24px;

          .profile-row__textarea,
          .profile-row__input {
            &.input-error {
              border: 2px solid var(--stiletto);
            }
          }
        }

        .remove-row {
          .remove-item-publications {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
          }
        }
      }
    }

    &.coaching-info {
      .coaching-information-list {
        .multi-select {
          .row-select__control {
            .row-select__indicators {
              .multi-select-remove-item {
                display: none;
              }
            }
          }
        }
      }

      .additional-info {
        margin: 24px 0 0;

        .coaching-experience {
          display: flex;
          flex-wrap: wrap;

          .section-coaching-experience {
            width: 100%;
            font-weight: var(--font-weight-medium);
            font-size: 17px;
            line-height: 140%;
            letter-spacing: 0.0015em;
            color: #101821;
            margin: 0 0 7px 24px;
            display: flex;
          }

          .years {
            width: 50%;
            margin: 0 0 24px;
          }

          .hours {
            width: 50%;
            margin: 0 0 24px;
          }

          .profile-row__input {
            width: 80%;
          }
        }
      }
    }
  }

  .status-messages {
    margin: 25px 0;

    .alert {
      width: 100%;
      max-height: 80px;
      padding: 16px 24px;

      border-radius: 12px;

      font-size: 15px;
      font-weight: var(--font-weight-regular);
      line-height: 22px;
      text-align: center;
      //transform: scale(0.9);

      &.alert-success {
        background: var(--green-4);
        border: 1px solid var(--green);
        color: var(--white);
      }

      &.alert-error {
        background: var(--background-error);
        border: 1px solid var(--stiletto);
        color: var(--white);
      }
    }
  }

  @media (max-width: $desktop-1) {
    .section-info-block {
      .profile-row {
        &__textarea {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: $tablet-4) {
    .section-info-block {
      .profile-row {
        &__input {
          width: 100%;
        }

        &__textarea {
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    margin: 0;

    .section-info-block {
      margin: 0 0 56px;

      .work-experience-list {
        .item {
          .date-picker {
            .experience-row-input {
              padding: 0 30px;
              text-align: center;
            }
          }
        }
      }

      .languages-list {
        .item {
          .remove-item-language {
            right: -10px;
            top: 60%;
          }

          .row-select__control {
            padding: 0 0 0 20px;
          }

          .row-select__indicators {
            right: 6px;
          }
        }

        .profile-row__label {
          margin: 0 0 7px 20px;
        }
      }
    }
  }
}
