@import '../../../variables.scss';

section.choose-coach-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    margin-top: 32px;
    width: 120px;
    height: 120px;
  }

  .title {
    margin-top: 24px;
    font-size: 32px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.015em;
    color: #101821;
  }

  .description {
    margin-top: 16px;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    color: #929292;
  }

  .proceed__link {
    margin-top: 56px;
    width: 165px;
    height: 63px;
    background: var(--orange-2);
    border-radius: 80px;
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 125%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border: none;
    transition: all 0.2s ease-out;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: var(--orange-2);
      background: #fff;
      border: 2px solid var(--orange-2);
    }
  }
}
