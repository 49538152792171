@import 'src/breakpoints';

.credit-graph {
  &--total {
    padding: 14px 12px 12px;

    .title {
      font-weight: var(--font-weight-medium);
      font-size: 14px;
      line-height: 150%;
      color: #101821;
    }

    @media (min-width: $tablet-3) {
      padding: 14px;
    }

    .credit-graph__row {
      margin-bottom: 24px;
      background: var(--grey-girl);
      border-radius: 100px;

      @media (min-width: $tablet-3) {
        margin-bottom: 12px;
      }
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    @media (min-width: $tablet-3) {
      margin-bottom: 4px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: 12px;
    line-height: 130%;
    color: var(--black);

    @media (min-width: $tablet-3) {
      line-height: 140%;
    }

    @media (min-width: $desktop-2) {
      font-size: 14px;
      line-height: 150%;
      margin-right: 16px;
    }
  }

  &__info {
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    line-height: 160%;

    .accent {
      margin-right: 4px;
      font-weight: var(--font-weight-medium);
      font-size: 13px;
      line-height: 125%;

      @media (min-width: $full) {
        font-size: 20px;
      }
    }
  }

  &__row {
    display: flex;
    width: 100%;
    height: 8px;
    background: var(--db_session_credits_purchased);
    border-radius: 100px;
    overflow: hidden;
  }

  &__line {
    position: relative;
    height: 100%;
    border-radius: 40px;
    background: white;
    z-index: 3;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--db_session_credits_used);
      border-radius: 40px;
      right: 1.5px;
    }

    &--in-progress {
      z-index: 2;

      &::before {
        border-radius: 0 40px 40px 0;
        background: var(--db_session_credits_in_progress);
      }
    }

    &--available {
      z-index: 1;

      &::before {
        border-radius: 0 40px 40px 0;
        background: var(--db_session_credits_available);
      }
    }
  }

  &__stats {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;

    @media (min-width: $tablet-3) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 499px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__stat {
    display: inline-flex;
    align-items: center;
    margin-right: 12px;
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 160%;

    @media (min-width: $tablet-3) {
      margin: 0 0 2px;
    }

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background: var(--db_session_credits_available);
      border-radius: 40px;
      margin-right: 4px;
    }

    .accent {
      display: inline;
      font-weight: var(--font-weight-medium);
      line-height: 125%;
    }

    &--used {
      &::before {
        background: var(--db_session_credits_used);
      }
    }

    &--in-progress {
      &::before {
        background: var(--db_session_credits_in_progress);
      }
    }
  }
}
