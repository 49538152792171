.event-link {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: var(--black);
  word-break: break-all;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;

  &__icon {
    flex-shrink: 0;
    margin-right: 12px;
  }

  &--google {
    align-items: center;
    align-self: flex-start;
    padding-right: 5px;
    border: 1px solid var(--grey-girl);
    border-radius: 6px;
  }
}
