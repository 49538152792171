.box {

}

.actions {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.select_wrap {
  width: 400px
}

.selectTitle {
  font-size: 17px;
  font-weight: var(--font-weight-medium);
}
