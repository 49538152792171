@import 'src/breakpoints';

.select-currency {
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  &__list {
    margin-top: 10px;
    display: flex;

    .modern-radio-container {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__title {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 140%;
  }

  @media screen and (min-width: $tablet-1) {
    align-items: center;
    flex-direction: row;
    margin-top: 0;

    &__list {
      margin-top: 0;

      .modern-radio-container {
        &:first-child {
          margin-left: 20px;
        }
      }
    }
  }
}
