.attendance-graph {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__scale {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 23px;
    height: 180px;
    border-radius: 4px;
    overflow: hidden;
    background-color: var(--background);
  }

  &__line {
    flex-shrink: 0;
    width: 100%;
    background: var(--db_completed_session);
    border-top: 2px solid white;
    border-radius: 4px;

    &:nth-child(1) {
      transform: translateY(4px);
    }

    &:nth-child(2) {
      transform: translateY(2px);
    }

    &:last-child {
      transform: translateY(0);
      box-sizing: content-box;
      padding-bottom: 2px;
    }

    &--not-attended {
      background: var(--db_not_attended_session);
    }

    &--confirmed {
      background: var(--db_confirmed_session);
    }
  }

  &__add-title {
    margin-top: 8px;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
  }
}
