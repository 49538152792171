.decline-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--request {
        .decline-modal__text {
            margin-bottom: 24px;
        }
    }

    &__img {
        max-width: 100%;
        height: auto;
    }

    &__title {
        margin: 36px 16px;
        font-size: 32px;
        text-align: center;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: var(--black);
        opacity: 0.9;
    }

    &__text {
        margin-bottom: 56px;
        font-size: 15px;
        text-align: center;
        line-height: 150%;
        color: var(--grey-3);
        opacity: 0.9;
    }

    &__actions {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        @media (min-width: 520px) {
            flex-direction: row;
            width: auto;
        }

        .button {
            width: 100%;
            margin: 0 0 24px 0;

            @media (min-width: 520px) {
                width: auto;

                &:first-child {
                    margin: 0 24px 24px 0;
                }
            }
        }
    }
}