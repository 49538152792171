@import "src/breakpoints";

.time-picker {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 18px 31px 16px;
	font-weight: var(--font-weight-medium);
	font-size: 18px;
	line-height: 24px;
	color: var(--black);

	@media (min-width: $eventForm-s) {
		padding: 24px 8px 16px;
	}

	@media (min-width: $tablet) {
		padding: 24px 31px 16px;
	}

	&__actions {
		display: flex;
		justify-content: space-around;
		margin: 0 0 4px 0;

		&:last-child {
			margin: 8px 0 0 0;
		}
	}

	&__btn {
		padding: 4px;
		box-sizing: content-box;

		&--down {
			transform: rotate(180deg);
		}
	}

	&__action-icon {
		margin: 0 0 0 19px;
	}

	&__ampm {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 50px;
		flex-shrink: 0;
	}

	&__ampm-value {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		margin-top: -2px;
	}

	&__ampm-icon {
		margin: 0 0 10px;

		&:last-child {
			margin: 11px 0 0;
		}
	}

	.react-time-picker__inputGroup__leadingZero {
		position: absolute;
		top: 12px;
		left: 25px;
		font-size: 18px;
		line-height: 24px;
		z-index: 100;

		@media (min-width: $eventForm-s) and (max-width: 766px) {
			left: 18px;
		}

		&:last-of-type {
			left: 115px;

			@media (min-width: $eventForm-s) and (max-width: 766px) {
				left: 93px;
			}
		}
	}

	.react-time-picker__inputGroup__input {
		box-sizing: border-box;
		width: 70px !important;
		height: 48px;
		background: var(--grey-2);
		border-radius: 14px;
		color: inherit;
		text-align: center;
		font-size: 18px;
		line-height: 24px;
		flex-shrink: 0;

		@media (min-width: $eventForm-s) {
			width: 55px !important;
		}

		@media (min-width: $tablet) {
			width: 70px !important;
		}

		&--hasLeadingZero {
			margin-left: 0;
		}
	}

	.react-time-picker__inputGroup__divider {
		margin: 0 8px;
	}
}

.custom-time-picker input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}

.custom-time-picker input::-webkit-outer-spin-button,
.custom-time-picker input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; /* Safari and Chrome */
}
