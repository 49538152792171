@import '../../../variables.scss';

section.feedback-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: var(--background);

  .decor-image-left {
    position: absolute;
    top: 345px;
    left: 0;
    @media screen and (max-width: 1299px) {
      display: none;
    }
  }

  .decor-image-right {
    position: absolute;
    top: 170px;
    right: 0px;
    @media screen and (max-width: 1299px) {
      display: none;
    }

    @media screen and (min-width: 1920px) {
      top: 170px;
      right: 100px;
    }
  }

  .decor-image-main {
    position: absolute;
    bottom: calc(80px + var(--auth-footer-min-height));
    display: none;

    @media screen and (min-width: 1280px) {
      display: block;
      right: 0;
      transform: scale(0.9);
    }

    @media screen and (min-width: 1920px) {
      right: 100px;
      transform: unset;
    }
  }
}
