@import 'src/breakpoints';

.billing-overview-section {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__head {
    width: 100%;
    display: flex;
    background-color: #fff;
    padding: 12px;
    background-color: var(--reports-sorting_bg);
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    line-height: 150%;
    color: #6a541f;

    @media screen and (min-width: $tablet-1) {
      padding: 12px 24px;
    }

    @media screen and (min-width: $desktop-2) {
      padding: 12px 32px 12px 80px;
    }

    .head__columns {
      width: 50%;

      .sorting-buttons__wrapper {
        svg path {
          fill: #6a541f;
        }
      }
    }
  }

  .empty-data {
    width: 100%;
    padding: 12px;
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    line-height: 150%;
    background-color: #fff;
  }
}
