@import 'src/breakpoints';

.programme-card {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  background: Var(--white);
  box-shadow: 0px 6px 12px rgba(209, 207, 205, 0.4);
  text-decoration: none;
  position: relative;

  @media (min-width: 1440px) {
    padding: 12px 24px;
  }

  @media (min-width: $desktop) {
    flex-direction: row;
  }

  &.deactivated {
    background: #edf0f2;
    box-shadow: none;

    .programme-card__title {
      color: #929292;

      .deactivated-label {
        margin: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 118px;
        height: 32px;
        background: #ec4e7c;
        border-radius: 100px;
        font-weight: var(--font-weight-medium);
        font-size: 15px;
        line-height: 150%;
        color: #ffffff;
      }

      span {
        width: max-content;
        margin-right: 24px;
      }
    }

    .programme-card__progress-fill {
      background: #929292;
    }

    &:hover {
      box-shadow: none;
      transition: top 0.5s ease 0s; /* only transition top property */
      top: -2px;
      .programme-card__title {
        color: var(--blue-3);
      }
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 200px;
    height: 140px;
    margin: 0 0 16px 0;
    border-radius: 8px;
    object-fit: cover;

    @media (min-width: $desktop) {
      margin: 0 48px 0 0;
    }

    @media (min-width: $full) {
      max-width: 260px;
      height: 152px;
    }
    @media (max-width: $tablet) {
      max-width: 100%;
      height: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 12px;
    font-weight: var(--font-weight-medium);
    font-size: 18px;
    line-height: 22.5px;
    color: var(--gen_font_color);
    text-decoration: none;

    @media (min-width: $desktop) {
      margin: 0 0 12px;
    }

    @media screen and (min-width: $full) {
      font-size: 24px;
      line-height: 40px;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__info-item {
    display: flex;
    align-items: center;
    margin: 0 12px 0 0;
    font-weight: var(--font-weight-regular);
    font-size: 12px;
    line-height: 18px;
    color: var(--grey-3);

    @media screen and (min-width: $full) {
      font-size: 15px;
      line-height: 22px;
    }

    &::before {
      content: '\00B7';
      display: inline-block;
      margin-right: 12px;
      font-size: 24px;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__progress {
    display: flex;
    flex-direction: column;
    margin-top: 22px;

    .wrap-trigger {
      position: absolute;
      right: 24px;
      top: 24px;

      .trigger-upload-svg {
        cursor: pointer;
      }

      .open-modal-upload-csv {
        display: flex;
        background: var(--white);
        box-shadow: 0 12px 24px rgba(209, 207, 205, 0.4);
        border-radius: 12px;
        position: absolute;
        top: 50px;
        right: 0;
        padding: 16px 48px 16px 24px;
        transition: all ease-in 0.25s;
        opacity: 0;
        visibility: hidden;
        cursor: pointer;

        .icon {
          margin: 0 12px 0 0;
          display: flex;
          align-items: center;
          svg {
            path {
              fill: var(--gen_font_color);
            }
          }
        }

        .text {
          font-weight: var(--font-weight-regular);
          font-size: 18px;
          line-height: 150%;
          white-space: nowrap;
          color: var(--gen_font_color);
        }

        &.open {
          opacity: 1;
          visibility: visible;
        }
      }

      @media (max-width: $tablet) {
        right: 24px;
      }
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
    }

    @media (min-width: $full) {
      margin-top: 44px;
    }
  }

  &__progress-num {
    flex-shrink: 0;
    font-weight: var(--font-weight-medium);
    font-size: 12px;
    line-height: 18px;
    // for ILTB
    // color: var(--viridian);
    color: var(--gen_font_color);

    @media screen and (min-width: $full) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  &__progress-bar {
    position: relative;
    flex-grow: 1;
    height: 8px;
    margin: 0 0 8px 0;
    border-radius: 12px;
    background-color: var(--grey-4);

    @media (min-width: 1024px) {
      margin: 0 24px 0 0;
    }
  }

  &__progress-fill {
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 12px;
    // for ILTB
    // background-color: var(--mint);
    background-color: var(--blue-3);
    transition: width 0.35s linear;
  }

  &:hover {
    box-shadow: 0px 16px 32px rgba(210, 217, 225, 0.4);
    transition: top 0.5s ease 0s; /* only transition top property */
    top: -2px;
    .programme-card__title {
      color: var(--blue-3);
    }
  }
}

.modal-upload-csv {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(40, 34, 31, 0.4);
  z-index: 5;
  white-space: pre-line;

  .modal-upload-csv-wrap {
    background: var(--white);
    border-radius: 12px;
    padding: 48px 48px;
    position: relative;
    width: 675px;

    .modal-close {
      position: absolute;
      right: 48px;
      top: 48px;
      cursor: pointer;
    }

    .modal-title {
      font-weight: var(--font-weight-medium);
      font-size: 32px;
      line-height: 125%;
      letter-spacing: 0.015em;
      color: var(--black);
    }

    .modal-sub-title {
      font-weight: var(--font-weight-regular);
      font-size: 15px;
      line-height: 150%;
      color: var(--grey-3);
    }

    .check-example {
      color: #3d8b82;
      font-weight: var(--font-weight-medium);
    }

    .modal-list-items {
      padding: 0 0 0 20px;
      margin: 0;

      .item {
        font-weight: var(--font-weight-regular);
        font-size: 15px;
        line-height: 150%;
        color: var(--grey-3);
      }
    }

    .upload-csv {
      .instruction-before-uploads {
        display: flex;
        margin: 0 0 32px 0;

        .icon {
          margin: 0 24px 0 0;
        }

        .text-wrap {
          .modal-title {
            margin: 0 0 16px 0;
          }
        }
      }

      .drop-file-upload {
        background: #f5f5f5;
        border: 1px dashed #cdcbcb;
        border-radius: 12px;
        padding: 32px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin: 0 16px 0 0;
        }

        .text-csv-upload {
          font-weight: var(--font-weight-medium);
          font-size: 17px;
          line-height: 140%;
          color: #3d8b82;
        }

        input[type='file'] {
          display: none;
        }
      }

      .sended-file {
        background: var(--white);
        border: 1px dashed #cdcbcb;
        box-sizing: border-box;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: 28px 40px;

        position: relative;

        .icon {
          margin: 0 14px 0 0;
        }

        .info {
          .file-name {
            font-weight: var(--font-weight-medium);
            font-size: 15px;
            line-height: 150%;
            color: #101821;
          }

          .file-size {
            font-weight: var(--font-weight-regular);
            font-size: 15px;
            line-height: 150%;
            color: #929292;
          }
        }

        .clear-csv-file {
          position: absolute;
          right: 40px;
          top: 28px;
          cursor: pointer;
        }
      }
    }

    .success-uploads {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-success {
        margin: 0 0 24px;
      }

      .modal-title {
        margin: 0 0 24px;
      }

      .modal-sub-title {
        margin: 0 0 48px;
      }

      .button-success-ok {
        background: var(--orange-2);
        border-radius: 80px;
        font-weight: var(--font-weight-medium);
        font-size: 20px;
        line-height: 125%;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--white);
        padding: 17px 40px;
        cursor: pointer;
        transition: all 0.2s ease-out;
        border: 2px solid var(--orange-2);

        &:hover {
          background: var(--white);
          color: var(--orange-2);
        }
      }
    }

    .errors-messages {
      margin: 30px 0 0;

      .errors-item-wrap {
        margin: 0;
        padding: 0;

        .error-item {
          font-weight: var(--font-weight-regular);
          font-size: 18px;
          line-height: 130%;
          margin: 0 0 25px;
          list-style: none;
          color: #9f3c47;
          display: flex;

          .icon {
            margin: 0 24px 0 0;
            display: flex;
            align-items: center;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .errors-import-messages {
      margin: 30px 0 0;

      .errors-item-wrap {
        margin: 0;
        padding: 0;
        max-height: 250px;
        overflow: scroll;

        .error-item-header {
          background: #f5f5f5;
          border-radius: 12px;
          padding: 16px 16px;
          list-style: none;
          display: flex;
          font-weight: var(--font-weight-regular);
          font-size: 15px;
          line-height: 150%;
          color: #929292;
          margin: 0 0 30px;

          .row {
            width: 10%;
          }

          .attribute {
            width: 25%;
          }

          .text-error {
            width: 75%;
          }
        }

        .error-item {
          font-weight: var(--font-weight-regular);
          font-size: 18px;
          line-height: 130%;
          margin: 0 0 25px;
          list-style: none;
          color: #9f3c47;
          display: flex;
          padding: 0 16px;
          width: 100%;

          .icon {
            margin: 0 24px 0 0;
            display: flex;
            align-items: center;
            width: 10%;
          }

          .row {
            width: 10%;
          }

          .attribute {
            width: 25%;
          }

          .text-error {
            width: 75%;

            span {
              margin: 0 0 10px;
              display: block;

              &:last-child {
                margin: 0;
              }
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .modal-upload-csv-wrap {
      margin: 0 20px;
      padding: 15px 15px;

      .upload-csv {
        .instruction-before-uploads {
          flex-direction: column;
        }

        .drop-file-upload {
          padding: 15px 10px;
          flex-direction: column;

          .icon {
            margin: 0 0 15px;
          }
        }

        .sended-file {
          padding: 15px 40px 15px 15px;

          .info {
            max-width: 80%;
            word-break: break-all;
          }

          .clear-csv-file {
            right: 10px;
          }
        }
      }

      .errors-import-messages {
        margin: 15px 0 0;

        .errors-item-wrap {
          max-height: 220px;
          overflow: scroll;

          .error-item-header,
          .error-item {
            font-size: 15px;

            .row {
              width: 15%;
            }

            .attribute {
              width: 35%;
            }

            .text-error {
              width: 50%;

              span {
                margin: 0 0 10px;
                display: block;

                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
