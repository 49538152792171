.download-report-button__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 44px;
  border-radius: 8px;
  border: 2px solid #2e79da;
  cursor: pointer;

  .download-report__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    height: 40px;
    padding: 0;
    border-radius: 8px;
    border: none;
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 21px;
    color: #2e79da;
    background-color: #fff;
    transition: all 0.2s ease-in;
  }

  .select-language__btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background: rgba(46, 121, 218, 0.1);
    border-radius: 8px;
    border: none;
    transition: all 0.2s linear;

    &:hover {
      cursor: pointer;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.download-report__languages {
  position: absolute;
  opacity: 0;
  padding: 12px;
  width: 100%;
  height: auto;
  top: 57px;
  left: 0;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 12px 24px rgba(209, 207, 205, 0.4);
  border-radius: 8px;
  transition: all 0.2s ease-in;
  z-index: 10;

  &.active {
    opacity: 1;
  }

  .language__option {
    width: 100%;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 12px;
    border-radius: 8px;
    transition: all 0.2s ease-in;

    &:hover {
      cursor: pointer;
      background: #f7f7f7;
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }

    &.active {
      background: #f7f7f7;
    }
  }
}
