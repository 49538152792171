@import 'src/breakpoints';

.page-programmes {
  // for ILTB
  // background-color: var(--grey);
  background-color: var(--background);
  height: calc(100% - var(--header-height));

  .coach-program-select__control {
    max-width: 368px;
    margin-bottom: 24px;
    z-index: 1;
  }
  .coach-program-select__menu {
    max-width: 368px;
  }

  .row-select__control {
    &--menu-is-open {
      .row-select__indicator {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }

  .programme-card {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
