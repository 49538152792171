.career-coaching-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 320px;
    background-image: url('../../../assets/vector/questionnaire/career_coaching_bg.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @media screen and (max-width: 500px) {
      height: auto;
    }
    img {
      width: 305px;
      height: 293px;
    }
  }

  .modal-title {
    font-weight: var(--font-weight-medium);
    font-size: 32px;
    text-align: center;
    line-height: 125%;
    letter-spacing: 0.015em;
    color: #212121;
  }

  .modal-description {
    margin-top: 14px;
    font-weight: var(--font-weight-regular);
    font-size: 16px;
    line-height: 150%;
    color: #212121;
    text-align: center;
  }

  .thank-you {
    margin-top: 32px;
    font-weight: var(--font-weight-medium);
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #212121;
  }

  .modal-btn {
    margin-top: 32px;
    width: 246px;
    height: 52px;
    background: #2e79da;
    border-radius: 8px;
    outline: none;
    border: none;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
  }
}
