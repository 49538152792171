@import 'src/breakpoints';

:root {
  // General and Common
  --black: #101821;
  --black1: #212121;
  --orange: #d9622e;
  --orange-2: #e75b32;
  --orange-3: #e5876c;
  --orange-4: #ff7d34;
  --green: #386d66;
  --green-2: #71b6aa;
  --green-3: #689b95;
  --green-4: #5a998e;
  --green-5: #557b77;
  --green-6: #1d3d38;
  --green-7: #003a55;
  --green-8: #3d5956;
  --green-9: #43a7a5;
  --grey: #fbf8f6;
  --bird-grey: #f7f7f7;
  --blood: #e9333f;
  --grey-2: #faf4f0;
  --grey-3: #929292;
  --grey-4: #e0e0e0;
  --grey-5: #cdcbcb;
  --grey-6: #e5e5e5;
  --grey-7: #f3f3f3;
  --grey-8: #f2f2f2;
  --grey-gun: #f1f1f1;
  --grey-girl: #d8d8d8;
  --grey-jedi: #d9d9d9;
  --silver: #a9a9a9;
  --light-gray: #f5f5f5;
  --light-gray-2: #f8f8f8;
  --white: #fff;
  --yellow: #edbd48;
  --grey-baba: #bababa;
  --yellow-2: #f0dfb4;
  --yellow-3: #fdc94c;
  --yellow-4: #f3c042;
  --brown: #513e0f;
  --brown-2: #492210;
  --brown-3: #6a541f;
  --rafia: #ebcdb9;
  --gomo: #5bcbc9;
  --background: #f8f9fa;
  --millbrook: #533e2f;
  --william: #366b65;
  --stiletto: #9f3c47;
  --background-error: rgba(159, 60, 71, 0.8);
  --soft-peach: #f3eaeb;
  --mint: #35a684;
  --viridian: #3d8b82;
  --chat-opponent: #c3d7d5;
  --chat-my-self: rgba(0, 140, 206, 0.2);
  --modal: rgba(40, 34, 31, 0.6);
  --san-marino: #486ea0;
  --blue: #4296e5;
  --blue-1: #3da7da;
  --blue-2: #5ed3f2;
  --blue-3: #2e79da;
  --auth-header-min-height: 48px;
  --auth-footer-min-height: 100px;
  --header-height: 48px;
  --purple-light: #edebf9;
  --purple: #d8d5eb;
  --purple-2: #9e9bb6;
  --pink: #ec4e7c;
  --pinky: #f284a4;
  --background-default: #f7fafe;

  --loader-color: #4296e5;
  --active_menu: #2e79da;
  --reports-sorting_bg: rgba(253, 201, 76, 0.3);
  --session_completed: #929292;
  --session_confirmed: rgba(32, 131, 119, 0.15);
  --session_late_canceled: rgba(236, 78, 124, 0.15);
  --session_passed: #929292;
  --session_pending: rgba(46, 121, 218, 0.15);
  --opened_activity: #2a6bbf;
  --blocked_activity: #686f84;
  --completed_activity: #35a684;

  --db_completed_session: #2a6bbf;
  --db_confirmed_session: #7cb8ef;
  --db_assigned_session: #fdc94c;
  --db_not_assigned_session: #5bcbc9;
  --db_not_attended_session: #ec4e7c;

  --db_satisfaction_good: #5bcbc9;
  --db_satisfaction_neutral: #fdc94c;
  --db_satisfaction_bad: #ec4e7c;

  --db_session_credits_purchased: #d8d8d8;
  --db_session_credits_used: #2a6bbf;
  --db_session_credits_available: #5bcbc9;
  --db_session_credits_in_progress: #fdc94c;

  --db_session_frequency: #5bcbc9;

  --db_attendance_rate_planed: #50997f;
  --db_attendance_rate_completed: #2a6bbf;
  --db_attendance_rate_late_cancelled: #ec4e7c;

  --db_average_mark: #2e79da;
  --db_satisfaction_level: #5bcbc9;
  --db_deactivated: #ff7f48;
  --db_disengaged: #2e79da;
  --db_active: #5bcbc9;
  --db_total: #929292;
  --db_btn_bg_color: #5ed3f2;
  --gen_button_color: #4285f4;
  --gen_font_color: #101821;
  --dropdown_label_color: #342f52;
  --db_satisfaction_mark_1: #f7b8cb;
  --db_satisfaction_mark_2: #f284a4;
  --db_satisfaction_mark_3: #ec4e7c;
  --db_satisfaction_mark_4: #fdc94c;
  --db_satisfaction_mark_5: #feda82;
  --db_satisfaction_mark_6: #fee9b7;
  --db_satisfaction_mark_7: #5bcbc9;
  --db_satisfaction_mark_8: #8ddbda;
  --db_satisfaction_mark_9: #bdeae9;

  --font-weight-regular: 400;
  --font-weight-medium: 500;

  @media (min-width: $tablet-1) {
    --auth-header-min-height: 92px;
    --auth-footer-min-height: 127px;
  }

  @media (min-width: $tablet-2) {
    --header-height: 64px;
  }

  @media (min-width: $full) {
    --header-height: 89px;
  }
}
